import { db } from "../../../services/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export const getAllQuery = async (dbCollection: any) => {
  try {
    let data: any = [];
    const q = query(
      collection(db, dbCollection),
      where("isArchive", "==", false)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  } catch (error: any) {
    console.warn(error.message);
    return { error: error.message };
  }
};

export const getAllQueryWithArchive = async (dbCollection: any) => {
  try {
    let data: any = [];
    const q = query(
      collection(db, dbCollection),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  } catch (error: any) {
    console.warn(error.message);
    return { error: error.message };
  }
};
