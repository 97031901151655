import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit, Preview } from "@mui/icons-material";
import Popup from "../../Components/Popup";
import {
    setVideosData,
    getVideosData,
    updateVideosData,
    deleteVideosData,
} from "../../../store/reduxSlice/VideosSlice";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { getSession } from "../../utilities/Auth/AuthStates";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchBar from "../../Components/SearchBar";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import DialogBox from "../../Components/Dialoag";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import { fetchBrand } from "../../../store/reduxSlice/brandSlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../services/firebase";
import { getVideoCategoryData, setVideoCategoryData, } from "../../../store/reduxSlice/VideoCategory";

type Props = {};
const Videos = (props: Props) => {
    const data: any = useSelector((state: any) => state);
    const { videoCategoryData } = data?.videoCategory;
    const [page, setPage] = React.useState(0);
    const [values, setValues] = useState<any>([]);
    const [valuesSubCat, setvaluesSubCat] = useState<any>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [disableButton, setDisableButton] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchInput, setSearchInput] = useState("");
    const [videoData, setVideoData] = useState([]);
    const [video, setVideo] = useState<any>([]);


    const rows =
        data?.videos && data?.videos?.videosData.length > 0
            ? data?.videos?.videosData
            : [];
    console.log("rows :", rows);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const [openAlert, setOpenAlert] = React.useState(false);
    const [itemToDelete, setItemToDelete] = React.useState<any>([]);

    const handleCloseAlert = () => {
        setOpenAlert(false);
        setItemToDelete([]);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [open, setOpen] = React.useState(false);
    const [openCat, setOpenCat] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleOpenCat = () => setOpenCat(true);
    const [openModal, setOpenModal] = React.useState(false);

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const handleClose = () => {
        setValues({
            ...values,
            video_title: "",
            video_description: "",
            video_url: "",
            video_cat_id: ""
        });
        setvaluesSubCat({
            ...values,
            video_cat_name: "",
            video_cat_description: "",
        });
        setOpen(false);
        setOpenCat(false);
        setIsEditMode(false);
        setDisableButton(false)
    };

    const dispatch: any = useDispatch();
    useEffect(() => {
        getSession();
        dispatch(getVideosData());
        dispatch(getVideoCategoryData());
    }, [dispatch]);

    // useEffect(() => {
    //     setVideoCatData(categoryData);
    //     setVideoData(rows);
    // }, [rows]);

    const columns = [
        {
            id: "video_cat_id",
            label: "Video Category",
            minWidth: 100,
        },
        {
            id: "video_title",
            label: "Title",
            minWidth: 170,
        },
        {
            id: "video_description",
            label: "Description",
            minWidth: 170,
        },
        {
            id: "created_date",
            label: "Date",
            minWidth: 170,
            format: (value: number) => value.toFixed(2),
        },
        {
            id: "action",
            label: "Action",
            placeholder: "",
            type: "button",
            select: false,
        },
    ];

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value,
        });
        setLoading(false);
        setDisableButton(false);
    };

    const handleChangeVideoCat = (e: any) => {
        const { name, value } = e.target;

        setvaluesSubCat({
            ...valuesSubCat,
            [name]: value,
        });
        setLoading(false);
        setDisableButton(false);
    };

    const inputFieldValues = [
        {
            name: "video_title",
            label: "Video Title *",
            id: "video_title",
            placeholder: "Video Title",
            type: "text",
        },
        {
            name: "video_description",
            label: "Video Description *",
            id: "video_description",
            placeholder: "Video Description",
            type: "text",
        },
        {
            id: "video_url",
            name: "video_url",
            label: "video -(mp4, mov, wmv, flv, avi, avchd) *",
            placeholder: "",
            type: "file",
        },
    ];

    const videoCategoryFieldValues = [
        {
            name: "video_cat_name",
            label: "Video Category Name *",
            id: "video_cat_name",
            placeholder: "Video Category Name",
            type: "text",
        },
        {
            name: "video_cat_description",
            label: "Video Category Description *",
            id: "video_cat_description",
            placeholder: "Video Category Description",
            type: "text",
        },
    ];

    const getContent = () => (
        <Box sx={modalStyles.inputFields}>
            <div style={{ display: "flex" }}>
                <FormControl fullWidth={true}>
                    <InputLabel id="demo-simple-select-label">Video Category *</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="video_cat_id"
                        label="Category Id*"
                        defaultValue={values["video_cat_id"]}
                        variant="outlined"
                        onBlur={handleChange}
                        onChange={handleChange}
                    >
                        {videoCategoryData && videoCategoryData.length > 0 ? (
                            videoCategoryData.map((eachCategory: any) => {
                                return (
                                    <MenuItem
                                        // key={eachCategory.cat_id}
                                        // value={`${eachCategory.cat_id}`}
                                        key={
                                            isEditMode && values["video_cat_id"] === eachCategory["video_cat_id"]
                                                ? eachCategory["video_cat_id"]
                                                : eachCategory["video_cat_id"]
                                        }
                                        value={
                                            isEditMode && values["video_cat_id"] === eachCategory["video_cat_id"]
                                                ? eachCategory["video_cat_id"]
                                                : eachCategory["video_cat_id"]
                                        }
                                    >
                                        {eachCategory.video_cat_name}
                                    </MenuItem>
                                );
                            })
                        ) : (
                            <MenuItem> </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
            {inputFieldValues.map((eachField: any) => (
                <Fragment>
                    <InputLabel style={{ margin: "5px 5px" }}>
                        {eachField.label}
                    </InputLabel>
                    <TextField
                        disabled={(eachField.id === "video_url" && isEditMode) ? true : false}
                        defaultValue={eachField.type === "file" ? "" : values[eachField.id]}
                        name={eachField.id}
                        type={eachField.type}
                        multiline={eachField.id === "video_description" ? true : false}
                        onChange={(event: any) => {
                            eachField.type === "file"
                                ? handleVideo(event)
                                : handleChange(event);
                        }}
                    />
                </Fragment>
            ))}
        </Box>
    );

    const getContentCategory = () => (
        <Box sx={modalStyles.inputFields}>
            {videoCategoryFieldValues.map((eachField: any) => (
                <Fragment>
                    <InputLabel style={{ margin: "5px 5px" }}>
                        {eachField.label}
                    </InputLabel>
                    <TextField
                        defaultValue={eachField.type === "file" ? "" : values[eachField.id]}
                        name={eachField.id}
                        type={eachField.type}
                        multiline={eachField.id === "video_cat_description" ? true : false}
                        onChange={(event: any) => {
                            eachField.type === "file"
                                ? handleVideo(event)
                                : handleChangeVideoCat(event);
                        }}
                    />
                </Fragment>
            ))}
        </Box>
    );

    const handleVideo = (event: any) => {
        const { name, value, files } = event.target;

        if (files[0].name.match(/\.(mp4|mov|wmv|flv|avi|avchd)$/i) != null) {
            setVideo({
                ...video,
                [name]: files,
            });
            setDisableButton(false);
        } else {
            toast.error(`Select valid image extension!`, {
                className: "toast-message",
            });
        }
    };

    const handleFormSubmit = async () => {
        if (
            video &&
            video.video_url != undefined &&
            video.video_url != "" &&
            video.video_url != "undefined" &&
            values.video_description != undefined &&
            values.video_description != "" &&
            values.video_description != "undefined" &&
            values.video_title != undefined &&
            values.video_title != "" &&
            values.video_title != "undefined" &&
            values.video_cat_id != undefined &&
            values.video_cat_id != "" &&
            values.video_cat_id != "undefined"
        ) {
            setLoading(true);
            let allVideoData: any = {};
            if (video) {
                for (const key in video) {
                    const element = video[key];
                    if (key === "video_url") {
                        let imageName: any = Math.round(Math.random() * 1000000000);
                        let videoPath: any = `/Videos/${values.video_cat_id}/${imageName}`;
                        const storageRef = ref(
                            storage,
                            videoPath
                        );
                        const uploadTask = uploadBytesResumable(storageRef, element[0]);
                        const getThumbnailUrl = await uploadImageTask(uploadTask);
                        values["video_url"] = getThumbnailUrl;
                        values["video_uploaded_path"] = videoPath;
                        allVideoData = {
                            ...values,
                        };
                    }
                }

                if (allVideoData.video_url) {
                    dispatch(setVideosData(allVideoData));
                    handleClose();
                    dispatch(getVideosData());
                    setLoading(false);
                }
            }
        } else {
            setDisableButton(true);
        }
    };

    const handleFormSubmitVideoCategory = async () => {
        if (
            valuesSubCat.video_cat_name != undefined &&
            valuesSubCat.video_cat_name != "" &&
            valuesSubCat.video_cat_name != "undefined" &&
            valuesSubCat.video_cat_description != undefined &&
            valuesSubCat.video_cat_description != "" &&
            valuesSubCat.video_cat_description != "undefined"
        ) {
            setLoading(true);
            dispatch(setVideoCategoryData(valuesSubCat));
            handleClose();
            dispatch(getVideoCategoryData());
            setLoading(false);
        } else {
            setDisableButton(true);
        }
    };

    async function uploadImageTask(uploadTask: any) {
        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot: any) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (error: any) => {
                    alert(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                }
            );
        });
    }

    const handleFormSubmitAfterEdit = async () => {
        setLoading(true);

        let setDataRequest = {
            video_ID: values?.video_ID,
            video_cat_id: values?.video_cat_id,
            video_title: values?.video_title,
            video_description: values?.video_description
        };
        if (setDataRequest) {
            dispatch(updateVideosData(setDataRequest));
            handleClose();
            dispatch(getVideosData());
            setLoading(false);
            setValues({
                ...values,
                cat_id: "",
                sub_cat_id: "",
                sub_cat_name: "",
                sub_cat_description: "",
            });
        }
        setIsEditMode(false);
    };

    const editVideo = (
        video_cat_id: string,
        video_title: string,
        video_description: string,
        video_ID: string
    ) => {
        setValues({
            video_title: video_title,
            video_description: video_description,
            video_cat_id: video_cat_id,
            video_ID
        });
        setIsEditMode(true);
        handleOpen();
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        await dispatch(deleteVideosData(itemToDelete[0]));
        await dispatch(getVideosData());
        setItemToDelete([]);
        setLoading(false);
        handleCloseAlert();
    };

    const handleChangeSearch = (e: any) => {
        let filteredData: any = [];
        let filterCat: any = [];
        e.preventDefault();
        if (e.target.value?.length > 0) {
            filteredData = rows.filter((row: any) => {
                return (
                    row.sub_cat_name
                        ?.toLowerCase()
                        .match(e.target.value?.toLowerCase()) ||
                    row.sub_cat_description
                        ?.toLowerCase()
                        ?.match(e.target.value.toLowerCase())
                );
            });
            if (filteredData.length === 0) {
                filterCat = videoCategoryData.filter((row: any) => {
                    return row.cat_name
                        ?.toLowerCase()
                        .match(e.target.value?.toLowerCase());
                });
                filterCat.map((item: any) => {
                    let data = rows.filter((item2: any) => item2.cat_id === item?.cat_id);
                    filteredData.push(data[0]);
                });
            }
            setSearchInput(e.target.value);
            setVideoData(filteredData);
        } else {
            setVideoData(rows);
        }
    };

    const handleDelete = (item: any) => {
        setOpenAlert(true);
        setItemToDelete([item]);
    };

    const handlePreview = (url: string) => {
        // setVideoUrl(url)
        // videoRef.load();
        // setOpenVideoModal(true);
    }

    const dialogContent =
        "Sorry you are not able to delete this.Please delete brand and deal first";

    return (
        <>
            <Header>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "2em" }}>Videos</div>
                </div>
            </Header>
            <Box sx={cardHeaderStyles.wrapper}>
                {/* Search Bar */}
                <SearchBar
                    onChange={handleChangeSearch}
                    value={searchInput}
                    placeholder="Search by Category Name or Category Description"
                    searchBarWidth="720px"
                />
                <Box>
                    <div>
                        <Button variant="contained" onClick={handleOpenCat} style={{ marginRight: '10px' }}>
                            <AddIcon />
                            Add Video Category
                        </Button>
                        <Button variant="contained" onClick={handleOpen}>
                            <AddIcon />
                            Add Video
                        </Button>
                        <IconButton>
                            <RefreshIcon />
                        </IconButton>

                        <div>
                            <Popup
                                open={open}
                                onClose={handleClose}
                                title={isEditMode ? "Edit Video" : "Add Video"}
                                buttonText={
                                    isEditMode ? "Update Video" : "Submit Video"
                                }
                                content={getContent()}
                                values={values}
                                handleFormSubmit={() => handleFormSubmit()}
                                disabled={disableButton}
                                isLoading={isLoading}
                                isEditMode={isEditMode}
                                handleFormSubmitAfterEdit={() => handleFormSubmitAfterEdit()}
                            />
                            {/* video category modal */}
                            <Popup
                                open={openCat}
                                onClose={handleClose}
                                title={isEditMode ? "Edit Video Category" : "Add Video Category"}
                                buttonText={
                                    isEditMode ? "Update Video Category" : "Submit Video Category"
                                }
                                content={getContentCategory()}
                                values={valuesSubCat}
                                handleFormSubmit={() => handleFormSubmitVideoCategory()}
                                disabled={disableButton}
                                isLoading={isLoading}
                                isEditMode={isEditMode}
                                handleFormSubmitAfterEdit={() => handleFormSubmitAfterEdit()}
                            />
                        </div>
                    </div>
                </Box>
            </Box>
            {/*  Table Start */}
            <div
                style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
            >
                <Grid container>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                align={"center"}
                                                key={column.id}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows && rows.length > 0 ? (
                                        rows
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                    // key={row.code}
                                                    >
                                                        <TableCell align={"center"}>
                                                            {videoCategoryData &&
                                                                getObjectById(
                                                                    videoCategoryData,
                                                                    row.video_cat_id,
                                                                    "video_cat_name"
                                                                )}
                                                        </TableCell>
                                                        <TableCell key={"description"} align={"center"}>
                                                            {row?.video_title}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {row?.video_description}

                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {setDateFormate(row?.created_Date)}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            <Button
                                                                sx={{ margin: "2px" }}
                                                                variant="outlined"
                                                                onClick={() =>
                                                                    editVideo(
                                                                        row.video_cat_id,
                                                                        row.video_title,
                                                                        row.video_description,
                                                                        row.video_ID
                                                                    )
                                                                }
                                                            >
                                                                <Edit fontSize="small" />
                                                            </Button>
                                                            <Button
                                                                sx={{ margin: "2px" }}
                                                                variant="outlined"
                                                                onClick={() => handleDelete(row)}
                                                            >
                                                                <Delete fontSize="small" />
                                                            </Button>
                                                            <Button
                                                                sx={{ margin: "2px" }}
                                                                variant="outlined"
                                                                onClick={() => handlePreview(row.video_url)}
                                                            >
                                                                <Preview fontSize="small" />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    ) : (
                                        <Typography
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: 15,
                                                right: "40%",
                                            }}
                                        >
                                            No Video Found
                                        </Typography>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
                <AlertDialog
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    handleConfirmDelete={handleConfirmDelete}
                    isLoading={isLoading}
                />
                <Loader isLoading={isLoading} />
                <ToastContainer theme="colored" />

                <DialogBox
                    openModal={openModal}
                    handleModalClose={handleModalClose}
                    dialogContent={dialogContent}
                ></DialogBox>
            </div>
        </>
    );
};

export default Videos;
