import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./reduxSlice/LoginSlice";
import brandReducer from "./reduxSlice/brandSlice";
import dealReducer from "./reduxSlice/dealSlice";
import categoryReducer from "./reduxSlice/CategorySlice";
import subCategoryReducer from "./reduxSlice/SubCategorySlice";
import sliderReducer from "./reduxSlice/sliderSlice";
import homeCardsReducer from "./reduxSlice/HomeCardsSlice";
import hCardsReducer from "./reduxSlice/hCardSlice";
import VideoReducer from "./reduxSlice/VideosSlice";
import VideoCategoryReducer from "./reduxSlice/VideoCategory";
import CompaniesReducer from "./reduxSlice/CompaniesSlice";
import MerchantReducer from "./reduxSlice/MerchantSlice";
import refferalCodeReducer from "./reduxSlice/refferalCodeSlice";
import refferalUserReducer from "./reduxSlice/UserRefferalSlice";
import qrPaymentsReducer from "./reduxSlice/QRPaymentSlice";
import Coupon from "./reduxSlice/Coupon";
import MerchantInvoiceReducer from "./reduxSlice/MerchantInvoiceSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    brands: brandReducer,
    deals: dealReducer,
    category: categoryReducer,
    subCategory: subCategoryReducer,
    slider: sliderReducer,
    homeCards: homeCardsReducer,
    videos: VideoReducer,
    videoCategory: VideoCategoryReducer,
    companies: CompaniesReducer,
    merchant: MerchantReducer,
    refferalCode: refferalCodeReducer,
    refferalUser: refferalUserReducer,
    QRPayment: qrPaymentsReducer,
    coupon: Coupon,
    merchantInvoices: MerchantInvoiceReducer
  },
});
