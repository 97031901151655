import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
// import { MerchantInvoiceType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";


export const getMerchantInvoiceData = createAsyncThunk(
  "MerchantInvoice/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_MERCHANT_INVOICE);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setMerchantInvoiceData = createAsyncThunk(
  "MerchantInvoice/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(DB_COLLECTION.DP_MERCHANT_INVOICE, data, "id");
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateMerchantInvoiceData = createAsyncThunk(
  "MerchantInvoice/update",
  async (data: any, { rejectWithValue }) => {
    console.log('data IN SLICE:', data);
    try {
      let res = await updateQuery(DB_COLLECTION.DP_MERCHANT_INVOICE, data.id, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMerchantInvoiceData = createAsyncThunk(
  "MerchantInvoice/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_MERCHANT_INVOICE, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface MerchantInvoiceDate {
  MerchantInvoiceData: any;
  setMerchantInvoiceData: any;
  updateMerchantInvoiceData: any;
  deleteMerchantInvoiceData: any;
}

const initialState: any = {
  MerchantInvoiceData: [],
  setMerchantInvoiceData: [],
  updateMerchantInvoiceData: [],
  deleteMerchantInvoiceData: false,
} as MerchantInvoiceDate;

export const MerchantInvoiceSlice = createSlice({
  name: "MerchantInvoice",
  initialState,

  reducers: {
    setMerchantInvoice: () => { },
    getMerchantInvoice: (state, { payload }) => { },
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMerchantInvoiceData.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.MerchantInvoiceData = action.payload;
          }
        }
      )
      .addCase(getMerchantInvoiceData.rejected, (state, action) => {
        state.MerchantInvoiceData = action.payload;
      })
      .addCase(setMerchantInvoiceData.fulfilled, (state, action) => {
        state.setMerchantInvoiceData = action.payload;
      })
      .addCase(setMerchantInvoiceData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setMerchantInvoiceData = action.payload;
      })
      .addCase(updateMerchantInvoiceData.fulfilled, (state, action) => {
        state.updateMerchantInvoiceData = action.payload;
      })
      .addCase(updateMerchantInvoiceData.rejected, (state, action) => {
        state.updateMerchantInvoiceData = action.payload;
      })
      .addCase(deleteMerchantInvoiceData.fulfilled, (state, action) => {
        state.deleteMerchantInvoiceData = action.payload;
      })
      .addCase(deleteMerchantInvoiceData.rejected, (state, action) => {
        state.deleteMerchantInvoiceData = action.payload;
      })
  },
});

// Action creators are generated for each case reducer function
export const { setMerchantInvoice } = MerchantInvoiceSlice.actions;
export default MerchantInvoiceSlice.reducer;
