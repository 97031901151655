import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
// import { MerchantType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
import { deleteObject, ref } from "@firebase/storage";
import { storage } from "../../services/firebase";
import { toast } from "react-toastify";


export const getMerchantData = createAsyncThunk(
  "Merchant/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_MERCHANT);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setMerchantData = createAsyncThunk(
  "Merchant/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(DB_COLLECTION.DP_MERCHANT, data, "id");
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateMerchantData = createAsyncThunk(
  "Merchant/update",
  async (data: any, { rejectWithValue }) => {
    console.log('data IN SLICE:', data);
    try {
      let res = await updateQuery(DB_COLLECTION.DP_MERCHANT, data.id, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMerchantData = createAsyncThunk(
  "Merchant/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_MERCHANT, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface Merchanttate {
  MerchantData: any;
  setMerchantData: any;
  updateMerchantData: any;
  deleteMerchantData: any;
}

const initialState: any = {
  MerchantData: [],
  setMerchantData: [],
  updateMerchantData: [],
  deleteMerchantData: false,
} as Merchanttate;

export const MerchantSlice = createSlice({
  name: "Merchant",
  initialState,

  reducers: {
    setMerchant: () => { },
    getMerchant: (state, { payload }) => { },
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMerchantData.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.MerchantData = action.payload;
          }
        }
      )
      .addCase(getMerchantData.rejected, (state, action) => {
        state.MerchantData = action.payload;
      })
      .addCase(setMerchantData.fulfilled, (state, action) => {
        state.setMerchantData = action.payload;
      })
      .addCase(setMerchantData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setMerchantData = action.payload;
      })
      .addCase(updateMerchantData.fulfilled, (state, action) => {
        state.updateMerchantData = action.payload;
      })
      .addCase(updateMerchantData.rejected, (state, action) => {
        state.updateMerchantData = action.payload;
      })
      .addCase(deleteMerchantData.fulfilled, (state, action) => {
        state.deleteMerchantData = action.payload;
      })
      .addCase(deleteMerchantData.rejected, (state, action) => {
        state.deleteMerchantData = action.payload;
      })
  },
});

// Action creators are generated for each case reducer function
export const { setMerchant } = MerchantSlice.actions;
export default MerchantSlice.reducer;
