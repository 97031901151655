import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";

export const getQRPayment = createAsyncThunk(
  "qrPayments/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_PAYMENT_RECEIPT);
      console.log("res--->", res);
      return res;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const setQRPayment = createAsyncThunk(
  "qrPayments/set",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await setQuery(DB_COLLECTION.DP_PAYMENT_RECEIPT, data, "id");
      return response;
    } catch (error: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeQRPayment = createAsyncThunk(
  "qrPayment/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await deleteQuery(DB_COLLECTION.DP_PAYMENT_RECEIPT, data);
      return response;
    } catch (error: any) {

      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQRPayment = createAsyncThunk(
  "qrPayment/update",
  async (data: any, { rejectWithValue }) => {
    try {
      let response = await updateQuery(
        DB_COLLECTION.DP_PAYMENT_RECEIPT,
        data.id,
        data
      );
      return response;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
interface qrPaymentState {
  qrPaymentsData: any;
  setQRPaymentData: any;
  deleteQrPaymentData: any;
  setImageDownloadUrl: any;
  updateQrPaymentData: any;
}

const initialState: any = {
  qrPaymentsData: [],
  setQRPaymentData: [],
  setImageDownloadUrl: "",
  deleteQrPaymentData: false,
  updateQrPaymentData: false,
} as qrPaymentState;

export const QRPaymentSlice = createSlice({
  name: "qrPayments",
  initialState,
  reducers: {
    handleUploadImage(state, action) {},
    getQrPayment: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQRPayment.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.qrPaymentsData = action.payload;
        }
      })
      .addCase(getQRPayment.rejected, (state, action) => {
        state.qrPaymentsData = action.payload;
      })
    .addCase(setQRPayment.fulfilled, (state, action) => {
      state.setQRPaymentData = action.payload;
    })
    .addCase(setQRPayment.rejected, (state, action) => {
      state.setQRPaymentData = action.payload;
    })
    .addCase(removeQRPayment.fulfilled, (state, action) => {
      state.deleteQrPaymentData = action.payload;
    })
    .addCase(removeQRPayment.rejected, (state, action) => {
      state.deleteQrPaymentData = action.payload;
    })
    .addCase(updateQRPayment.fulfilled, (state, action) => {
      state.updateQrPaymentData = action.payload;
    })
    .addCase(updateQRPayment.rejected, (state, action) => {
      state.updateQrPaymentData = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { getQrPayment } = QRPaymentSlice.actions;
export default QRPaymentSlice.reducer;
