import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit, Preview } from "@mui/icons-material";
import Popup from "../../Components/Popup";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { getSession, getSessionData } from "../../utilities/Auth/AuthStates";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import SearchBar from "../../Components/SearchBar";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import DialogBox from "../../Components/Dialoag";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import { fetchBrand } from "../../../store/reduxSlice/brandSlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteCompaniesData,
  getCompaniesData,
  updateCompaniesData,
} from "../../../store/reduxSlice/CompaniesSlice";
import {
  getrefferalData,
  setRefferalData,
} from "../../../store/reduxSlice/refferalCodeSlice";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { isDataExistByAttributeQuery, updateQuery } from "../../utilities/queries/updateQuery";
import { DB_COLLECTION } from "../../../constant";

type Props = {};
const Companies = (props: Props) => {
  const data: any = useSelector((state: any) => state);
  const [page, setPage] = React.useState(0);
  const [values, setValues] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [refferalCodeData, setRefferalCodeData] = useState<any>({});
  const [searchInput, setSearchInput] = React.useState("");
  const [companiesData, setCompaniesData] = React.useState([]);
  const [selectedCompanyUrl, setSelectedCompanyUrl] = useState("");

  const rows =
    data?.companies && data?.companies?.CompaniesData.length > 0
      ? data?.companies?.CompaniesData
      : [];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);
  const [openCat, setOpenCat] = React.useState(false);
  const handleOpen = (company_id: any, email: any, refferal_id: any, companyName:any) => {
    generateCode(company_id, email, refferal_id, companyName);
    setOpen(true);
  };
  const handleOpenCat = () => setOpenCat(true);
  const [openModal, setOpenModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleClose = () => {
    setRefferalCodeData({});
    setOpen(false);
    setIsEditMode(false);
    setDisableButton(false);
  };
  const handleCategoryChange = (e: any) => {
    setSelectedCompanyUrl(e.target.value);
  };
  const filteredCompaniesData = selectedCompanyUrl
    ? companiesData.filter(
        (company: any) => company.companyUrl === selectedCompanyUrl
      )
    : companiesData;
  const getContentCategory = () => (
    <Box sx={modalStyles.inputFields}>
      <Card style={{ background: "#F5F5F5" }} elevation={0}>
        <p
          style={{
            fontSize: 25,
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          {refferalCodeData.refferal_code}
        </p>
      </Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          marginTop: 10,
          fontSize: 20,
        }}
      >
        <Typography
          style={{
            fontSize: 20,
          }}
        >
          Expiry Date:{" "}
          {new Date(refferalCodeData.referral_code_expiry).getDate() +
            "/" +
            (new Date(refferalCodeData.referral_code_expiry).getMonth() + 1) +
            "/" +
            new Date(refferalCodeData.referral_code_expiry).getFullYear()}
        </Typography>
      </div>
    </Box>
  );

  const dispatch: any = useDispatch();

  useEffect(() => {
    getSession();
    dispatch(getCompaniesData());
    dispatch(getrefferalData());
  }, [dispatch]);
  useEffect(() => {
    setCompaniesData(rows);
    sort_data(rows);
  }, [rows]);
  const sort_data = (data: any) => {
    let sortedData: any = [...data];
    try {
      sortedData.sort((a: any, b: any) => a.created_Date - b.created_Date);
      setCompaniesData(sortedData);
    } catch (e: any) {
      console.log("error in console  for sorting:", e);
    }
  };
  const columns = [
    {
      id: "i_am",
      label: "Client Type",
      minWidth: 100,
    },
    {
      id: "firstName",
      label: "Name",
      minWidth: 100,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 100,
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      minWidth: 100,
    },
    {
      id: "companyName",
      label: "Company Name",
      minWidth: 150,
    },
    {
      id: "companyUrl",
      label: "Company Url",
      minWidth: 150,
    },
    {
      id: "companySize",
      label: "Company Size",
      minWidth: 50,
    },
    {
      id: "companyCountry",
      label: "HQ Country",
      minWidth: 100,
    },
    {
      id: "companyRegion",
      label: "HQ Region",
      minWidth: 100,
    },
    {
      id: "companyCode",
      label: "Code",
      minWidth: 70,
    },
    {
      id: "isTemporaryCode",
      label: "Temporary Code",
      minWidth: 70,
    },
    {
      id: "codeExpiryDate",
      label: "Code Expiry Date",
      minWidth: 80,
    },
    {
      id: "createdDate",
      label: "Register Date",
      minWidth: 80,
    },
    {
      label: "Referral Code",
      minWidth: 170,
    },
    {
      label: "Action",
      minWidth: 170,
    },
  ];

  async function generateCode(company_id: any, email: any, refferal_id: any, companyName:any) {
    const codeLength = 6;
    const chars = "0123456789";
    let code = "";
    for (let i = 0; i < codeLength; i++) {
      code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7);
    const sessionData: any = await getSessionData();
    console.log("sessionData.uid--->", sessionData?.uid);
    setRefferalCodeData({
      ...refferalCodeData,
      refferal_code: code,
      marchantId: sessionData?.uid,
      referral_code_expiry: new Date(expiryDate).getTime(),
      is_temporary_code: true,
      company_id,
      companyName,
      email,
      // refferal_id,
    });
  }

  const handleFormSubmit = async () => {
    const sessionData: any = await getSessionData()
    if (refferalCodeData) {
      const refferalDoc = {
        ...refferalCodeData,
        marchantId: sessionData.uid,
        // refferal_email:,
        // refferal_id:
      };
      setLoading(true);
      dispatch(updateCompaniesData(refferalCodeData));
      dispatch(setRefferalData(refferalDoc));
      dispatch(getCompaniesData());
      setLoading(false);
      handleClose();
    }
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(deleteCompaniesData(itemToDelete[0]));
    await dispatch(getCompaniesData());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };

  const makeCodePermanent = async (data: any) => {
    console.log("dataaaa", data)
    if (window.confirm("Are you sure you want to permanent this code?")) {
      const codeLength = 6;
      const chars = "0123456789";
      let code = "";
      for (let i = 0; i < codeLength; i++) {
        code += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 7);
      let referralCodeData: any = {
        refferal_code: code,
        referral_code_expiry: new Date(expiryDate).getTime(),
        is_temporary_code: false,
        company_id: data.company_id,
        email: data.email,
        companyName:data.companyName
        // refferal_id,
      };
      const sessionData: any = await getSessionData();
      console.log("sessionData.uid--->", sessionData?.uid);
      const refferalDoc = {
        ...referralCodeData,
        marchantId: sessionData?.uid,
        refferal_code: code,
        // refferal_email:,
        // refferal_id:
      };
      dispatch(updateCompaniesData(referralCodeData));
      dispatch(setRefferalData(refferalDoc));
      dispatch(getCompaniesData());
      await updateReferralCode(data.email, code)
    }
  };

  const updateReferralCode = async (email: string, code: string) => {
    const isUserExist: any = await isDataExistByAttributeQuery(
      DB_COLLECTION.DP_REFERAL_USERS,
      "email",
      email
    );
    if (isUserExist) {
      await updateQuery(DB_COLLECTION.DP_REFERAL_USERS, isUserExist.id, { referral_code: code })
    }
  }

  const handleDelete = (item: any) => {
    setOpenAlert(true);
    setItemToDelete([item]);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const dialogContent =
    "Sorry you are not able to delete this.Please delete brand and deal first";

  const handleChangeSearch = (e: any) => {
    let filteredData: any = [];
    // e.preventDefault();
    if (e.target.value?.length > 0) {
      filteredData = rows.filter((row: any) => {
        return (
          row?.i_am?.toLowerCase().match(e.target.value?.toLowerCase()) ||
          row?.firstName?.toLowerCase()?.match(e.target.value.toLowerCase()) ||
          row?.email?.toLowerCase().match(e.target.value?.toLowerCase()) ||
          row?.companySize
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.companyHQCountry
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.companyHQRegion
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.phoneNumber
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.companyUrl?.toLowerCase().match(e.target.value?.toLowerCase())
        );
      });
      console.log("e.target.value :", filteredData);
      setSearchInput(e.target.value);
      setCompaniesData(filteredData);
    } else {
      setCompaniesData(rows);
    }
  };
  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Companies</div>
          <div style={{ marginRight: 20 }}>
            <FormControl sx={{ width: 300 }}>
              <InputLabel
                style={{ fontSize: "14px", fontWeight: 600 }}
                id="demo-simple-select-outlined-label"
              >
                {" "}
                Company URL
              </InputLabel>
              <Select
                value={selectedCompanyUrl}
                onChange={handleCategoryChange}
                label="  Category Name"
                id="demo-simple-select-outlined-label"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                <MenuItem value="">All</MenuItem>
                {companiesData.map((category: any) => (
                  <MenuItem
                    key={category.companyUrl}
                    value={category.companyUrl}
                  >
                    {category.companyUrl}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search..."
          searchBarWidth="720px"
        />
      </Box>
      {/*  Table Start */}
      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCompaniesData && filteredCompaniesData.length > 0 ? (
                    filteredCompaniesData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell align={"center"}>{row.i_am}</TableCell>
                            <TableCell align={"center"}>
                              {row.firstName}
                            </TableCell>
                            <TableCell align={"center"}>{row.email}</TableCell>
                            <TableCell align={"center"}>
                              {row.phoneNumber}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.companyName}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.companyUrl}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.companySize}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.companyHQCountry}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.companyHQRegion}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.refferal_code}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.is_temporary_code &&
                              row.is_temporary_code === true
                                ? "True"
                                : "False"}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.is_temporary_code === true ? (row.referral_code_expiry
                                ? new Date(row.referral_code_expiry).getDate() +
                                    "/" +
                                    (new Date(
                                      row.referral_code_expiry
                                    ).getMonth() +
                                      1) +
                                    "/" +
                                    new Date(
                                      row.referral_code_expiry
                                    ).getFullYear() || "----"
                                : "--/--/----") : (
                                  "--/--/----"
                                )}
                            </TableCell>
                            <TableCell align={"center"}>
                              {new Date(row.created_Date).getDate() +
                                "/" +
                                (new Date(row.created_Date).getMonth() + 1) +
                                "/" +
                                new Date(row.created_Date).getFullYear() ||
                                "----"}
                            </TableCell>
                            <TableCell align={"center"}>
                              {(row.is_temporary_code === undefined ||
                                row.is_temporary_code === true) && (
                                  <Fragment>
                                    <Tooltip
                                      title="Generate Code"
                                      placement="right-start"
                                    >
                                      {
                                        row.referral_code_expiry >= new Date().getTime() ? (
                                          <></>
                                        ) : (
                                          <FiberNewIcon
                                            style={{ cursor: "pointer" }}
                                            fontSize="large"
                                            color="primary"
                                            // disabled={row.referral_code_expiry >= new Date().getTime()}
                                            onClick={() =>
                                              handleOpen(
                                                row.company_id,
                                                row.email,
                                                row.id,
                                                row.companyName
                                              )
                                            }
                                          />
                                        )

                                      }

                                    </Tooltip>
                                    <Tooltip
                                      title="Make code Permanent"
                                      placement="right-start"
                                    >
                                      <AllInclusiveIcon
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                        }}
                                        fontSize="large"
                                        color="success"
                                        // disabled={row.referral_code_expiry >= new Date().getTime()}
                                        onClick={() => makeCodePermanent(row)}
                                      />
                                    </Tooltip>
                                  </Fragment>
                                )}
                              {row.is_temporary_code === false && (
                                <p
                                  style={{
                                    background: "#98FB98",
                                    borderRadius: 2,
                                    padding: 2,
                                  }}
                                >
                                  Code is Permanent
                                </p>
                              )}
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Companies Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />

        <Popup
          open={open}
          onClose={handleClose}
          title={
            // isEditMode ? "Edit Video" :
            "Add Code"
          }
          buttonText={isEditMode ? "Add Code" : "Add Code"}
          content={getContentCategory()}
          values={values}
          handleFormSubmit={() => handleFormSubmit()}
          disabled={disableButton}
          isLoading={isLoading}
          isEditMode={isEditMode}
          handleFormSubmitAfterEdit={null}
        />

        <Loader isLoading={isLoading} />
        <ToastContainer theme="colored" />

        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>
      </div>
    </>
  );
};


export default Companies;


