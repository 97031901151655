import { auth } from "../../../services/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { setSession } from "../Session/Session";
import { printLogs } from "../Helper/ErrorHandlers";
import { getAllByAttributesQuery } from "../queries/AddQueries";
import { CONSTANT, DB_COLLECTION } from "../../../constant";

export const createUserByEmailAndPassword = (email: string, password: string) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // Set Query by using uid from "user"
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
};

export const signinWithEmail = async (email: string, password: string) => {
  try {
    const {response, data}: any = await getAllByAttributesQuery(
      DB_COLLECTION.DP_ADMIN_USER,
      CONSTANT.email,
      email,
      CONSTANT.equal_operator
    )
    if (response && data && data?.length > 0) {
      window.localStorage.setItem(CONSTANT.DEEPERKS_SESSION_ADMIN, data[0].isAdmin)
      await signInWithEmailAndPassword(auth, email, password)
      return {status: true}
    }
    else return {status: false, errorMessage: "User not exist!"}
  }
  catch (error: any) {
    return {status: false, errorMessage: error.message}
  }
};
