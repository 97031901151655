import React from "react";
import { Modal, Box, Button, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";

export default function Popup({
  open,
  onClose,
  title,
  buttonText,
  content,
  values,
  handleFormSubmit,
  disabled,
  isLoading,
  isEditMode,
  handleFormSubmitAfterEdit,
}: any) {
  const style = {
    maxHeight: "70vh",
    height: "auto ",
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #221f1fad",
    borderRadius: "5px",
    boxShadow: 24,
    p: "15px 15px",
  };
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scrollbar">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h2>{title}</h2>
            <span onClick={onClose} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </span>
          </div>
          {content}
          <Button
            sx={{ marginBottom: "10px" }}
            size="medium"
            variant="contained"
            onClick={isEditMode ? handleFormSubmitAfterEdit : handleFormSubmit}
            disabled={disabled || isLoading}
          >
            {buttonText}
          </Button>
          <FormHelperText error={true}>
            {disabled ? "Please fill all the required fields first" : ""}
          </FormHelperText>
          <Loader isLoading={isLoading} />
        </Box>
      </Modal>
    </>
  );
}
