import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {  getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { videoCategoryType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";


export const getVideoCategoryData = createAsyncThunk(
  "videoCategory/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_VIDEOS_CATEGORY);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setVideoCategoryData = createAsyncThunk(
  "videoCategory/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(DB_COLLECTION.DP_VIDEOS_CATEGORY, data, "video_cat_id");
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateVideoCategoryData = createAsyncThunk(
  "videoCategory/update",
  async (data: any, { rejectWithValue }) => {
  console.log('data IN SLICE:', data);
    try {
      let res = await updateQuery(DB_COLLECTION.DP_VIDEOS_CATEGORY, data.video_ID ,data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteVideoCategoryData = createAsyncThunk(
  "videoCategory/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_VIDEOS_CATEGORY, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface videoState {
  videoCategoryData: any;
  setVideoCategoryData: any;
  updateVideoCategoryData: any;
  deleteVideoCategoryData: any;
}

const initialState: any = {
  videoCategoryData: [],
  setVideoCategoryData: [],
  updateVideoCategoryData: [],
  deleteVideoCategoryData: false,
} as videoState;

export const videoCategorySlice = createSlice({
  name: "videoCategory",
  initialState,

  reducers: {
    setVideoCategory: () => {},
    getVideoCategory: (state, { payload }) => {},
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getVideoCategoryData.fulfilled,
        (state, action: PayloadAction<videoCategoryType[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.videoCategoryData = action.payload;
          }
        }
      ) 
      .addCase(getVideoCategoryData.rejected, (state, action) => {
        state.videoCategoryData = action.payload;
      })
      .addCase(setVideoCategoryData.fulfilled, (state, action) => {
        state.setVideoCategoryData = action.payload;
      })
      .addCase(setVideoCategoryData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setVideoCategoryData = action.payload;
      })
      .addCase(updateVideoCategoryData.fulfilled, (state, action) => {
        state.updateVideoCategoryData = action.payload;
      })      
      .addCase(updateVideoCategoryData.rejected, (state, action) => {
        state.updateVideoCategoryData = action.payload;
      })
      .addCase(deleteVideoCategoryData.fulfilled, (state, action) => {
        state.deleteVideoCategoryData = action.payload;
      })      
      .addCase(deleteVideoCategoryData.rejected, (state, action) => {
        state.deleteVideoCategoryData = action.payload;
      })     
  },
});

// Action creators are generated for each case reducer function
export const { setVideoCategory } = videoCategorySlice.actions;
export default videoCategorySlice.reducer;
