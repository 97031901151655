import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../App.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getSession } from "../../utilities/Auth/AuthStates";
import {
  getQRPayment,
  updateQRPayment,
} from "../../../store/reduxSlice/QRPaymentSlice";
import { CheckBox, Delete, Edit } from "@mui/icons-material";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import SearchBar from "../../Components/SearchBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import Loader from "../../Components/Loader";
import PreviewIcon from "@mui/icons-material/Preview";
import ImageModal from "../../Components/ImagePreview";
import axios from "axios";
import {
  HEADERS,
  REJECT_QR_EMAIL,
  VERIFY_QR_EMAIL,
} from "../../../services/config";
import { setQuery } from "../../utilities/queries/setQuery";
import { DB_COLLECTION } from "../../../constant/dbcollections";
import { WEB_CONFIG } from "../../../config/web_config";
import { getHomeCards, updateHomeCards } from "../../../store/reduxSlice/HomeCardsSlice";
import { Tooltip } from "@mui/material";
import { getCouponData, updateCouponData } from "../../../store/reduxSlice/Coupon";

type Props = {};

const QRPayment = (props: Props) => {
  const data: any = useSelector((state: any) => state);
  const [reciptData, setreciptData] = useState<any>([]);
  const dispatch: any = useDispatch();
  const { qrPaymentsData } = data.QRPayment;
  let rows: any[] = qrPaymentsData;
  const homeCardData = data?.homeCards?.homCardsData;
  const CouponData = data?.coupon?.couponsData
  const [values, setValues] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchInput, setSearchInput] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isVerfyRecipt, setisVerfyRecipt] = useState("");
  const [allreciptData, setallreciptData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);

  const handleOpenModalImage = (data: any, isVerify: any) => {
    setisVerfyRecipt(isVerify);
    setreciptData(data);
    setImageUrl(data.recipt_url);
    setOpenImage(true);
  };

  const handleCloseModalImage = () => {
    setOpenImage(false);
  };

  const sort_data = (data: any) => {
    let sortedData: any = [...data];
    try {
      sortedData.sort((a: any, b: any) => b.created_Date - a.created_Date);
      setallreciptData(sortedData);
    } catch (e: any) {
      console.log("error in console  for sorting:", e);
    }
  };

  // const rows: any = qrPaymentsData;

  useEffect(() => {
    sort_data(rows);
  }, [rows]);
  // fetching all data here
  useEffect(() => {
    getSession();
    dispatch(getHomeCards());
    dispatch(getCouponData());
    qrPaymentsData.length === 0 && dispatch(getQRPayment());
    console.log("qrPaymentsData.length--->", qrPaymentsData.length);
  }, []);

  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  // const [convertedContent, setConvertedContent] = useState<any>(null);

  // const convertContentToHTML = () => {
  //   var rawJson = convertToRaw(editorState.getCurrentContent());
  //   setConvertedContent(rawJson);
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    {
      id: "homeCard_code",
      label: "Home Card Code",
      placeholder: "Home Card Code",
      type: "text",
      width: 50,
    },
    // {
    //   id: "homeCard_id",
    //   label: "Home Card Id",
    //   placeholder: "Home Card Id",
    //   type: "text",
    // },
    {
      id: "user_name",
      label: "User Name",
      placeholder: "User Name",
      type: "select",
    },
    {
      id: "user_email",
      label: "User Email",
      placeholder: "User Email",
      type: "text",
      select: true,
    },
    {
      id: "user_phone_no",
      label: "User Phone No.",
      placeholder: "User Phone No.",
      type: "text",
      select: false,
    },
    {
      id: "company_name",
      label: "Company Name",
      minWidth: 170,
      type: "text",
      select: false,
    },
    {
      id: "created_Date",
      label: "Date / Time",
      minWidth: 170,
      type: "text",
      select: false,
    },
    {
      id: "is_verfy",
      label: "Verified",
      minWidth: 50,
    },
    {
      id: "isReject",
      label: "Rejected",
      minWidth: 50,
    },
    {
      id: "recipt_image",
      label: "Receipt",
      placeholder: "",
      type: "file",
      select: false,
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const verifyEmail = async (data: any) => {
    setLoading(true);
    let requestBody: any = {
      userId: data.pay_user_id,
      userEmail: data.user_email,
      userFirstName: data.user_first_name,
      userLastName: data.user_last_name,
      couponCode: data.homeCard_code,
      merchantId: data.merchant_id,
      homeCardId: data.homeCard_id,
      receiptId: data.id,
      receiptUrl: data.recipt_url,
      couponId: data.couponId,
      couponPurchaseId: data.couponPurchaseId || "",
    };

    const APIData = await axios.post(
      VERIFY_QR_EMAIL,
      { ...requestBody },
      {
        headers: HEADERS,
      }
    );
    console.log("APIData", APIData);
    if (APIData?.data?.data?.object === "error") {
      console.error(APIData);
      alert("Something went wrong. Please try again later!");

      setLoading(false);
      // seterrorMessage(APIData?.data?.data?.code);
      // setisErrorOccur(true);
    } else if (APIData?.data?.data === "Failed") {
      alert("Something went wrong. Please try again later!");

      setLoading(false);
      // seterrorMessage(APIData?.data?.data);
      // setisErrorOccur(true);
    } else {
      setLoading(false);
      increaseCountOfPurchase(data)
      setTimeout(() => {
        dispatch(getQRPayment());
      }, 1000);
      handleCloseModalImage();
    }
  };

  const increaseCountOfPurchase = (QRData: any) => {
    let targetHomeCard: any = homeCardData.filter((item: any) => item.id === QRData.homeCard_id)
    let targetCoupon: any = CouponData.filter((item: any) => item.id === QRData.couponId)
    let purchaseCountHomeCard: number = 0;
    let purchaseCountCoupon: number = 0;

    if (targetHomeCard[0].purchaseCount === undefined) purchaseCountHomeCard = 1
    else purchaseCountHomeCard = Number(targetHomeCard[0].purchaseCount) + 1

    if (targetCoupon[0].purchaseCount === undefined) purchaseCountCoupon = 1
    else purchaseCountCoupon = Number(targetCoupon[0].purchaseCount) + 1

    dispatch(updateHomeCards({...targetHomeCard[0], purchaseCount: purchaseCountHomeCard}))
    dispatch(updateCouponData({...targetCoupon[0], purchaseCount: purchaseCountCoupon}))
  }

  const rejectEmail = async (data: any, rejectReason: string) => {
    let url = `${WEB_CONFIG.marketplace_base_url}/uploadrecipt?userId=${data.pay_user_id}&homeCard_code=${data.homeCard_code}&homeCard_id=${data.homeCard_id}&paymentReciptId=${data.id}`;

    const APIData = await axios.post(
      REJECT_QR_EMAIL,
      {
        userId: data.pay_user_id,
        userEmail: data.user_email,
        userFirstName: data.user_first_name,
        userLastName: data.user_last_name,
        couponCode: data.homeCard_code,
        merchantId: data.merchant_id,
        homeCardId: data.homeCard_id,
        receiptId: data.id,
        receiptUrl: data.recipt_url,
        couponPurchaseId: data.couponPurchaseId || "",
        reUploadUrl: url,
        rejectionReason: rejectReason,
      },
      {
        headers: HEADERS,
      }
    );
    console.log("APIData", APIData);
    if (APIData?.data?.data?.object === "error") {
      console.error(APIData);
      alert("Something went wrong. Please try again later!");

      setLoading(false);
      // seterrorMessage(APIData?.data?.data?.code);
      // setisErrorOccur(true);
    } else if (APIData?.data?.data === "Failed") {
      alert("Something went wrong. Please try again later!");

      setLoading(false);
      // seterrorMessage(APIData?.data?.data);
      // setisErrorOccur(true);
    } else {
      setLoading(false);
      // setmodalState(1);
      setTimeout(() => {
        dispatch(getQRPayment());
      }, 1000);
      handleCloseModalImage();
    }
  };

  const verifyRecipt = async () => {
    setLoading(true);
    if (window.confirm("Are you sure you want to verify this receipt?")) {
      setLoading(true);
      verifyEmail(reciptData);
    }
  };

  const rejectRecipt = (rejectReason: string) => {
    if (window.confirm("Are you sure you want to Reject this receipt?")) {
      setLoading(true);
      rejectEmail(reciptData, rejectReason);
    }
  };

  const handleChangeSearch = (e: any) => {
    let filteredData: any = [];
    e.preventDefault();
    // filter deal data
    if (e.target.value?.length > 0) {
      filteredData = rows?.filter((row: any) => {
        return (
          row?.homeCard_code
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.user_email?.toLowerCase()?.match(e.target.value.toLowerCase()) ||
          row?.companyName
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase()) ||
          row?.user_first_name
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase()) ||
          row?.user_last_name
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase())
        );
      });

      setSearchInput(e.target.value);
      sort_data(filteredData);
    } else {
      sort_data(rows);
    }
  };
  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>QR Payments</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          value={searchInput}
          onChange={handleChangeSearch}
          placeholder="Search by  Name , Description"
          searchBarWidth="720px"
        />
      </Box>
      {/*  Table Start */}
      <div
        style={{
          marginLeft: "3.5%",
        }}
      >
        <Grid container>
          <Paper sx={{ width: "100%" }} className="scrollbar">
            <TableContainer sx={{ maxHeight: 700 }} className="scrollbar">
              <Table
                stickyHeader
                aria-label="sticky table"
                // sx={{ width: "100%" }}
              >
                <TableHead>
                  <TableRow sx={{ alignItems: "center" }}>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        style={{ fontWeight: "bold" }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allreciptData && allreciptData.length > 0 ? (
                    allreciptData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell
                              style={{ padding: "2px" }}
                              align={"center"}
                            >
                              {row?.homeCard_code}
                            </TableCell>
                            {/* <TableCell
                              key={"deal_description"}
                              align={"center"}
                            >
                              {row?.homeCard_id}
                            </TableCell> */}
                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {`${row?.user_first_name} ${row?.user_last_name}`}
                            </TableCell>
                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {row?.user_email}
                            </TableCell>
                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {row?.user_phone_number}
                            </TableCell>

                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {row?.companyName}
                            </TableCell>
                            <TableCell align={"center"}>
                              {new Date(row.created_Date).getDate() +
                                "/" +
                                (new Date(row.created_Date).getMonth() + 1) +
                                "/" +
                                new Date(row.created_Date).getFullYear() ||
                                "----"}
                            </TableCell>
                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {row?.isVerify ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>

                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {row?.isReject ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>

                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              <img
                                src={row.recipt_url}
                                style={{ width: "70px", height: "65px" }}
                              ></img>
                            </TableCell>

                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {/* {!row?.isVerify && (
                                <Button
                                  sx={{ margin: "2px" }}
                                  variant="outlined"
                                  onClick={() => verifyRecipt(row)}
                                >
                                  Verify
                                </Button>
                              )} */}

                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() =>
                                  handleOpenModalImage(row, row?.isVerify)
                                }
                              >
                                <PreviewIcon color="success" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No QR Receipt Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={allreciptData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Loader isLoading={isLoading} />
        <ImageModal
          open={openImage}
          handleCloseModal={handleCloseModalImage}
          imageUrl={imageUrl}
          isActionButton={true}
          actionTitle={"Verify"}
          ActionFunction={verifyRecipt}
          isActionButton2={true}
          actionTitle2={"Reject"}
          ActionFunction2={rejectRecipt}
          isActionButtonAllow={!isVerfyRecipt}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default QRPayment;
