import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { videosType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
import { deleteObject, ref } from "@firebase/storage";
import { storage } from "../../services/firebase";
import { toast } from "react-toastify";


export const getVideosData = createAsyncThunk(
  "videos/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_VIDEOS);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setVideosData = createAsyncThunk(
  "videos/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(DB_COLLECTION.DP_VIDEOS, data, "video_ID");
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateVideosData = createAsyncThunk(
  "videos/update",
  async (data: any, { rejectWithValue }) => {
    console.log('data IN SLICE:', data);
    try {
      let res = await updateQuery(DB_COLLECTION.DP_VIDEOS, data.video_ID, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteVideosData = createAsyncThunk(
  "videos/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      if (data.video_uploaded_path && data.id) {
        const storageRef = ref(storage, data.video_uploaded_path);
        deleteObject(storageRef).then(() => {
          let res = deleteQuery(DB_COLLECTION.DP_VIDEOS, data);
          return res
        }).catch((error) => {
          toast.error(`Unable to delete this video. Please try later!`, {
            className: "toast-message",
          });
          return false;
        });
      }
      else {
        toast.error(`Unable to delete this video. Please try later!`, {
          className: "toast-message",
        });
        return false;
      }
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface videoState {
  videosData: any;
  setVideosData: any;
  updateVideosData: any;
  deleteVideosData: any;
}

const initialState: any = {
  videosData: [],
  setVideosData: [],
  updateVideosData: [],
  deleteVideosData: false,
} as videoState;

export const videosSlice = createSlice({
  name: "videos",
  initialState,

  reducers: {
    setVideos: () => { },
    getVideos: (state, { payload }) => { },
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getVideosData.fulfilled,
        (state, action: PayloadAction<videosType[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.videosData = action.payload;
          }
        }
      )
      .addCase(getVideosData.rejected, (state, action) => {
        state.videosData = action.payload;
      })
      .addCase(setVideosData.fulfilled, (state, action) => {
        state.setVideosData = action.payload;
      })
      .addCase(setVideosData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setVideosData = action.payload;
      })
      .addCase(updateVideosData.fulfilled, (state, action) => {
        state.updateVideosData = action.payload;
      })
      .addCase(updateVideosData.rejected, (state, action) => {
        state.updateVideosData = action.payload;
      })
      .addCase(deleteVideosData.fulfilled, (state, action) => {
        state.deleteVideosData = action.payload;
      })
      .addCase(deleteVideosData.rejected, (state, action) => {
        state.deleteVideosData = action.payload;
      })
  },
});

// Action creators are generated for each case reducer function
export const { setVideos } = videosSlice.actions;
export default videosSlice.reducer;
