import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import {
  deleteCategoryData,
  getCategoryData,
  setCategoryData,
  updateCategoryData,
} from "../../../store/reduxSlice/CategorySlice";
import Popup from "../../Components/Popup";
import { Delete, Edit } from "@mui/icons-material";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { getSession } from "../../utilities/Auth/AuthStates";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import SearchBar from "../../Components/SearchBar";
import AddCommonButton from "../../Components/AddCommonButton";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import { AnyAction } from "@reduxjs/toolkit";
import DialogBox from "../../Components/Dialoag";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import { fetchBrand } from "../../../store/reduxSlice/brandSlice";
import { getSubCategoryData } from "../../../store/reduxSlice/SubCategorySlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../services/firebase";
import ReactFlagsSelect from "react-flags-select";
import { Flags } from "../../../data/Flags";
import { getCompaniesData } from "../../../store/reduxSlice/CompaniesSlice";
import { getMerchantData } from "../../../store/reduxSlice/MerchantSlice";

const defaultInputValues = {
  cat_name: "",
  cat_description: "",
};
const Home = () => {
  const data: any = useSelector((state: any) => state);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [values, setValues] = useState<any>([]);
  const [retrieveData, setRetrieveData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [catData, setCatData] = useState([]);
  const [location, setLocation] = React.useState<string[]>([]);

  const rows = data?.category?.categoryData;

  const [searchResults, setSearchResults] = useState(null);
  const { brandsData } = data.brands;
  const { subCategoryData } = data.subCategory;
  const { dealData } = data.deals;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [openModal, setOpenModal] = React.useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);
  const [image, setImage] = React.useState<any>([]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleClose = () => {
    setValues({
      ...values,
      cat_id: "",
      cat_name: "",
      cat_description: "",
      location: [],
    });
    setLocation([]);
    setOpen(false);
    setIsEditMode(false);
    setDisableButton(false);
  };
  const dispatch: any = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    getSession();
    dispatch(getCategoryData());
    setRetrieveData(data);
    dispatch(fetchDeals());
    dispatch(fetchBrand());
    dispatch(getSubCategoryData());
    dispatch(getCompaniesData());
    dispatch(getMerchantData());
  }, []);

  useEffect(() => {
    setCatData(rows);
  }, [rows]);
  const columns = [
    {
      id: "cat_icon",
      label: "Icon (480 * 320) JPEG/PNG",
      minWidth: 100,
    },
    {
      id: "cat_name",
      label: "Name",
      minWidth: 100,
    },
    {
      id: "cat_description",
      label: "Description",
      minWidth: 170,
    },
    {
      id: "banner_image",
      label: "Banner Image",
      minWidth: 170,
    },
    {
      id: "cat_location",
      label: "Location",
      placeholder: "",
      select: false,
    },
    {
      id: "created_Date",
      label: "Created Date",
      minWidth: 170,
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e;
    setValues({
      ...values,
      [name]: value,
    });
    setLoading(false);
    setDisableButton(false);
  };
  const inputFieldValues = [
    {
      name: "cat_name",
      label: "Category Name",
      id: "cat_name",
      placeholder: "Enter Category Name",
      type: "text",
      required: true,
    },
    {
      name: "cat_description",
      label: "Category Description *",
      id: "cat_description",
      placeholder: "Enter Category Description",
      type: "text",
      required: true,
    },
    {
      id: "banner_image",
      name: "banner_image",
      label: "Banner Image (1020 * 380) JPEG/PNG *",
      placeholder: "",
      type: "file",
      required: true,
    },
    {
      id: "cat_icon",
      name: "cat_icon",
      label: "Icon (480 * 320) JPEG/PNG *",
      placeholder: "",
      type: "file",
      required: true,
    },
  ];
  const dialogContent =
    "Sorry you are not able to delete this.Please delete  subcatefory brand and deal first";
  const handleImage = (event: any) => {
    const { name, value, files } = event.target;
    if (files[0].name.match(/\.(jpg|jpeg|png|PNG)$/i) != null) {
      setImage({
        ...image,
        [name]: files,
      });
      setDisableButton(false);
    } else {
      toast.error(`Select valid image extension!`, {
        className: "toast-message",
      });
    }
  };
  const handleMultiSelect = (event: SelectChangeEvent<typeof location>) => {
    const {
      target: { value },
    } = event;
    let array = typeof value === "string" ? value.split(",") : value;
    let editValues = {
      ...values,
      location: array,
    };
    setValues(editValues);
    setLocation(typeof value === "string" ? value.split(",") : value);
    // if (values?.location) {
    //   editValues = {
    //     ...values,
    //     location: [
    //       ...values.location,
    //       {
    //         flag: value[value.length - 1].split(" ")[0],
    //         country:
    //           value[value.length - 1].split(" ")[1] +
    //           " " +
    //           (value[value.length - 1].split(" ")[2]
    //             ? value[value.length - 1].split(" ")[2]
    //             : ""),
    //       },
    //     ],
    //   };
    // } else {
    //   editValues = {
    //     ...values,
    //     location: [
    //       {
    //         flag: value[value.length - 1].split(" ")[0],
    //         country:
    //           value[value.length - 1].split(" ")[1] +
    //           " " +
    //           (value[value.length - 1].split(" ")[2]
    //             ? value[value.length - 1].split(" ")[2]
    //             : ""),
    //       },
    //     ],
    //   };
    // }
  };
  function getStyles(name: string, location: readonly string[], theme: Theme) {
    return {
      fontWeight: location.indexOf(name) === -1 ? "normal" : "bold",
    };
  }
  const getContent = () => (
    <Box sx={modalStyles.inputFields}>
      {inputFieldValues.map((eachField: any) => (
        <>
          <InputLabel style={{ margin: "5px 5px" }}>
            {eachField.label}
          </InputLabel>
          <TextField
            type={eachField.type}
            placeholder={eachField.placeholder}
            name={eachField.id}
            required
            defaultValue={eachField.type === "file" ? "" : values[eachField.id]}
            multiline={eachField.id === "cat_description" ? true : false}
            onChange={(e: any) => {
              try {
                eachField.type === "file"
                  ? handleImage(e)
                  : handleChange(e.target);
              } catch (e: any) {
                console.log("e.target.value", e);
              }
            }}
          />
        </>
      ))}
      <div>
        {/* multi select  */}
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Region</InputLabel>
          <Select
            fullWidth={true}
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            label="Region"
            value={location}
            onChange={handleMultiSelect}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={value} />
                  // <div
                  //   style={{
                  //     backgroundColor: "#E8E8E8	",
                  //     borderRadius: 4,
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //     padding: 2,
                  //   }}
                  // >
                  //   <img src={value.split(" ")[0]} alt="flags" />
                  //   <span style={{ padding: 0, marginLeft: 2 }}>
                  //     {" "}
                  //     {" " +
                  //       value.split(" ")[1] +
                  //       " " +
                  //       (value.split(" ")[2] ? value.split(" ")[2] : "")}
                  //   </span>
                  // </div>
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {Flags.map((item: any) => (
              <MenuItem
                key={item.name}
                // value={item.mini + " " + item.name}
                value={item.name}
                style={getStyles(item.name, location, theme)}
              >
                {
                  <div style={{}}>
                    {/* <img src={item?.mini} /> */}
                    {item.name}
                  </div>
                }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
  const handleFormSubmit = async (file: any) => {
    if (
      values.cat_name &&
      values.cat_name !== undefined &&
      values.cat_description &&
      values.cat_description !== undefined &&
      image &&
      image.banner_image !== undefined &&
      image.banner_image !== "" &&
      image.banner_image !== "undefined" &&
      image &&
      image.cat_icon !== undefined &&
      image.cat_icon !== "" &&
      image.cat_icon !== "undefined"
    ) {
      values["cat_name"] = values["cat_name"].trim();
      values["cat_description"] = values["cat_description"].trim();
      let allBrandsData: any = {};
      setLoading(true);
      if (image) {
        for (const key in image) {
          const element = image[key];
          if (key === "banner_image") {
            let imageName = Math.round(Math.random() * 1000000000);
            let dealName =
              values && values.cat_name ? values.cat_name : "custom";
            const storageRef = ref(
              storage,
              `/Categories/${dealName}/${imageName}-${dealName}-banner`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getThumbnailUrl = await uploadImageTask(uploadTask);
            values["banner_image"] = getThumbnailUrl;
            allBrandsData = {
              ...values,
            };
          } else if (key === "cat_icon") {
            let imageName = Math.round(Math.random() * 1000000000);
            let dealName =
              values && values.cat_name ? values.cat_name : "custom";
            const storageRef = ref(
              storage,
              `/Categories/${dealName}/${imageName}-${dealName}-cat_icon`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getSliderUrl = await uploadImageTask(uploadTask);
            values["cat_icon"] = getSliderUrl;
            allBrandsData = {
              ...values,
            };
          }
        }
        if (allBrandsData?.banner_image && allBrandsData?.cat_icon) {
          dispatch(setCategoryData(allBrandsData));
          dispatch(getCategoryData());
          handleClose();
          setValues({
            ...values,
            cat_id: "",
            cat_name: "",
            cat_description: "",
          });
          setLoading(false);
        }
      }
    } else {
      setDisableButton(true);
    }
  };

  async function uploadImageTask(uploadTask: any) {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  }
  const handleFormSubmitAfterEdit = async (file: any) => {
    if (image) {
      let setDataRequest = {
        ...values,
      };

      if (image) {
        setLoading(true);

        if (
          image.hasOwnProperty("banner_image") &&
          image.hasOwnProperty("cat_icon") &&
          typeof image.banner_image === "object" &&
          typeof image.cat_icon === "object"
        ) {
          for (const key in image) {
            const element = image[key];
            if (key === "banner_image") {
              let imageName = Math.round(Math.random() * 1000000000);
              let dealName =
                values && values.cat_name ? values.cat_name : "custom";
              const storageRef = ref(
                storage,
                `/Categories/${dealName}/${imageName}-${dealName}`
              );
              const uploadTask = uploadBytesResumable(storageRef, element[0]);
              const getThumbnailUrl = await uploadImageTask(uploadTask);
              values["banner_image"] = getThumbnailUrl;
              setDataRequest = {
                ...values,
              };
            } else if (key === "cat_icon") {
              let imageName = Math.round(Math.random() * 1000000000);
              let dealName =
                values && values.cat_name ? values.cat_name : "custom";
              const storageRef = ref(
                storage,
                `/Categories/${dealName}/${imageName}-${dealName}-cat_icon`
              );
              const uploadTask = uploadBytesResumable(storageRef, element[0]);
              const getHomeCardUmageUrl = await uploadImageTask(uploadTask);
              values["cat_icon"] = getHomeCardUmageUrl;
              setDataRequest = {
                ...values,
              };
            }
          }
        } else if (
          image.hasOwnProperty("banner_image") &&
          typeof image.banner_image === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          let dealName = values && values.cat_name ? values.cat_name : "custom";
          const storageRef = ref(
            storage,
            `/Categories/${dealName}/${imageName}-${dealName}`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.banner_image[0]
          );
          const getSliderUrl = await uploadImageTask(uploadTask);
          values["banner_image"] = getSliderUrl;
          setDataRequest = {
            ...values,
          };
        } else if (
          image.hasOwnProperty("cat_icon") &&
          typeof image.cat_icon === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          let dealName = values && values.cat_name ? values.cat_name : "custom";
          const storageRef = ref(
            storage,
            `/Categories/${dealName}/${imageName}-${dealName}-cat_icon`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.cat_icon[0]
          );
          const getThumbnailUrl = await uploadImageTask(uploadTask);
          values["cat_icon"] = getThumbnailUrl;
          setDataRequest = {
            ...values,
          };
        }

        if (setDataRequest.banner_image && setDataRequest.cat_icon) {
          setDataRequest = {
            ...values,
          };
          await dispatch(updateCategoryData(setDataRequest));
          handleClose();
          await dispatch(getCategoryData());
          setLoading(false);
          setIsEditMode(false);
        }
      }
    } else {
      setDisableButton(true);
      setIsEditMode(false);
    }
  };

  const handleEditCategory = (
    id: any,
    name: any,
    description: any,
    banner_image: any,
    cat_icon: any,
    location: any
  ) => {
    setIsEditMode(true);
    setValues({
      ...values,
      cat_id: id,
      cat_name: name,
      cat_description: description,
      banner_image: banner_image,
      cat_icon: cat_icon,
      location: location,
    });
    setLocation(location ? location : []);
    setImage({ banner_image: banner_image, cat_icon: cat_icon });
    handleOpen();
  };

  const handleDelete = (item: any) => {
    let checkDealExists = dealData.filter((eachDeal: any) => {
      if (eachDeal.cat_id === item.cat_id) {
        return eachDeal;
      }
    });
    let checkSubCatrgoryExists = subCategoryData.filter(
      (eachSubCategory: any) => {
        if (eachSubCategory.cat_id === item.cat_id) {
          return eachSubCategory;
        }
      }
    );
    let checkBrandExists = brandsData.filter((eachBrand: any) => {
      if (eachBrand.cat_id === item.cat_id) {
        return eachBrand;
      }
    });

    if (
      checkSubCatrgoryExists.length === 0 &&
      checkDealExists.length === 0 &&
      checkBrandExists.length === 0
    ) {
      setOpenAlert(true);
      setItemToDelete([item]);
    } else {
      setOpenModal(true);
    }
  };
  const handleChangeSearch = (e: AnyAction) => {
    let filteredData: any = [];
    e.preventDefault();
    if (e.target.value?.length > 0) {
      filteredData = rows.filter((row: any) => {
        return (
          row?.cat_name?.toLowerCase().match(e.target.value?.toLowerCase()) ||
          row?.cat_description
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase())
        );
      });
      setSearchInput(e.target.value);
      setCatData(filteredData);
    } else {
      setCatData(rows);
    }
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(deleteCategoryData(itemToDelete[0]));
    await dispatch(getCategoryData());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };

  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Category</div>
        </div>
      </Header>

      <ToastContainer theme="colored" />
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search by Category Name or Category Description"
          searchBarWidth="720px"
        />
        <Box>
          <div>
            {/* import add buton */}
            {/* <AddCommonButton
              onClick={handleOpen}
              size="large"
              sx={cardHeaderStyles.addUserButton}
              title={"Add Category"}
            /> */}

            <Button variant="contained" onClick={handleOpen}>
              <AddIcon />
              Add Category
            </Button>
            <IconButton>
              <RefreshIcon />
            </IconButton>
            <div>
              {/* add/edit Modal */}
              <Popup
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Edit Category" : "Add Category"}
                buttonText={isEditMode ? "Update Category" : "Submit Category"}
                content={getContent()}
                values={values}
                handleFormSubmit={() => handleFormSubmit(image)}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() =>
                  handleFormSubmitAfterEdit(image)
                }
              />
            </div>
          </div>
        </Box>
      </Box>

      {/*Table Start  */}

      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {catData && catData?.length > 0 ? (
                    catData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell align={"center"}>
                              <img
                                alt="cat_icon"
                                src={row.cat_icon}
                                style={{
                                  width: "50px",
                                  height: "auto",
                                  color: "#0E5B9B",
                                }}
                              ></img>
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.cat_name}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row.cat_description}
                            </TableCell>
                            <TableCell key={"banner_image"} align={"center"}>
                              <img
                                alt="cat_banner"
                                src={row.banner_image}
                                style={{ width: "90px" }}
                              ></img>
                            </TableCell>
                            <TableCell style={{ width: 180 }} align={"left"}>
                              <ul>
                                {row?.location && row?.location.length > 0
                                  ? row?.location.map((item: any) => {
                                      return <li>{item.country || item}</li>;
                                    })
                                  : "No Location Available"}
                              </ul>
                            </TableCell>
                            <TableCell align={"center"}>
                              {setDateFormate(row?.created_Date)}
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() =>
                                  handleEditCategory(
                                    row.cat_id,
                                    row.cat_name,
                                    row.cat_description,
                                    row.banner_image,
                                    row.cat_icon,
                                    row.location
                                  )
                                }
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                        // border: "1px solid black"
                      }}
                    >
                      No Category Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
        <Loader isLoading={isLoading} />
        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>
      </div>
    </>
  );
};

export default Home;
