import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "../constant";
//Import Screens
import Home from "../UI/Pages/Home/Home";
import LoginForm from "../UI/Pages/Login/LoginForm";
import Brands from "../UI/Pages/Brand/Brands";
import SubCategory from "../UI/Pages/Category/SubCategory";
import Deals from "../UI/Pages/Deals/Deals";
import Slider from "../UI/Pages/Slider";
import HomeCards from "../UI/Pages/HomeCards/HomeCards";
import Videos from "../UI/Pages/Videos/Videos";
import Companies from "../UI/Pages/Companies/Companies";
import MerchantSLice from "../store/reduxSlice/MerchantSlice";
import Merchant from "../UI/Pages/Merchant/Merchant";
import RefferalUser from "../UI/Pages/User/RefferalUser";
import RefferalUsers from "../UI/Pages/User/RefferalUsers";
import QRPayment from "../UI/Pages/QRPayment/QRPayment";
import Coupon from "../UI/Pages/Coupon/Coupon";
import MerchantInvoice from "../UI/Pages/MerchantInvoices/MerchantInvoices";

const Navigation: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.LOGIN} element={<LoginForm />} />
        <Route path={ROUTES.BRANDS} element={<Brands />} />
        <Route path={ROUTES.SUBCATEGORIES} element={<SubCategory />} />
        <Route path={ROUTES.DEALS} element={<Deals />} />
        <Route path={ROUTES.SLIDER} element={<Slider />} />
        <Route path={ROUTES.HOME_CARDS} element={<HomeCards />} />
        <Route path={ROUTES.VIDEOS} element={<Videos />} />
        <Route path={ROUTES.COMPANIES} element={<Companies />} />
        <Route path={ROUTES.MERCHANT} element={<Merchant />} />
        <Route path={ROUTES.USER_REFFERAL} element={<RefferalUsers />} />
        <Route path={ROUTES.QR_PAYMENTS} element={<QRPayment />} />
        <Route path={ROUTES.COUPONS} element={<Coupon />} />
        <Route path={ROUTES.MERCHANT_INVOICES} element={<MerchantInvoice />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
