import React from 'react'
import Alert from '@mui/material/Alert';

type Props = {
  severity: "error" | "warning" | "info" | "success",
  message: String
}

const CustomAlert = (props: Props) => {
  return (
    <Alert severity={props.severity} >{props.message}</Alert>
  )
}

export default CustomAlert