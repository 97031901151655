import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit, Preview } from "@mui/icons-material";
import Popup from "../../Components/Popup";
import {
  setVideosData,
  getVideosData,
  updateVideosData,
  deleteVideosData,
} from "../../../store/reduxSlice/VideosSlice";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { createUser, getSession } from "../../utilities/Auth/AuthStates";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchBar from "../../Components/SearchBar";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import DialogBox from "../../Components/Dialoag";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import { fetchBrand } from "../../../store/reduxSlice/brandSlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../services/firebase";
import {
  getVideoCategoryData,
  setVideoCategoryData,
} from "../../../store/reduxSlice/VideoCategory";
import { getCompaniesData } from "../../../store/reduxSlice/CompaniesSlice";
import {
  deleteMerchantData,
  getMerchantData,
  setMerchantData,
  updateMerchantData,
} from "../../../store/reduxSlice/MerchantSlice";
import VerifiedIcon from "@mui/icons-material/Verified";
import FlakyIcon from "@mui/icons-material/Flaky";
import CancelIcon from "@mui/icons-material/Cancel";
import { DB_COLLECTION } from "../../../constant";
import { setQuery } from "../../utilities/queries/setQuery";
import axios from "axios";
import { ACCEPT_MERCHANT_EMAIL, HEADERS } from "../../../services/config";

type Props = {};
const Merchant = (props: Props) => {
  const data: any = useSelector((state: any) => state);
  const [page, setPage] = React.useState(0);
  const [values, setValues] = useState<any>([]);
  const [valuesSubCat, setvaluesSubCat] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isOpen, setisOpen] = useState(false);
  const [merchantData, setMerchantDataForUpdate] = useState<any>({});
  const [video, setVideo] = useState<any>([]);
  const [image, setImage] = React.useState<any>([]);
  const [reasonOfRejection, setReasonOfRejection] = React.useState<any>("");

  const acceptMerchantReuqest = async () => {
    if (merchantData.merchantName !== undefined) {
      setLoading(true);
      let password: any = String(Math.round(Math.random() * 100000000));
      let requestBody: any = {
        merchantId: merchantData.id,
        name: merchantData.merchantName,
        email: merchantData.email,
        password: password,
      };

      const APIData = await axios.post(
        ACCEPT_MERCHANT_EMAIL,
        { ...requestBody },
        {
          headers: HEADERS,
        }
      );

      console.log("APIData", APIData);
      if (APIData?.data?.data?.object === "error") {
        console.error(APIData);
        alert("Something went wrong. Please try again later!");

        setLoading(false);
      } else if (APIData?.data?.data === "Failed") {
        alert("Something went wrong. Please try again later!");

        setLoading(false);
      } else {
        createUser(merchantData, password, DB_COLLECTION.DP_MERCHANT_USERS);
        dispatch(updateMerchantData({ ...merchantData, isVerify: true }));
        setisOpen(false);
        setTimeout(() => {
          dispatch(getMerchantData());
          setLoading(false);
        }, 5000);
      }
    } else {
      alert("Please edit merchant name.");
    }
  };

  const rejectMerchantReuqest = async () => {
    if (reasonOfRejection !== "") {
      setLoading(true);
      let requestBody: any = {
        merchantId: merchantData.id,
        email: merchantData.email,
        reasonOfRejection: reasonOfRejection,
      };

      const APIData = await axios.post(
        ACCEPT_MERCHANT_EMAIL,
        { ...requestBody },
        {
          headers: HEADERS,
        }
      );

      console.log("APIData", APIData);
      if (APIData?.data?.data?.object === "error") {
        console.error(APIData);
        alert("Something went wrong. Please try again later!");

        setLoading(false);
      } else if (APIData?.data?.data === "Failed") {
        alert("Something went wrong. Please try again later!");

        setLoading(false);
      } else {
        dispatch(updateMerchantData({ ...merchantData, isVerify: false }));
        setisOpen(false);
        setTimeout(() => {
          dispatch(getMerchantData());
          setLoading(false);
        }, 2000);
      }
    } else {
      alert("Please enter reason of rejection");
    }
  };

  const rows =
    data?.merchant && data?.merchant?.MerchantData.length > 0
      ? data?.merchant?.MerchantData
      : [];
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);
  const [openCat, setOpenCat] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenCat = () => setOpenCat(true);
  const [openModal, setOpenModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setLoading(false);
    setDisableButton(false);
  };

  const handleImage = (event: any) => {
    const { name, value, files } = event.target;
    if (files[0] != null) {
      setImage({
        ...image,
        [name]: files,
      });
      setDisableButton(false);
    } else {
      toast.error(`Select valid image extension!`, {
        className: "toast-message",
      });
    }
  };

  const handleClose = () => {
    setValues({
      ...values,
      websiteLink: "",
      phoneNumber: "",
      merchantName: "",
      lineNumberID: "",
      lastName: "",
      instagramID: "",
      firstName: "",
      faceebookPageLink: "",
      email: "",
    });
    setOpen(false);
    setOpenCat(false);
    setIsEditMode(false);
    setDisableButton(false);
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    getSession();
    dispatch(getMerchantData());
  }, [dispatch]);

  // useEffect(() => {
  //     setVideoCatData(categoryData);
  //     setVideoData(rows);
  // }, [rows]);

  const columns = [
    {
      id: "firstName",
      label: "First Name",
      minWidth: 60,
    },
    {
      id: "lastName",
      label: "Name",
      minWidth: 70,
    },
    {
      id: "Email",
      label: "Email",
      minWidth: 170,
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      minWidth: 70,
    },
    {
      id: "merchantName",
      label: "Merchant Name",
      minWidth: 70,
    },
    {
      id: "offerAudience",
      label: "Offers Audience",

      minWidth: 70,
    },
    {
      id: "briefDescription",
      label: "Brief Description",
      minWidth: 70,
    },
    {
      id: "registerDate",
      label: "Register Date",
      minWidth: 70,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 70,
    },
    {
      id: "action",
      label: "Action",
      type: "button",
      select: false,
    },
  ];

  const handleEditMerchant = (data: any) => {
    setIsEditMode(true);
    setValues({
      ...values,
      id: data?.id,
      websiteLink: data?.websiteLink || "",
      phoneNumber: data?.phoneNumber || "",
      merchantName: data?.merchantName || "",
      lineNumberID: data?.lineNumberID || "",
      lastName: data?.lastName || "",
      instagramID: data?.instagramID || "",
      firstName: data?.firstName || "",
      faceebookPageLink: data?.faceebookPageLink || "",
      email: data?.email || "",
    });
    handleOpen();
  };

  const inputFieldValues = [
    {
      name: "firstName",
      label: "First Name *",
      id: "firstName",
      placeholder: "First Name",
      type: "text",
    },
    {
      name: "lastName",
      label: "Last Name *",
      id: "lastName",
      placeholder: "Last Name",
      type: "text",
    },
    {
      name: "email",
      label: "email *",
      id: "email",
      placeholder: "email",
      type: "text",
    },
    {
      name: "phoneNumber",
      label: "Phone Number *",
      id: "phoneNumber",
      placeholder: "Phone Number",
      type: "text",
    },
    {
      name: "merchantName",
      label: "Merchant Name *",
      id: "merchantName",
      placeholder: "Merchant Name",
      type: "text",
    },
    {
      name: "websiteLink",
      label: "Website Link",
      id: "websiteLink",
      placeholder: "Website Link",
      type: "text",
    },
    {
      name: "lineNumberID",
      label: "Line Number ID",
      id: "lineNumberID",
      placeholder: "Line Number ID",
      type: "text",
    },
    {
      name: "instagramID",
      label: "Instagram ID",
      id: "instagramID",
      placeholder: "Instagram ID",
      type: "text",
    },
    {
      name: "faceebookPageLink",
      label: "Faceebook Page Link",
      id: "faceebookPageLink",
      placeholder: "Faceebook Page Link",
      type: "text",
    },
    // {
    //   id: "email",
    //   name: "email",
    //   label: "video -(mp4, mov, wmv, flv, avi, avchd) *",
    //   placeholder: "",
    //   type: "file",
    // },
  ];

  const getContent = () => (
    <Box sx={modalStyles.inputFields}>
      {inputFieldValues.map((eachField: any, index: any) => {
        return (
          <Fragment>
            <InputLabel style={{ margin: "5px 5px" }}>
              {eachField.label}
            </InputLabel>

            <TextField
              // fullWidth={eachField.id === "homeCard_description" ? false : true}
              defaultValue={
                eachField.type === "file" ? "" : values[eachField.id]
              }
              placeholder={eachField.placeholder}
              name={eachField.id}
              type={eachField.type}
              // multiline={eachField.id === "homeCard_description" ? true : false}
              onChange={(event: any) => {
                eachField.type === "file"
                  ? handleImage(event)
                  : handleChange(event);
              }}
            />
          </Fragment>
        );
      })}
    </Box>
  );

  const handleFormSubmit = async () => {
    if (
      video.firstName != undefined &&
      video.firstName != "" &&
      video.firstName != "undefined" &&
      values.lastName != undefined &&
      values.lastName != "" &&
      values.lastName != "undefined" &&
      values.email != undefined &&
      values.email != "" &&
      values.email != "undefined" &&
      values.merchantName != undefined &&
      values.merchantName != "" &&
      values.merchantName != "undefined" &&
      values.phoneNumber != undefined &&
      values.phoneNumber != "" &&
      values.emphoneNumberail != "undefined"
    ) {
      setLoading(true);
      let allVideoData: any = {};
      if (video) {
        for (const key in video) {
          const element = video[key];
          if (key === "video_url") {
            let imageName: any = Math.round(Math.random() * 1000000000);
            let videoPath: any = `/Videos/${values.video_cat_id}/${imageName}`;
            const storageRef = ref(storage, videoPath);
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getThumbnailUrl = await uploadImageTask(uploadTask);
            values["video_url"] = getThumbnailUrl;
            values["video_uploaded_path"] = videoPath;
            allVideoData = {
              ...values,
            };
          }
        }

        if (allVideoData.video_url) {
          dispatch(setVideosData(allVideoData));
          handleClose();
          dispatch(getVideosData());
          setLoading(false);
        }
      }
    } else {
      setDisableButton(true);
    }
  };

  async function uploadImageTask(uploadTask: any) {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  }

  const handleFormSubmitAfterEdit = async () => {
    setLoading(true);
    if (
      values.firstName != undefined &&
      values.firstName != "" &&
      values.firstName != "undefined" &&
      values.lastName != undefined &&
      values.lastName != "" &&
      values.lastName != "undefined" &&
      values.email != undefined &&
      values.email != "" &&
      values.email != "undefined" &&
      values.merchantName != undefined &&
      values.merchantName != "" &&
      values.merchantName != "undefined" &&
      values.phoneNumber != undefined &&
      values.phoneNumber != "" &&
      values.phoneNumber != "undefined"
    ) {
      let setDataRequest = {
        ...values,
        id: values?.id,
      };
      if (setDataRequest) {
        dispatch(updateMerchantData(setDataRequest));
        handleClose();
        dispatch(getMerchantData());
        setLoading(false);
        setValues({
          ...values,
          websiteLink: "",
          phoneNumber: "",
          merchantName: "",
          lineNumberID: "",
          lastName: "",
          instagramID: "",
          firstName: "",
          faceebookPageLink: "",
          email: "",
        });
      }
    }
    setLoading(false);
    setDisableButton(true);
    setIsEditMode(false);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    setTimeout(async () => {
      await dispatch(deleteMerchantData(itemToDelete[0]));
      await dispatch(getMerchantData());
    }, 2000);
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };

  const handleDelete = (item: any) => {
    setOpenAlert(true);
    setItemToDelete([item]);
  };

  const dialogContent =
    "Sorry you are not able to delete this.Please delete brand and deal first";

  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Merchants</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        {/* <SearchBar
                    // onChange={handleChangeSearch}
                    value={searchInput}
                    placeholder="Search by Category Name or Category Description"
                    searchBarWidth="720px"
                /> */}
        <Box>
          <div>
            <div>
              <Popup
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Edit Merchant" : "Add Merchant"}
                buttonText={isEditMode ? "Update Merchant" : "Submit Merchant"}
                content={getContent()}
                values={values}
                handleFormSubmit={() => handleFormSubmit()}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() => handleFormSubmitAfterEdit()}
              />
            </div>
          </div>
        </Box>
      </Box>
      {/*  Table Start */}
      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell align={"center"}>
                              {row.firstName}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.lastName}
                            </TableCell>
                            <TableCell align={"center"}>{row.email}</TableCell>
                            <TableCell align={"center"}>
                              {row.phoneNumber}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.merchantName}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.offerAudience}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.briefDescription}
                            </TableCell>
                            <TableCell align={"center"}>
                              {new Date(row.created_Date).getDate() +
                                "/" +
                                (new Date(row.created_Date).getMonth() + 1) +
                                "/" +
                                new Date(row.created_Date).getFullYear() ||
                                "----"}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.isVerify === true && (
                                <VerifiedIcon color="success" />
                              )}
                              {row.isVerify === false && (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setisOpen(true);
                                  setMerchantDataForUpdate(row);
                                }}
                              >
                                <FlakyIcon
                                  style={{ cursor: "pointer" }}
                                  color="success"
                                />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleEditMerchant(row)}
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Companies Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
        <Loader isLoading={isLoading} />
        <ToastContainer theme="colored" />

        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>

        <Dialog open={isOpen} onClose={() => setisOpen(false)}>
          <DialogTitle>Accept or Reject Merchant Request </DialogTitle>
          <TextField
            sx={{
              margin: "20px",
            }}
            placeholder={"Enter Comments for rejection"}
            name={reasonOfRejection}
            type={"text"}
            onChange={(e) => setReasonOfRejection(e.target.value)}
          />
          <Button
            onClick={() => {
              acceptMerchantReuqest();
            }}
            color="primary"
          >
            Accept
          </Button>
          <Button
            onClick={() => {
              rejectMerchantReuqest();
            }}
            color="primary"
          >
            Reject
          </Button>
        </Dialog>
      </div>
    </>
  );
};

export default Merchant;
