import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";

export const getHomeCards = createAsyncThunk(
  "homeCards/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_HOME_CARDS);
      return res;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const setHomeCards = createAsyncThunk(
  "homeCards/set",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await setQuery(DB_COLLECTION.DP_HOME_CARDS, data, "homeCard_id");
      return response;
    } catch (error: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeHomeCards = createAsyncThunk(
  "homeCard/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await deleteQuery(DB_COLLECTION.DP_HOME_CARDS, data);
      return response;
    } catch (error: any) {

      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateHomeCards = createAsyncThunk(
  "homeCard/update",
  async (data: any, { rejectWithValue }) => {
    try {
      let response = await updateQuery(
        DB_COLLECTION.DP_HOME_CARDS,
        data.homeCard_id,
        data
      );
      return response;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
interface homeCardState {
  homCardsData: any;
  setHomeCardsData: any;
  deletehomeCardData: any;
  setImageDownloadUrl: any;
  updateHomeCardData: any;
}

const initialState: any = {
  homCardsData: [],
  setHomeCardsData: [],
  setImageDownloadUrl: "",
  deletehomeCardData: false,
  updateHomeCardData: false,
} as homeCardState;

export const HomeCardsSlice = createSlice({
  name: "homeCards",
  initialState,
  reducers: {
    handleUploadImage(state, action) {},
    getHomeCard: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeCards.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.homCardsData = action.payload;
        }
      })
      .addCase(getHomeCards.rejected, (state, action) => {
        state.homCardsData = action.payload;
      })
    .addCase(setHomeCards.fulfilled, (state, action) => {
      state.setHomeCardsData = action.payload;
    })
    .addCase(setHomeCards.rejected, (state, action) => {
      state.setHomeCardsData = action.payload;
    })
    .addCase(removeHomeCards.fulfilled, (state, action) => {
      state.deletehomeCardData = action.payload;
    })
    .addCase(removeHomeCards.rejected, (state, action) => {
      state.deletehomeCardData = action.payload;
    })
    .addCase(updateHomeCards.fulfilled, (state, action) => {
      state.updateHomeCardData = action.payload;
    })
    .addCase(updateHomeCards.rejected, (state, action) => {
      state.updateHomeCardData = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { getHomeCard } = HomeCardsSlice.actions;
export default HomeCardsSlice.reducer;
