import { db } from "../../../services/firebase";

import { doc, setDoc, Timestamp } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { getSessionData } from "../Auth/AuthStates";

export const setQuery = async (
  collection: any,
  data: any,
  collectionId: any
) => {
  try {
    let loginSession: any = getSessionData();
    let id;
    if (data.id === undefined) {
      id = uuid().replaceAll("-", "");
      data.id = id;
    }
    else {
      id = data.id
    }
    data = {
      ...data,
      login_user_id: loginSession.uid,
      login_user_email: loginSession.email,
      id,
      // createdAt: Timestamp.fromDate(new Date()),
      isArchive: false,
      created_Date: new Date().getTime(),
    };

    await setDoc(doc(db, collection, id), data);

    return { response: true };
  } catch (error: any) {
    console.warn(error.message);
    return { response: false };
  }
};
