import { printLogs } from "./ErrorHandlers";

export const getObjectById = (objectArray: any, id: string, keyForReturn: string): any => {
    let value: any = "null";
    objectArray.map((item: any) => {
        if (item.id === id && item !== null) {
            // printLogs(`getObjectById fucntion: ${item}`)
            value = item[keyForReturn];
        }
    })
    return value;
}