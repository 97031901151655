import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchBrand,
  removeBrand,
  setBrands,
  updateBrand,
} from "../../../store/reduxSlice/brandSlice";
import Popup from "../../Components/Popup";
import { storage } from "../../../services/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { brandType } from "../../../types";
import { getCategoryData } from "../../../store/reduxSlice/CategorySlice";
import { getSubCategoryData } from "../../../store/reduxSlice/SubCategorySlice";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { Edit, Delete } from "@mui/icons-material";
import DialogBox from "../../Components/Dialoag";
import { getSession } from "../../utilities/Auth/AuthStates";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import SearchBar from "../../Components/SearchBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import HelpIcon from "@mui/icons-material/Help";
import Popover from "../../Components/Popover";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firstBy } from "thenby";
type Props = {};

const Brands = (props: Props) => {
  const data: any = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  let { brandsData } = data?.brands;
  const rows = brandsData;
  const { dealData } = data?.deals;
  const { categoryData } = data?.category;
  const { subCategoryData } = data?.subCategory;
  const [values, setValues] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [convertedContent, setConvertedContent] = useState<any>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [textField, setTextField] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [checked, setChecked] = React.useState(false);
  const openPopup = Boolean(anchorEl);
  const [image, setImage] = React.useState<any>([]);
  const [previewImage, setPreviewImage] = React.useState<any>([]);
  const [previewBannerImage, setPreviewBannerImage] = React.useState<any>([]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);
  const [showTopBrands, setShowTopBrands] = useState(false);

   // fetching all data here
   useEffect(() => {
    getSession();
    brandsData.length === 0 && dispatch(fetchBrand());
    categoryData.length === 0 && dispatch(getCategoryData());
    dealData.length === 0 && dispatch(fetchDeals());
    subCategoryData.length === 0 && dispatch(getSubCategoryData());
  }, [dispatch, brandsData, categoryData, subCategoryData, dealData]);

  useEffect(() => {
    setCatData(categoryData);
    setSubCatData(subCategoryData);
    setBrandData(rows);
  }, [rows]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryChange = (e: any) => {
    setSelectedCategory(e.target.value);
  };
  const filteredBrandData = selectedCategory
    ? brandData.filter((brand: any) => brand.cat_id === selectedCategory)
    : brandData;
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setValues({
      ...values,
      brand_id: "",
      cat_id: "",
      brand_description: "",
      brand_logo_url: "",
      brand_name: "",
      banner_image: "",
      sub_cat_id: "",
      offer_link: "",
      topBrand: "",
    });
    setIsEditMode(false);
    setConvertedContent(null);
    setEditorState(() => EditorState.createEmpty());
    setDisableButton(false);
    setChecked(false);
    setOpen(false);
    // setImage([]);
    setPreviewBannerImage([]);
    setPreviewImage([]);
  };
  const columns = [
    {
      id: "brand_logo_url",
      label: "Logo ",
      placeholder: "",
      type: "file",
      select: false,
    },
    {
      id: "brand_name",
      label: "Name",
      placeholder: "Name",
      type: "text",
    },
    {
      id: "brand_description",
      label: "Description",
      placeholder: "Description",
      type: "text",
      select: false,
    },
    {
      id: "banner_image",
      label: "Banner Image",
      minWidth: 170,
    },
    {
      id: "cat_name",
      label: "Category Name",
      placeholder: "Category Name",
      type: "select",
      select: true,
    },
    {
      id: "sub_cat_name",
      label: "Sub Category Name",
      placeholder: "Sub Category Name",
      type: "text",
      select: true,
    },
    {
      id: "offer_link",
      label: "Offer Link",
      minWidth: 170,
      type: "text",
      select: false,
    },
    {
      id: "topBrand",
      label: "Top Brand",
      // minWidth: 170,
      type: "text",
      select: false,
    },
    {
      id: "brands_location",
      label: "Location",
      minWidth: 100,
      placeholder: "",
      type: "text",
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const inputFieldValues = [
    {
      name: "brandName",
      id: "brand_name",
      label: "Brand Name",
      placeholder: "Brand Name",
      type: "text",
      required: true,
    },
    {
      name: "brandName",
      id: "brand_description",
      label: "Brand Description",
      placeholder: "Brand Description",
      type: "text",
      required: true,
    },
    {
      id: "banner_image",
      name: "banner_image",
      label: "Banner Image (1020 * 380) JPEG/PNG *",
      placeholder: "",
      type: "file",
      required: true,
    },
    {
      name: "brandName",
      id: "brand_logo_url",
      label: "Logo (480 * 320) JPEG/PNG *",
      placeholder: "",
      type: "file",
      required: true,
    },
    {
      id: "offer_link",
      label: "Offer Link",
      minWidth: 170,
      required: false,
    },
  ];
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const handleEditorChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
    setDisableButton(false);
  };
  const convertContentToHTML = () => {
    var rawJson = convertToRaw(editorState.getCurrentContent());
    console.log("rawJson:", rawJson);

    setConvertedContent(rawJson);
  };

  async function uploadImageTask(uploadTask: any) {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  }

  const handleFormSubmit = async (file: any) => {
    // check if fields are not empty or undefined
    if (
      values.brand_name &&
      values.brand_name != undefined &&
      values.brand_name != "undefined" &&
      values.cat_id &&
      values.cat_id != undefined &&
      values.cat_id != "undefined" &&
      // values.sub_cat_id &&
      // values.sub_cat_id != undefined &&
      image &&
      image.banner_image !== undefined &&
      image.banner_image !== "" &&
      image.banner_image !== "undefined" &&
      convertedContent &&
      convertedContent != undefined &&
      convertedContent != "undefined" &&
      image.brand_logo_url !== undefined &&
      image.brand_logo_url !== "" &&
      image.brand_logo_url !== "undefined"
    ) {
      setLoading(true);
      if (image) {
        values["brand_name"] = values["brand_name"].trim();
        let allBrandsData: any = {};
        for (const key in image) {
          const element = image[key];
          if (key === "banner_image") {
            let imageName = Math.round(Math.random() * 1000000000);
            let dealName =
              values && values.brand_name ? values.brand_name : "custom";
            const storageRef = ref(
              storage,
              `/Brands/${dealName}/${imageName}-${dealName}-banner`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getThumbnailUrl = await uploadImageTask(uploadTask);
            values["banner_image"] = getThumbnailUrl;
            allBrandsData = {
              ...values,
            };
          } else if (key === "brand_logo_url") {
            let imageName = Math.round(Math.random() * 1000000000);
            let dealName =
              values && values.brand_name ? values.brand_name : "custom";
            const storageRef = ref(
              storage,
              `/Brands/${dealName}/${imageName}-${dealName}-brand_logo_url`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getSliderUrl = await uploadImageTask(uploadTask);
            values["brand_logo_url"] = getSliderUrl;
            allBrandsData = {
              ...values,
              topBrand: checked,
              brand_description: convertedContent,
            };
          }
        }

        if (allBrandsData?.banner_image && allBrandsData?.brand_logo_url) {
          dispatch(setBrands(allBrandsData));
          await dispatch(fetchBrand());
          handleClose();
          setValues({
            ...values,
            cat_id: "",
            brand_name: "",
            cat_description: "",
          });
          setLoading(false);
        }
      }

      // values["brand_name"] = values["brand_name"].trim();
      // let allBrandsData: any = {};
      // let imageName = Math.round(Math.random() * 1000000000);
      // let brandName =
      //   values && values.brand_name ? values.brand_name : "custom";
      // const storageRef = ref(
      //   storage,
      //   `/Brands/${brandName}/${imageName}-${brandName}`
      // );
      // const uploadTask = uploadBytesResumable(storageRef, file[0]);

      // uploadTask.on(
      //   "state_changed",
      //   (snapshot: any) => {
      //     const progress = Math.round(
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      //     );
      //     setPrograssValue(progress);
      //   },
      //   (error) => {
      //     alert(error);
      //   },
      //   () => {
      //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      //       values["brand_logo_url"] = downloadURL;
      //       allBrandsData = {
      //         ...values,
      //         topBrand: change[0].topBrand,
      //         brand_description: convertedContent,
      //       };
      //       dispatch(setBrands(allBrandsData));
      //       handleClose();
      //       dispatch(fetchBrand());
      //       setLoading(false);
      //     });
      //   }
      // );
    } else {
      setDisableButton(true);
    }
  };

  const handleUpdateSubmit = async (file: any) => {
    if (
      values.brand_name &&
      values.brand_name != undefined &&
      values.brand_name != "undefined" &&
      values.cat_id &&
      values.cat_id != undefined &&
      values.cat_id != "undefined" &&
      // values.sub_cat_id &&
      // values.sub_cat_id != undefined &&
      // values.sub_cat_id != "undefined" &&
      convertedContent &&
      convertedContent != undefined &&
      convertedContent != "undefined"
    ) {
      let allBrandsData: any = {};

      setLoading(true);
      let setDataRequest = {
        brand_id: values.brand_id,
        cat_id: values.cat_id || "",
        brand_description: convertedContent || "",
        brand_logo_url: values.brand_logo_url || "",
        brand_name: values.brand_name || "",
        banner_image: values.banner_image || "",
        sub_cat_id: values.sub_cat_id || "",
        location: values?.location,
        topBrand: checked,
        offer_link: values.offer_link || "",
      };

      if (
        image.hasOwnProperty("banner_image") &&
        image.hasOwnProperty("brand_logo_url") &&
        typeof image.banner_image === "object" &&
        typeof image.brand_logo_url === "object"
      ) {
        for (const key in image) {
          const element = image[key];
          if (key === "banner_image") {
            let imageName = Math.round(Math.random() * 1000000000);
            let dealName =
              values && values.brand_name ? values.brand_name : "custom";
            const storageRef = ref(
              storage,
              `/Brands/${dealName}/${imageName}-${dealName}`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getThumbnailUrl = await uploadImageTask(uploadTask);
            setDataRequest["banner_image"] = getThumbnailUrl;
            setDataRequest = {
              ...setDataRequest,
            };
          } else if (key === "brand_logo_url") {
            let imageName = Math.round(Math.random() * 1000000000);
            let dealName =
              values && values.brand_name ? values.brand_name : "custom";
            const storageRef = ref(
              storage,
              `/Brands/${dealName}/${imageName}-${dealName}-brand_logo_url`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getHomeCardUmageUrl = await uploadImageTask(uploadTask);
            setDataRequest["brand_logo_url"] = getHomeCardUmageUrl;
            setDataRequest = {
              ...setDataRequest,
            };
          }
        }
      } else if (
        image.hasOwnProperty("banner_image") &&
        typeof image.banner_image === "object"
      ) {
        let imageName = Math.round(Math.random() * 1000000000);
        let dealName =
          values && values.brand_name ? values.brand_name : "custom";
        const storageRef = ref(
          storage,
          `/Brands/${dealName}/${imageName}-${dealName}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          image.banner_image[0]
        );
        const getSliderUrl = await uploadImageTask(uploadTask);
        setDataRequest["banner_image"] = getSliderUrl;
        setDataRequest = {
          ...setDataRequest,
        };
      } else if (
        image.hasOwnProperty("brand_logo_url") &&
        typeof image.brand_logo_url === "object"
      ) {
        let imageName = Math.round(Math.random() * 1000000000);
        let dealName =
          values && values.brand_name ? values.brand_name : "custom";
        const storageRef = ref(
          storage,
          `/Brands/${dealName}/${imageName}-${dealName}-brand_logo_url`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          image.brand_logo_url[0]
        );
        const getThumbnailUrl = await uploadImageTask(uploadTask);
        setDataRequest["brand_logo_url"] = getThumbnailUrl;
        setDataRequest = {
          ...setDataRequest,
        };
      }

      if (setDataRequest) {
        allBrandsData = {
          ...setDataRequest,
        };
        if (allBrandsData) {
          await dispatch(updateBrand(allBrandsData));
          await dispatch(fetchBrand());

          setValues({
            brand_id: "",
            cat_id: "",
            brand_description: "",
            brand_logo_url: "",
            brand_name: "",
            sub_cat_id: "",
            offer_link: "",
          });
          setConvertedContent(null);
          setEditorState(EditorState.createEmpty());
          setIsEditMode(false);
          setLoading(false);
          handleClose();
        }
      }
    } else {
      setDisableButton(true);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // on click of edit button
  const handleUpdateDeal = (data: any) => {
    console.log("data :", data);
    setIsEditMode(true);
    setValues({
      ...values,
      brand_description: data.brand_description || "",
      brand_id: data.brand_id,
      brand_logo_url: data.brand_logo_url || "",
      brand_name: data.brand_name || "",
      cat_id: data.cat_id || "",
      banner_image: data.banner_image || "",
      sub_cat_id: data.sub_cat_id || "",
      location: data.location || [],
      offer_link: data.offer_link || "",
      topBrand: data.topBrand,
    });
    handleOpen();
    setEditorState(
      EditorState.createWithContent(convertFromRaw(data.brand_description))
    );
    setPreviewBannerImage(data.banner_image);
    setPreviewImage(data.brand_logo_url);
    setChecked(
      data.topBrand === true || data.topBrand === "true" ? true : false
    );
    setConvertedContent(data.brand_description);
    setIsEditMode(true);
    setTextField(data.location ? data.location : []);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(removeBrand(itemToDelete[0]));
    await dispatch(fetchBrand());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };

  const handleDelete = (item: any) => {
    console.log("item :", item);
    let checkDealExists = dealData.filter((eachDeal: any) => {
      if (
        eachDeal.sub_cat_id != "" &&
        item.sub_cat_id != "" &&
        eachDeal.sub_cat_id === item.sub_cat_id
      ) {
        return eachDeal;
      }
    });
    console.log("checkDealExists.length ", checkDealExists.length);
    if (checkDealExists.length === 0) {
      setOpenAlert(true);
      setItemToDelete([item]);
    } else {
      setOpenModal(true);
    }
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;

    if (name === "cat_id") {
      let filteredData = categoryData.filter(
        (item: any) => item?.cat_id === value
      );
      let locationArray = filteredData[0].location
        ? filteredData[0].location
        : [];
      setValues({
        ...values,
        location: locationArray,
        [name]: value,
      });
      setTextField(locationArray);
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
    setDisableButton(false);
  };

  const handleImage = (event: any) => {
    const { name, value, files } = event.target;
    if (files[0].name.match(/\.(jpg|jpeg|png|PNG)$/i) != null) {
      setDisableButton(false);
      setImage({
        ...image,
        [name]: files,
      });
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        name === "banner_image"
          ? setPreviewBannerImage(reader.result)
          : setPreviewImage(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      toast.error(`Select valid image extension!`, {
        className: "toast-message",
      });
      setDisableButton(true);
    }
  };

  const getContent = () => (
    <Box sx={modalStyles.inputFields}>
      <div>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Location"
          name={"location"}
          value={textField}
          disabled
          onChange={(event: any) => handleInputValue(event)}
        />
        <div
          style={{
            marginTop: -19,
            // border: "1px solid black",
            marginBottom: 12,
          }}
        >
          {isEditMode ? (
            ""
          ) : (
            <>
              <HelpIcon
                style={{
                  color: "#1976d2",
                  fontSize: 20,
                  // border: "1px solid black",
                }}
                onMouseEnter={(e: any) => {
                  handleClick(e);
                }}
                // onMouseLeave={handleClosePopup}
              />
              <Popover
                PopoverContent={
                  "If you have picked any Location in respect to the category id on the Category tab, the location variant will automatically be populated after you select the category id below."
                }
                open={openPopup}
                onClose={handleClosePopup}
                anchorEl={anchorEl}
              />
            </>
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <FormControl sx={{ marginRight: "5px", width: "400px" }}>
          <InputLabel id="demo-simple-select-label">Category Id *</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="cat_id"
            className="scrollbar"
            defaultValue={values["cat_id"]}
            label="Category Id*"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {categoryData && categoryData.length > 0 ? (
              categoryData.map((eachCategory: any) => {
                return (
                  <MenuItem
                    key={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                    value={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                  >
                    {eachCategory.cat_name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem> </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: "5px", width: "400px" }}>
          <InputLabel id="demo-simple-select-label">Sub-Category Id</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="sub_cat_id"
            defaultValue={values["sub_cat_id"]}
            label="Sub-Category Id"
            onBlur={handleInputValue}
            onChange={handleInputValue}
          >
            {subCategoryData && subCategoryData.length > 0 ? (
              subCategoryData.map((eachSubCategory: any) => {
                return (
                  <MenuItem
                    key={
                      isEditMode &&
                      values["sub_cat_id"] === eachSubCategory["sub_cat_id"]
                        ? eachSubCategory["sub_cat_id"]
                        : eachSubCategory["sub_cat_id"]
                    }
                    value={
                      isEditMode &&
                      values["sub_cat_id"] === eachSubCategory["sub_cat_id"]
                        ? eachSubCategory["sub_cat_id"]
                        : eachSubCategory["sub_cat_id"]
                    }
                  >
                    {eachSubCategory.sub_cat_name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem> </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>

      {inputFieldValues.map((eachField: any) =>
        eachField.id === "brand_description" ? (
          <>
            <InputLabel
              id="demo-simple-select-label"
              style={{ marginBottom: "10px" }}
            >
              Brand Description *
            </InputLabel>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </>
        ) : (
          <>
            <InputLabel
              id="demo-simple-select-label"
              style={{ marginBottom: "10px" }}
            >
              {eachField.label} &nbsp; &nbsp;&nbsp;
              {eachField.id === "banner_image" && previewBannerImage && (
                <img
                  src={previewBannerImage}
                  style={{ width: "70px", height: "auto", paddingTop: "10px" }}
                  alt="Preview"
                />
              )}
              {eachField.id === "brand_logo_url" && previewImage && (
                <img
                  src={previewImage}
                  style={{ width: "70px", height: "auto", paddingTop: "10px" }}
                  alt="Preview"
                />
              )}
            </InputLabel>
            <TextField
              onChange={(event: any) => {
                eachField.type === "file"
                  ? handleImage(event)
                  : handleInputValue(event);
              }}
              defaultValue={
                eachField.type === "file" ? "" : values[eachField.id]
              }
              placeholder={eachField.placeholder}
              name={eachField.id}
              required={eachField.required}
              type={eachField.type}
            />
          </>
        )
      )}
      <div style={{ display: "flex" }}>
        <InputLabel style={{ marginTop: "10px" }} id="demo-multiple-chip-label">
          Top Brand
        </InputLabel>
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
    </Box>
  );
  const dialogContent =
    "Sorry you are not able to delete this. Please delete deal first";

  const handleChangeSearch = (e: any) => {
    let filteredData: any = [];
    let filterCat: any = [];
    let filterSubCat: any = [];
    e.preventDefault();
    //filter brand data
    if (e.target.value?.length > 0) {
      filteredData = rows?.filter((row: any) => {
        return (
          row?.brand_name?.toLowerCase().match(e.target.value?.toLowerCase())
          // || row?.brand_description.blocks[0]?.text?.toLowerCase()?.match(e.target.value.toLowerCase())
          );
        });
        // filter by location
      rows.filter((row: any) => {
        if (
          row.location.some((x: any) =>
            x.toLowerCase().includes(e.target.value?.toLowerCase())
          )
        ) {
          filteredData?.push(row);
        }
      });
      // filter category name
      if (filteredData?.length === 0) {
        console.log("cat filter");
        filterCat = categoryData?.filter((row: any) => {
          return row?.cat_name
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase());
        });
        filterCat.map((item: any) => {
          let data = rows.filter((item2: any) => item2.cat_id === item?.cat_id);
          data.map((itemCat: any) => {
            filteredData.push(itemCat);
          });
        });
        // filter sub category
        if (filterCat?.length === 0) {
          filterSubCat = subCategoryData.filter((row: any) => {
            return row.sub_cat_name
              ?.toLowerCase()
              .match(e.target.value?.toLowerCase());
          });
          filterSubCat?.map((item: any) => {
            let dataSub = rows?.filter(
              (item2: any) => item2?.sub_cat_id === item?.sub_cat_id
            );
            dataSub.map((subItem: any) => {
              filteredData?.push(subItem);
            });
          });
        }
      }
      setSearchInput(e.target.value);
      setSubCatData(filterSubCat);
      setCatData(filterCat);
      setBrandData(filteredData);
    } else {
      setBrandData(rows);
    }
  };

  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Brand</div>
          <div style={{ display: "flex" }}>
            <div>
              {" "}
              <FormControlLabel
                control={
                  <Switch
                    checked={showTopBrands}
                    onChange={() => setShowTopBrands(!showTopBrands)}
                    name="showTopBrands"
                    color="primary"
                  />
                }
                label="Top Brands"
              />
            </div>
            <div style={{ marginRight: 20 }}>
              <FormControl sx={{ width: 300 }}>
                <InputLabel
                  style={{ fontSize: "14px", fontWeight: 600 }}
                  id="demo-simple-select-outlined-label"
                >
                  {" "}
                  Category Name
                </InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="  Category Name"
                  id="demo-simple-select-outlined-label"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {categoryData.map((category: any) => (
                    <MenuItem key={category.cat_id} value={category.cat_id}>
                      {category.cat_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </Header>
      <ToastContainer theme="colored" />
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search by Name"
          searchBarWidth="720px"
        />
        <Box>
          <div>
            <Button variant="contained" onClick={handleOpen}>
              <AddIcon />
              Add Brands
            </Button>
            <span>
              <IconButton>
                <RefreshIcon
                  onClick={() => {
                    console.log("clicked");
                    // setSearchInput("");
                  }}
                />
              </IconButton>
            </span>
            <div>
              <Popup
                sx={{ width: "400px" }}
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Update Brand" : "Add Brand"}
                buttonText={isEditMode ? "Update Brand" : "Submit Brand"}
                content={getContent()}
                handleFormSubmit={() => handleFormSubmit(image)}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() => handleUpdateSubmit(image)}
              />
            </div>
          </div>
        </Box>
      </Box>

      {/*  Table Start */}
      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }} className="scrollbar">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        style={{ fontWeight: "bold" }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ padding: "10px" }}>
                  {brandData && brandData.length > 0 ? (
                    brandData
                      .filter((row: any) =>
                        showTopBrands
                          ? row.topBrand === true || row.topBrand === "true"
                          : true
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell
                              key={"cat_id"}
                              style={{ padding: "5px", width: 200 }}
                              align={"center"}
                            >
                              <img
                                src={row.brand_logo_url}
                                style={{ width: "70px", height: "65px" }}
                              />
                            </TableCell>
                            <TableCell key={"sub_cat_id"} align={"center"}>
                              {row.brand_name}
                            </TableCell>
                            <TableCell
                              key={"brand_name"}
                              style={{ padding: "5px", width: 550 }}
                              align={"center"}
                            >
                              {typeof row.brand_description === "object" ? (
                                <Editor
                                  toolbarHidden={true}
                                  editorState={EditorState.createWithContent(
                                    convertFromRaw(row.brand_description)
                                  )}
                                  readOnly={true}
                                />
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell key={"banner_image"} align={"center"}>
                              <img
                                alt="cat_banner"
                                src={row.banner_image}
                                style={{ width: "90px" }}
                              ></img>
                            </TableCell>
                            <TableCell
                              key={"cat_name"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {categoryData &&
                                getObjectById(
                                  categoryData,
                                  row.cat_id,
                                  "cat_name"
                                )}
                            </TableCell>
                            <TableCell
                              key={"brand_logo_url"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {subCategoryData &&
                                getObjectById(
                                  subCategoryData,
                                  row.sub_cat_id,
                                  "sub_cat_name"
                                )}
                            </TableCell>

                            <TableCell
                              key={"offer_link"}
                              style={{ padding: "5px" }}
                              align={"center"}
                              width={200}
                            >
                              <a href={row?.offer_link} target="_blank">
                                {
                                  // row?.offer_link.length > 30
                                  // ? row?.offer_link.substring(0, 28 - 3) + "..."
                                  // :
                                  row?.offer_link || "-"
                                }
                              </a>
                            </TableCell>
                            <TableCell
                              key={"topBrand"}
                              // style={{ padding: "5px" }}
                              align={"center"}
                              width={200}
                            >
                              {row?.topBrand === "true" ||
                              row?.topBrand === true
                                ? "YES"
                                : "NO"}
                            </TableCell>

                            <TableCell style={{ marginRight: "15px" }}>
                              <ul>
                                {row?.location && row?.location.length > 0 ? (
                                  row?.location.map((item: any) => {
                                    if (item) {
                                      return <li>{item}</li>;
                                    }
                                  })
                                ) : (
                                  <p>No Location Available</p>
                                )}
                              </ul>
                            </TableCell>

                            <TableCell
                              style={{ marginLeft: "5px" }}
                              align={"center"}
                            >
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleUpdateDeal(row)}
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Brands Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />

        <Loader isLoading={isLoading} />

        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>
      </div>
    </>
  );
};

export default Brands;
