import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../App.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import { fetchBrand } from "../../../store/reduxSlice/brandSlice";
import { storage } from "../../../services/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Popup from "../../Components/Popup";
import { brandType } from "../../../types";
import { getCategoryData } from "../../../store/reduxSlice/CategorySlice";
import { getSubCategoryData } from "../../../store/reduxSlice/SubCategorySlice";
import { getSession } from "../../utilities/Auth/AuthStates";
import {
  fetchDeals,
  removeDeal,
  setDeals,
  updateDeal,
} from "../../../store/reduxSlice/dealSlice";
import { CheckBox, Delete, Edit } from "@mui/icons-material";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import SearchBar from "../../Components/SearchBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import Multiselect from "multiselect-react-dropdown";
import HelpIcon from "@mui/icons-material/Help";
import Popover from "../../Components/Popover";
type Props = {};

const Deals = (props: Props) => {
  const data: any = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const { brandsData } = data.brands;
  const { categoryData } = data.category;
  const { subCategoryData } = data.subCategory;
  const { dealData } = data.deals;

  const [values, setValues] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isEditMode, setIsEditMode] = useState(false);
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [textField, setTextField] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [dealTableData, setDealTableData] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [tagName, setTagName] = React.useState<string[]>([]);
  const theme = useTheme();
  const rows: any = dealData;
  useEffect(() => {
    getSession();
  }, []);
  // fetching all data here
  useEffect(() => {
    dealTableData.length === 0 && dispatch(fetchDeals());
    dealData.length === 0 && dispatch(fetchDeals());
    brandsData.length === 0 && dispatch(fetchBrand());
    categoryData.length === 0 && dispatch(getCategoryData());
    subCategoryData.length === 0 && dispatch(getSubCategoryData());
  }, [
    dispatch,
    brandsData,
    categoryData,
    subCategoryData,
    dealData,
    dealTableData,
  ]);
  useEffect(() => {
    setDealTableData(rows);
    setCatData(categoryData);
    setSubCatData(subCategoryData);
    setBrandData(brandsData);
  }, [rows]);
  // rich text editor
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState<any>(null);

  const handleEditorChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    var rawJson = convertToRaw(editorState.getCurrentContent());
    setConvertedContent(rawJson);
  };

  // image states
  const [prograssValue, setPrograssValue] = React.useState<any>([]);
  const [image, setImage] = React.useState<any>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const openPopup = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setValues({
      ...values,
      deal_id: "",
      brand_id: "",
      cat_id: "",
      deal_description: "",
      deal_image_url: "",
      deal_name: "",
      sub_cat_id: "",
      offer_link: "",
      tags: [],
    });
    setTagName([]);
    setConvertedContent(null);
    setDisableButton(false);
    setEditorState(EditorState.createEmpty());
    setChecked(false);

    setIsEditMode(false);
    setOpen(false);
  };

  const columns = [
    {
      id: "deal_name",
      label: "Name",
      placeholder: "Name",
      type: "text",
    },
    {
      id: "deal_description",
      label: "Description",
      placeholder: "Description",
      type: "text",
      select: false,
      width: 150,
    },
    {
      id: "cat_id",
      label: "Category Name",
      placeholder: "Category Name",
      type: "select",
      select: true,
    },
    {
      id: "sub_cat_id",
      label: "Sub Category Name",
      placeholder: "Sub Category Name",
      type: "text",
      select: true,
    },
    {
      id: "brand_id",
      label: "Brand Name",
      placeholder: "Brand Name",
      type: "file",
      select: false,
    },
    {
      id: "offer_link",
      label: "Offer Link",
      minWidth: 170,
      type: "text",
      select: false,
    },
    {
      id: "topOffer",
      label: "Top Offer",
      minWidth: 170,
    },
    {
      id: "tags",
      label: "Tags",
      minWidth: 170,
    },
    {
      id: "deal_image_url",
      label: "Deal Image",
      placeholder: "",
      type: "file",
      select: false,
    },
    {
      id: "deal_location",
      label: "Location",
      placeholder: "",
      select: false,
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const inputFieldValues = [
    {
      name: "dealName",
      id: "deal_name",
      label: "Deal Name *",
      placeholder: "Deal Name",
      type: "text",
    },
    {
      name: "dealDescription",
      id: "deal_description",
      label: "Deal Description",
      placeholder: "Deal Description",
      type: "text",
    },
    {
      name: "dealLogo",
      id: "deal_image_url",
      label: " Image (480 * 320)-JPEG/PNG *",
      placeholder: "",
      type: "file",
    },
    {
      id: "offer_link",
      label: "Offer Link",
      minWidth: 170,
    },
  ];

  /**
   * This function will upload image in firebase bucket and set data into the deal collection
   * @param file  this contains file information
   */
  const handleFormSubmit = (file: any) => {
    if (
      // values.brand_id &&
      // values.brand_id != undefined &&
      // values.brand_id != "undefined" &&
      values.cat_id &&
      values.cat_id != undefined &&
      values.cat_id != "undefined" &&
      // values.sub_cat_id &&
      // values.sub_cat_id != undefined &&
      // values.sub_cat_id != "undefined" &&
      convertedContent &&
      convertedContent != undefined &&
      convertedContent != "undefined" &&
      image.length > 0
    ) {
      setLoading(true);
      let allBrandsData: any = {};
      let imageName = Math.round(Math.random() * 1000000000);
      let dealName = values && values.deal_name ? values.deal_name : "custom";
      const storageRef = ref(
        storage,
        `/Deals/${dealName}/${imageName}-${dealName}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file[0]);

      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPrograssValue(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            values["deal_image_url"] = downloadURL;
            allBrandsData = {
              ...values,
              topOffer: checked,
              deal_description: convertedContent,
              tags: tagName,
            };

            dispatch(setDeals(allBrandsData));
            handleClose();
            dispatch(fetchDeals());
            setLoading(false);
          });
        }
      );
    } else {
      setDisableButton(true);
    }
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    if (name === "cat_id") {
      let filteredData = categoryData.filter(
        (item: any) => item?.cat_id === value
      );
      let locationArray = filteredData[0].location
        ? filteredData[0].location
        : [];
      setValues({
        ...values,
        location: locationArray,
        [name]: value || "",
      });
      setTextField(locationArray);
    } else {
      setValues({
        ...values,
        [name]: value || "",
      });
    }
    setDisableButton(false);
  };

  const handleUpdateSubmit = async (file: any) => {
    setLoading(true);

    console.log(" values:", checked);
    if (
      // values.brand_id &&
      // values.brand_id != undefined &&
      // values.brand_id != "undefined" &&
      values.cat_id &&
      values.cat_id != undefined &&
      values.cat_id != "undefined" &&
      // values.sub_cat_id &&
      // values.sub_cat_id != undefined &&
      // values.sub_cat_id != "undefined" &&
      convertedContent &&
      convertedContent != undefined &&
      convertedContent != "undefined"
    ) {
      let setDataRequest = {
        deal_id: values.deal_id,
        brand_id: values.brand_id || "",
        cat_id: values.cat_id,
        deal_description: convertedContent,
        deal_image_url: values.deal_image_url,
        deal_name: values.deal_name,
        sub_cat_id: values.sub_cat_id || "",
        topOffer: checked,
        offer_link: values.offer_link || "",
        tags: values.tags || [],
        location: values?.location,
      };

      if (image.length > 0) {
        let allBrandsData: any = {};
        if (image.length > 0 && typeof image === "string") {
          allBrandsData = {
            ...setDataRequest,
          };
          if (allBrandsData) {
            await dispatch(updateDeal(allBrandsData));
            await dispatch(fetchDeals());
            handleClose();
            setValues({
              ...values,
              deal_id: "",
              brand_id: "",
              cat_id: "",
              deal_description: "",
              deal_image_url: "",
              deal_name: "",
              sub_cat_id: "",
              offer_link: "",
              tags: [],
              location: [],
            });
          }
          setChecked(false);
          setConvertedContent(null);
          setEditorState(EditorState.createEmpty());
          setIsEditMode(false);
          setLoading(false);
        } else {
          let imageName = Math.round(Math.random() * 1000000000);
          let dealName =
            values && values.deal_name ? values.deal_name : "custom";
          const storageRef = ref(
            storage,
            `/Deals/${dealName}/${imageName}-${dealName}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file[0]);

          uploadTask.on(
            "state_changed",
            (snapshot: any) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPrograssValue(progress);
            },
            (error) => {
              alert(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setDataRequest["deal_image_url"] = downloadURL;

                allBrandsData = {
                  ...setDataRequest,
                };

                if (allBrandsData) {
                  dispatch(updateDeal(allBrandsData));
                  handleClose();
                  dispatch(fetchDeals());
                  setValues({
                    ...values,
                    deal_id: "",
                    brand_id: "",
                    cat_id: "",
                    deal_description: "",
                    deal_image_url: "",
                    deal_name: "",
                    sub_cat_id: "",
                    offer_link: "",
                    tags: [],
                  });
                }
                setConvertedContent(null);
                setEditorState(EditorState.createEmpty());
                setIsEditMode(false);
                setLoading(false);
              });
            }
          );
        }
      } else {
        if (setDataRequest) {
          dispatch(updateDeal(setDataRequest));
          handleClose();
          dispatch(fetchDeals());
          setValues({
            ...values,
            deal_id: "",
            brand_id: "",
            cat_id: "",
            deal_description: "",
            deal_image_url: "",
            deal_name: "",
            sub_cat_id: "",
            offer_link: "",
            tags: [],
            location: [],
          });
        }
        setIsEditMode(false);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setDisableButton(true);
    }
  };
  // on click of edit button
  const handleUpdateDeal = (data: any) => {
    console.log("data in update :", data);
    setValues({
      ...values,
      sub_cat_id: data.sub_cat_id,
      deal_id: data.deal_id,
      brand_id: data.brand_id,
      cat_id: data.cat_id,
      deal_description: data.deal_description,
      deal_image_url: data.deal_image_url,
      deal_name: data.deal_name,
      offer_link: data.offer_link,
      tags: data.tags,
      location: data.location,
      topOffer: data.topOffers,
    });
    console.log("values in update:", values);
    setEditorState(
      EditorState.createWithContent(convertFromRaw(data.deal_description))
    );
    setTextField(data.location ? data.location : []);
    setTagName(data.tags ? data.tags : []);
    setImage(data.deal_image_url);
    setConvertedContent(data.deal_description);
    setChecked(
      data.topOffer === true || data.topOffer === "true" ? true : false
    );
    setIsEditMode(true);
    handleOpen();
  };
  const handleDelete = (item: any) => {
    setOpenAlert(true);
    setItemToDelete([item]);
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(removeDeal(itemToDelete[0]));
    await dispatch(fetchDeals());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };
  const handleImage = (event: any) => {
    setImage(event.target.files);
    setDisableButton(false);
  };

  const names = [
    "Discounts",
    "Daily Savings",
    "Holiday Seals",
    "Time Payment",
    "Local Offers",
    "Popular",
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleMultiSelect = (event: SelectChangeEvent<typeof tagName>) => {
    console.log(" targett:", event.target.value);
    const {
      target: { value },
    } = event;
    let array = typeof value === "string" ? value.split(",") : value;
    let editValues = {
      ...values,
      tags: array,
    };
    setValues(editValues);
    setTagName(typeof value === "string" ? value.split(",") : value);
  };
  function getStyles(name: string, tagName: readonly string[], theme: Theme) {
    return {
      fontWeight: tagName.indexOf(name) === -1 ? "normal" : "bold",
    };
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("event.target.checked :", event.target.checked);
    setChecked(event.target.checked);
  };

  const getContent = () => (
    <Box sx={modalStyles.inputFields}>
      <div>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Location"
          name={"location"}
          value={textField}
          disabled
          onChange={(event: any) => handleInputValue(event)}
        />
        <div
          style={{
            marginTop: -19,
            // border: "1px solid black",
            marginBottom: 12,
          }}
        >
          {isEditMode ? (
            ""
          ) : (
            <>
              <HelpIcon
                style={{
                  color: "#1976d2",
                  fontSize: 20,
                  // border: "1px solid black",
                }}
                onMouseEnter={(e: any) => {
                  handleClick(e);
                }}
                // onMouseLeave={handleClosePopup}
              />
              <Popover
                PopoverContent={
                  "If you have picked any Location in respect to the category id on the Category tab, the location variant will automatically be populated after you select the category id below."
                }
                open={openPopup}
                onClose={handleClosePopup}
                anchorEl={anchorEl}
              />
            </>
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <FormControl sx={{ marginRight: "5px", width: "400px" }}>
          <InputLabel id="demo-simple-select-label">Category Id *</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="cat_id"
            defaultValue={values["cat_id"]}
            label="Category Id*"
            onBlur={handleInputValue}
            onChange={handleInputValue}
          >
            {categoryData && categoryData.length > 0 ? (
              categoryData.map((eachCategory: any) => {
                return (
                  <MenuItem
                    key={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                    value={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                  >
                    {eachCategory.cat_name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem> </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: "5px", width: "400px" }}>
          <InputLabel id="demo-simple-select-label">Sub-Category Id</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="sub_cat_id"
            defaultValue={values["sub_cat_id"]}
            label="Sub-Category Id"
            onBlur={handleInputValue}
            onChange={handleInputValue}
          >
            {subCategoryData && subCategoryData.length > 0 ? (
              subCategoryData.map((eachSubCategory: any) => {
                return (
                  <MenuItem
                    key={
                      isEditMode &&
                      values["sub_cat_id"] === eachSubCategory["sub_cat_id"]
                        ? eachSubCategory["sub_cat_id"]
                        : eachSubCategory["sub_cat_id"]
                    }
                    value={
                      isEditMode &&
                      values["sub_cat_id"] === eachSubCategory["sub_cat_id"]
                        ? eachSubCategory["sub_cat_id"]
                        : eachSubCategory["sub_cat_id"]
                    }
                  >
                    {eachSubCategory.sub_cat_name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem> </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: "5px", width: "400px" }}>
          <InputLabel id="demo-simple-select-label">Brand Id </InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="brand_id"
            label="Brand Id"
            defaultValue={values["brand_id"]}
            onBlur={handleInputValue}
            onChange={handleInputValue}
          >
            {brandsData && brandsData.length > 0 ? (
              brandsData.map((eachSubCategory: any) => {
                return (
                  <MenuItem
                    key={
                      isEditMode &&
                      values["brand_id"] === eachSubCategory["brand_id"]
                        ? eachSubCategory["brand_id"]
                        : eachSubCategory["brand_id"]
                    }
                    value={`${
                      isEditMode &&
                      values["brand_id"] === eachSubCategory["brand_id"]
                        ? eachSubCategory["brand_id"]
                        : eachSubCategory["brand_id"]
                    }`}
                  >
                    {eachSubCategory.brand_name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem> </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      {inputFieldValues.map((eachField: any) =>
        eachField.id === "deal_description" ? (
          <>
            <InputLabel
              id="demo-simple-select-label"
              style={{ marginBottom: "10px" }}
            >
              Deal Description *
            </InputLabel>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </>
        ) : (
          <>
            <InputLabel style={{ margin: "5px 5px" }}>
              {eachField.label}
            </InputLabel>
            <TextField
              onChange={(event: any) => {
                eachField.type === "file"
                  ? handleImage(event)
                  : handleInputValue(event);
              }}
              defaultValue={
                eachField.type === "file" ? "" : values[eachField.id]
              }
              placeholder={eachField.placeholder}
              name={eachField.id}
              // label={eachField.label}
              required
              type={eachField.type}
            />
          </>
        )
      )}
      {/* multi select  */}
      <FormControl fullWidth={true}>
        <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
        <Select
          fullWidth={true}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={tagName} //
          onChange={handleMultiSelect}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value: any) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, tagName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/*<div><img src{item?.mini}/><p>{item.name}</p></div>  */}
      <div style={{ display: "flex" }}>
        <InputLabel style={{ marginTop: "10px" }} id="demo-multiple-chip-label">
          Top Offer
        </InputLabel>
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={topOffer}
            onChange={(e: any) => {
              setTopOffer(!topOffer);
              setChange({ topOffer: "true" });
            }}
            name="topOffers"
          />
        }
        label="Top Offer"
      /> */}
    </Box>
  );
  const handleChangeSearch = (e: any) => {
    let filteredData: any = [];
    let filterCat: any = [];
    let filterSubCat: any = [];
    let filterBrand: any = [];
    e.preventDefault();
    // filter deal data
    if (e.target.value?.length > 0) {
      filteredData = rows?.filter((row: any) => {
        return (
          row?.deal_name?.toLowerCase().match(e.target.value?.toLowerCase()) ||
          row?.deal_description
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase())
        );
      });
      // filter by location
      rows.filter((row: any) => {
        if (
          row.location.some((x: any) =>
            x.toLowerCase().includes(e.target.value?.toLowerCase())
          )
        ) {
          filteredData?.push(row);
        }
      });
      // filter category name
      if (filteredData?.length === 0) {
        filterCat = categoryData?.filter((row: any) => {
          return row?.cat_name
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase());
        });
        filterCat.map((item: any) => {
          let data = rows.filter((item2: any) => item2.cat_id === item?.cat_id);
          data.map((itemCat: any) => {
            filteredData.push(itemCat);
          });
        });
        // filter sub category
        if (filterCat?.length === 0) {
          filterSubCat = subCategoryData.filter((row: any) => {
            return row.sub_cat_name
              ?.toLowerCase()
              .match(e.target.value?.toLowerCase());
          });
          filterSubCat?.map((item: any) => {
            let dataSub = rows?.filter(
              (item2: any) => item2?.sub_cat_id === item?.sub_cat_id
            );
            dataSub.map((subItem: any) => {
              filteredData?.push(subItem);
            });
          });
          // filter brands
          if (filterSubCat?.length === 0) {
            filterBrand = brandsData.filter((row: any) => {
              return row.brand_name
                ?.toLowerCase()
                .match(e.target.value?.toLowerCase());
            });
            filterBrand?.map((item: any) => {
              let dataBrand = rows?.filter(
                (item2: any) => item2?.brand_id === item?.brand_id
              );
              dataBrand.map((brandItem: any) => {
                filteredData?.push(brandItem);
              });
            });
          }
        }
      }
      setSearchInput(e.target.value);
      setDealTableData(filteredData);
      setCatData(filterCat);
      setSubCatData(filterSubCat);
      setBrandData(filterBrand);
    } else {
      setDealTableData(rows);
    }
  };
  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Deals</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          value={searchInput}
          onChange={handleChangeSearch}
          placeholder="Search by  Name , Description"
          searchBarWidth="720px"
        />
        <Box>
          <div>
            <Button variant="contained" onClick={handleOpen}>
              <AddIcon />
              Add Deal
            </Button>
            <IconButton>
              <RefreshIcon />
            </IconButton>
            <div>
              <Popup
                sx={{ width: "400px" }}
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Update Deal" : "Add Deal"}
                buttonText={isEditMode ? "Update Category" : "Submit Deal"}
                content={getContent()}
                handleFormSubmit={() => handleFormSubmit(image)}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() => handleUpdateSubmit(image)}
              />
            </div>
          </div>
        </Box>
      </Box>
      {/*  Table Start */}
      <div
        style={{
          marginLeft: "3.5%",
        }}
      >
        <Grid container>
          <Paper sx={{ width: "100%" }} className="scrollbar">
            <TableContainer sx={{ maxHeight: 700 }} className="scrollbar">
              <Table
                stickyHeader
                aria-label="sticky table"
                // sx={{ width: "100%" }}
              >
                <TableHead>
                  <TableRow sx={{ alignItems: "center" }}>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        style={{ fontWeight: "bold" }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealTableData && dealTableData.length > 0 ? (
                    dealTableData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell
                              key={"cat_id"}
                              style={{ padding: "2px" }}
                              align={"center"}
                              width={300}
                            >
                              {row?.deal_name}
                            </TableCell>
                            <TableCell
                              key={"deal_description"}
                              align={"center"}
                              width={500}
                            >
                              {typeof row.deal_description === "object" ? (
                                <Editor
                                  toolbarHidden={true}
                                  editorState={EditorState.createWithContent(
                                    convertFromRaw(row.deal_description)
                                  )}
                                  readOnly={true}
                                />
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell
                              key={"brand_id"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {categoryData &&
                                getObjectById(
                                  categoryData,
                                  row.cat_id,
                                  "cat_name"
                                )}
                            </TableCell>
                            <TableCell
                              key={"deal_name"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {subCategoryData &&
                                getObjectById(
                                  subCategoryData,
                                  row.sub_cat_id,
                                  "sub_cat_name"
                                )}
                            </TableCell>
                            <TableCell
                              key={"brand_name"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {brandsData &&
                                getObjectById(
                                  brandsData,
                                  row.brand_id,
                                  "brand_name"
                                )}
                            </TableCell>

                            <TableCell
                              key={"offer_link"}
                              style={{ padding: "5px" }}
                              align={"center"}
                              width={200}
                            >
                              <a href={row?.offer_link || null} target="_blank">
                                {row?.offer_link && row?.offer_link.length > 30
                                  ? row?.offer_link.substring(0, 28 - 3) + "..."
                                  : row?.offer_link || "-"}
                              </a>
                            </TableCell>
                            <TableCell
                              key={"topOffer"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {row?.topOffer === "true" ||
                              row?.topOffer === true
                                ? "YES"
                                : "NO"}
                            </TableCell>
                            <TableCell
                              key={"tags"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {row?.tags?.join(", ")}
                            </TableCell>

                            <TableCell
                              key={"deal_image_url"}
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              <img
                                src={row.deal_image_url}
                                style={{ width: "70px", height: "65px" }}
                              ></img>
                            </TableCell>
                            <TableCell style={{ padding: "5px" }}>
                              <ul>
                                {row?.location &&
                                  row?.location.map((item: any) => {
                                    if (item) {
                                      return <li>{item}</li>;
                                    } else {
                                      return "No Location Available";
                                    }
                                  })}
                              </ul>
                            </TableCell>
                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleUpdateDeal(row)}
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Brands Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
};

export default Deals;
