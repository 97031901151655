// import { db } from "../../services/firebase";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../../services/firebase";
import { getSessionData } from "../Auth/AuthStates";

export const updateQuery = async (collection: any, id: any, data: any) => {
  try {
    let loginSession: any = getSessionData()

    const userRef = doc(db, collection, id);
    await updateDoc(userRef, {
      merchant_id: loginSession.uid,
      merchant_email: loginSession.email,
      ...data,
    });
    return { response: true };
  } catch (error: any) {
  console.log('error :', error);
    console.warn(error.message);
    return { response: false };
  }
};

export const isDataExistByAttributeQuery = async (
  collectionName: string,
  fieldAttr: string,
  fieldValue: string
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(fieldAttr, "==", fieldValue),
      where("isArchive", "==", false)
    );
    const querySnapshot = await getDocs(q);
    console.log("querySnapshot", querySnapshot.size);
    if (querySnapshot.size > 0) {
      return querySnapshot.docs[0].data();
    } else {
      return false;
    }
  } catch (error:any) {
    console.warn(error.message);
    return false;
  }
};
