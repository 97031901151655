export const DB_COLLECTION: any = {
  DP_BRANDS: "DP_BRANDS",
  DP_CATEGORY: "DP_CATEGORY",
  DP_DEALS: "DP_DEALS",
  DP_SUBCATEGORY: "DP_SUBCATEGORY",
  DP_ADMIN_USER: "DP_ADMIN_USER",
  DP_SLIDER: "DP_SLIDERS",
  DP_HOME_CARDS: "DP_HOME_CARDS",
  DP_VIDEOS: "DP_VIDEOS",
  DP_VIDEOS_CATEGORY: "DP_VIDEOS_CATEGORY",
  DP_COMPANIES: "DP_COMPANIES",
  DP_MERCHANT: "DP_MERCHANT",
  DP_MERCHANT_USERS: "DP_MERCHANT_USERS",
  DP_REFERRAL_CODE: "DP_REFERRAL_CODE",
  DP_REFERAL_USERS: "DP_REFERAL_USERS",
  DP_PAYMENT_RECEIPT: "DP_PAYMENT_RECIPT",
  DP_COUPAN_CODE: "DP_COUPAN_CODE",
  DP_COUPON: "DP_COUPON",
  DP_MERCHANT_INVOICE: "DP_MERCHANT_INVOICE",
};
