import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery, getAllQueryWithArchive } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { subCategoryType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";

export const getUserRefferalData = createAsyncThunk(
  "userRefferal/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQueryWithArchive(DB_COLLECTION.DP_REFERAL_USERS);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setUserRefferalData = createAsyncThunk(
  "userRefferal/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(
        DB_COLLECTION.DP_REFERAL_USERS,
        data,
        "refferal_user_id"
      );
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserRefferalData = createAsyncThunk(
  "userRefferal/update",
  async (data: any, { rejectWithValue }) => {
    console.log("data IN SLICE:", data);
    try {
      let res = await updateQuery(
        DB_COLLECTION.DP_REFERAL_USERS,
        data.refferal_user_id,
        data
      );
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserRefferalData = createAsyncThunk(
  "userRefferal/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_REFERAL_USERS, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface categoryState {
  userRefferalData: any;
  setUserRefferalData: any;
  updateUserRefferalData: any;
  deleteUserRefferalData: any;
}

const initialState: any = {
  userRefferalData: [],
  setUserRefferalData: [],
  updateUserRefferalData: [],
  deleteUserRefferalData: false,
} as categoryState;

export const userRefferalSlice = createSlice({
  name: "userRefferal",
  initialState,

  reducers: {
    setuserRefferal: () => {},
    getuserRefferal: (state, { payload }) => {},
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUserRefferalData.fulfilled,
        (state, action: PayloadAction<subCategoryType[]>) => {
          if (action.payload.length > 0) {
            state.userRefferalData = action.payload;
          }
        }
      )
      .addCase(getUserRefferalData.rejected, (state, action) => {
        state.userRefferalData = action.payload;
      })
      .addCase(setUserRefferalData.fulfilled, (state, action) => {
        state.setUserRefferalData = action.payload;
      })
      .addCase(setUserRefferalData.rejected, (state, action) => {
        state.setUserRefferalData = action.payload;
      })
      .addCase(updateUserRefferalData.fulfilled, (state, action) => {
        state.updateUserRefferalData = action.payload;
      })
      .addCase(updateUserRefferalData.rejected, (state, action) => {
        state.updateUserRefferalData = action.payload;
      })
      .addCase(deleteUserRefferalData.fulfilled, (state, action) => {
        state.deleteUserRefferalData = action.payload;
      })
      .addCase(deleteUserRefferalData.rejected, (state, action) => {
        state.deleteUserRefferalData = action.payload;
      });
  },
});

export const { setuserRefferal } = userRefferalSlice.actions;
export default userRefferalSlice.reducer;
