import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
// import { CompaniesType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
import { deleteObject, ref } from "@firebase/storage";
import { storage } from "../../services/firebase";
import { toast } from "react-toastify";


export const getCompaniesData = createAsyncThunk(
  "Companies/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_COMPANIES);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setCompaniesData = createAsyncThunk(
  "Companies/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(DB_COLLECTION.DP_COMPANIES, data, "company_id");
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCompaniesData = createAsyncThunk(
  "Companies/update",
  async (data: any, { rejectWithValue }) => {
    console.log('data IN SLICE:', data);
    try {
      let res = await updateQuery(DB_COLLECTION.DP_COMPANIES, data.company_id, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCompaniesData = createAsyncThunk(
  "Companies/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_COMPANIES, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface Companiestate {
  CompaniesData: any;
  setCompaniesData: any;
  updateCompaniesData: any;
  deleteCompaniesData: any;
}

const initialState: any = {
  CompaniesData: [],
  setCompaniesData: [],
  updateCompaniesData: [],
  deleteCompaniesData: false,
} as Companiestate;

export const CompaniesSlice = createSlice({
  name: "Companies",
  initialState,

  reducers: {
    setCompanies: () => { },
    getCompanies: (state, { payload }) => { },
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCompaniesData.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.CompaniesData = action.payload;
          }
        }
      )
      .addCase(getCompaniesData.rejected, (state, action) => {
        state.CompaniesData = action.payload;
      })
      .addCase(setCompaniesData.fulfilled, (state, action) => {
        state.setCompaniesData = action.payload;
      })
      .addCase(setCompaniesData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setCompaniesData = action.payload;
      })
      .addCase(updateCompaniesData.fulfilled, (state, action) => {
        state.updateCompaniesData = action.payload;
      })
      .addCase(updateCompaniesData.rejected, (state, action) => {
        state.updateCompaniesData = action.payload;
      })
      .addCase(deleteCompaniesData.fulfilled, (state, action) => {
        state.deleteCompaniesData = action.payload;
      })
      .addCase(deleteCompaniesData.rejected, (state, action) => {
        state.deleteCompaniesData = action.payload;
      })
  },
});

// Action creators are generated for each case reducer function
export const { setCompanies } = CompaniesSlice.actions;
export default CompaniesSlice.reducer;
