import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";

export const deleteQuery = async (collection: any, item: any) => {
  try {
    let data = {
      ...item,
    };
    data.isArchive = true;
    const userRef = doc(db, collection, data.id);
    await updateDoc(userRef, {
      ...data,
    });
    return true;
  } catch (error: any) {
    console.warn(error.message);
    return false;
  }
};
