import { upload } from '@testing-library/user-event/dist/upload';
import React, {useState} from 'react'
import { getMerchantInvoiceData, updateMerchantInvoiceData } from '../../../store/reduxSlice/MerchantInvoiceSlice';
import { useDispatch, useSelector } from "react-redux";

type Props = {}

const Utiles = () => {
  const dispatch: any = useDispatch();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = React.useState(0);
    const [isModalOpen, setisModalOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [uploadType, setuploadType] = useState<any>("");
    const [isImageModal, setisImageModal] = useState(false);
    const [imageUrl, setimageUrl] = useState<any>("")
    const [isVerfyRecipt, setisVerfyRecipt] = useState("");
    const [reciptData, setreciptData] = useState<any>([]);
    const [openImage, setOpenImage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    const verifyEmail = async (data: any) => {
      setLoading(true);

      let updateData: any = {
        ...data,
        isPaid: true,
        receiptVerficationDateTime: new Date().getTime(),
        isVerify: true,
      }

      dispatch(updateMerchantInvoiceData(updateData)).then(() => {
        dispatch(getMerchantInvoiceData())
        setLoading(false);
        handleCloseModalImage();
      })
      // let requestBody: any = {
      //  ...data
      // };
  
      // const APIData = await axios.post(
      //   VERIFY_QR_EMAIL,
      //   { ...requestBody },
      //   {
      //     headers: HEADERS,
      //   }
      // );
      // console.log("APIData", APIData);
      // if (APIData?.data?.data?.object === "error") {
      //   console.error(APIData);
      //   alert("Something went wrong. Please try again later!");
  
      //   setLoading(false);
      //   // seterrorMessage(APIData?.data?.data?.code);
      //   // setisErrorOccur(true);
      // } else if (APIData?.data?.data === "Failed") {
      //   alert("Something went wrong. Please try again later!");
  
      //   setLoading(false);
      //   // seterrorMessage(APIData?.data?.data);
      //   // setisErrorOccur(true);
      // } else {
      //   setLoading(false);
      //   setTimeout(() => {
      //     // dispatch(getQRPayment());
      //   }, 1000);
      //   handleCloseModalImage();
      // }
    };

    const verifyRecipt = async () => {
      if (window.confirm("Are you sure you want to verify this receipt?")) {
        setLoading(true);
        verifyEmail(reciptData);
      } else {
        setLoading(false);
      }
    };

    const rejectRecipt = (rejectReason: string) => {
      if (window.confirm("Are you sure you want to Reject this receipt?")) {
        setLoading(true);
        let updateData: any = {
          ...reciptData,
          receiptRejectReason: rejectReason,
        }

        dispatch(updateMerchantInvoiceData(updateData)).then(() => {
          dispatch(getMerchantInvoiceData())
          setLoading(false);
          handleCloseModalImage();
        })
      }
    };

    const handleCloseModalImage = () => {
      setOpenImage(false);
    };

    const handleOpenModalImage = (data: any, isVerify: any) => {
      setisVerfyRecipt(isVerify);
      setreciptData(data);
      setimageUrl(data.paymentReceiptImage);
      setOpenImage(true);
    };

    const handleChangeModal = (row: any, uploadType: string) => {
      setuploadType(uploadType)
      setSelectedPayment(row)
      setisModalOpen(isModalOpen => !isModalOpen);
    };

    const handleImage= () => {
      setisImageModal(isImageModal => !isImageModal);
    };
    
    const columns = [
        {
          id: "merchantName",
          label: "Merchant Name",
          minWidth: 60,
        },
        {
          id: "invoiceAmount",
          label: "Invoice Amount",
          minWidth: 70,
        },
        {
            id: "paymentDate",
            label: "Invoice Issued",
            minWidth: 70,
        },
        {
          id: "isPaid",
          label: "Paid",
          minWidth: 70,
        },
        {
          id: "receiptVerficationDateTime",
          label: "Date(Admin Verify)",
          minWidth: 70,
        },
        {
            id: "isUploadReceipt",
            label: "Receipt Upload",
            minWidth: 70,
        },
        {
          id: "uploadReceiptDateTime",
          label: "Date(Receipt Upload)",
          minWidth: 70,
        },
        {
          id: "receiptRejectReason",
          label: "Receipt Rejection Reason",
          minWidth: 70,
        },
        {
          id: "paymentQRImage",
          label: "Payment QR",
          minWidth: 70,
        },
        {
          id: "paymentReceiptImage",
          label: "Payment Receipt Image",
          minWidth: 70,
        },
        {
          id: "action",
          label: "Action",
          type: "button",
          select: false,
        },
      ];

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const containerStyle: any = {
      position: "relative",
      display: "inline-block",
      overflow: "hidden",
      cursor: "pointer"
    };
  
    const imageStyle: any = {
      width: "100%",
      transition: "filter 0.3s, transform 0.3s",
      filter: isHovered ? "blur(5px)" : "none",
      transform: isHovered ? "scale(1.1)" : "none",
    };
  
    const iconOverlayStyle: any = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      opacity: isHovered ? 1 : 0,
      transition: "opacity 0.3s",
    };

    return {
        columns,
        rowsPerPage,
        page,
        isModalOpen,
        selectedPayment,
        containerStyle,
        imageStyle,
        iconOverlayStyle,
        uploadType,
        isImageModal,
        imageUrl,
        isVerfyRecipt,
        openImage,
        isLoading, 
        verifyRecipt,
        rejectRecipt,
        setLoading,
        handleCloseModalImage,
        handleOpenModalImage,
        setimageUrl,
        handleImage,
        setIsHovered,
        handleChangeRowsPerPage,
        handleChangePage,
        setRowsPerPage,
        setPage,
        handleChangeModal
    }
}

export default Utiles;