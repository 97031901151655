import {
  onAuthStateChanged,
  updateProfile,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { WEB_CONFIG } from "../../../config/web_config";
import { CONSTANT, ROUTES } from "../../../constant";
import { auth } from "../../../services/firebase";
import { printLogs } from "../Helper/ErrorHandlers";
import { setQuery } from "../queries/setQuery";

export const getSession = () => {
  onAuthStateChanged(auth, (user) => {
    printLogs(`user session: ${user}`);
    if (!user) window.location.href = `${WEB_CONFIG.base_url}${ROUTES.LOGIN}`;
  });
};

export const isUserLogin = () => {
  onAuthStateChanged(auth, (user) => {
    printLogs(`user seesion: ${user}`);
    if (user) window.location.href = `${WEB_CONFIG.base_url}${ROUTES.HOME}`;
  });
};

export const updateUserProfile = () => {
  const user: any = getSessionData();
  if (user) {
    updateProfile(user, {
      displayName: "Jane Q. User",
      photoURL: "https://example.com/jane-q-user/profile.jpg",
    })
      .then(() => {
        // Profile updated!
        // ...
      })
      .catch((error) => {
        // An error occurred
        // ...
      });
  }
};

export const getSessionData = () => {
  const user = auth.currentUser;
  if (user) return JSON.parse(JSON.stringify(user));
  else return false;
};

export const signOutUser = () => {
  signOut(auth)
    .then(() => {
      window.localStorage.removeItem(CONSTANT.DEEPERKS_SESSION_ADMIN);
      window.location.href = `${WEB_CONFIG.base_url}${ROUTES.LOGIN}`;
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const createUser = (
  data: any,
  password: any,
  collectionName: string
) => {
  new Promise( (resolve: any) => {
    createUserWithEmailAndPassword(auth, data.email, password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      updateProfile(userCredential.user, {
        photoURL: "MERCHANT"
      }).then(async () => {
        let res: any = await setQuery(collectionName, {...data, id: user.uid}, user.uid)
        resolve(res)
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  })
  
};
