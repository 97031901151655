export const setDateFormate = (date: any) => {
  try {
    let day: any = new Date(date).getUTCDate();
    let month: any = new Date(date).getUTCMonth() + 1;
    let year: any = new Date(date).getUTCFullYear();

    return month + "/" + day + "/" + year;
  } catch (e: any) {
    console.log("error in set Date formate ", e);
  }
};
