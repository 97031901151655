import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { brandType } from "../../types/index";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { storage } from "../../services/firebase";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
// add new brand
export const setBrands = createAsyncThunk(
  "brand/set",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_BRANDS
      let response = await setQuery(DB_COLLECTION.DP_BRANDS, data, "brand_id");
      return response;
    } catch (error: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);
// list all brands
export const fetchBrand = createAsyncThunk(
  "brand/get",
  async (_, { rejectWithValue }) => {
    try {
      let response = await getAllQuery(DB_COLLECTION.DP_BRANDS);
      return response;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
// update brand data
export const updateBrand = createAsyncThunk(
  "brand/update",
  async (data: any, { rejectWithValue }) => {
    try {
      let response = await updateQuery(
        DB_COLLECTION.DP_BRANDS,
        data.brand_id,
        data
      );
      return response;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
// delete brand
export const removeBrand = createAsyncThunk(
  "brand/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await deleteQuery(DB_COLLECTION.DP_BRANDS, data);
      return response;
    } catch (error: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

interface brandState {
  brandsData: any;
  setBrandsData: any;
  deletedBrandsData: any;
  setImageDownloadUrl: any;
  updatedBrand: any;
}

const initialState = {
  brandsData: [],
  setBrandsData: [],
  deletedBrandsData: false,
  updatedBrand: false,
  setImageDownloadUrl: "",
} as brandState;

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    handleUploadImage(state, action: PayloadAction<any>) {},
    getBrand: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchBrand.fulfilled,
        (state, action: PayloadAction<brandType[]>) => {
          if (action.payload.length > 0) {
            state.brandsData = action.payload;
          }
        }
      )
      .addCase(fetchBrand.rejected, (state, action) => {
        state.brandsData = action.payload;
      })
      .addCase(setBrands.fulfilled, (state, action) => {
        state.setBrandsData = action.payload;
      })
      .addCase(removeBrand.fulfilled, (state, action) => {
        state.deletedBrandsData = action.payload;
      })
      .addCase(removeBrand.rejected, (state, action) => {
        state.deletedBrandsData = action.payload;
      })
      .addCase(updateBrand.fulfilled, (state, action) => {
        state.updatedBrand = action.payload;
      })
      .addCase(updateBrand.rejected, (state, action) => {
        state.updatedBrand = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { getBrand } = brandSlice.actions;
export default brandSlice.reducer;
