import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
const DialogBox = ({ openModal, handleModalClose, dialogContent }: any) => {
  return (
    <Dialog
      open={openModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Opps Info"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <Alert severity="info" color="info">
          {dialogContent}
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>CANCEL</Button>
        <Button onClick={handleModalClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
