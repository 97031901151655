import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { dealType } from "../../types/index";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { storage } from "../../services/firebase";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
export const setDeals = createAsyncThunk(
  "deal/set",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await setQuery(DB_COLLECTION.DP_DEALS, data, "deal_id");
      return response;
    } catch (error: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeals = createAsyncThunk(
  "deal/get",
  async (_, { rejectWithValue }) => {
    try {
      let response = await getAllQuery(DB_COLLECTION.DP_DEALS);
      return response;
    } catch (err: any) {
      console.log("err in delete:", err);

      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeDeal = createAsyncThunk(
  "deal/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await deleteQuery(DB_COLLECTION.DP_DEALS, data);
      return response;
    } catch (error: any) {
      console.log("err in delete:", error);

      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

// update deal data
export const updateDeal = createAsyncThunk(
  "deal/update",
  async (data: any, { rejectWithValue }) => {
    console.log("data :", data);
    try {
      let response = await updateQuery(
        DB_COLLECTION.DP_DEALS,
        data.deal_id,
        data
      );
      return response;
    } catch (err: any) {
      console.log("err :", err);
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
interface brandState {
  dealData: any;
  setDealData: any;
  deletedDeal: any;
  setImageDownloadUrl: any;
  updatedDeal: any;
}

const initialState = {
  dealData: [],
  setDealData: [],
  setImageDownloadUrl: "",
  deletedDeal: false,
  updatedDeal: false,
} as brandState;

export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    handleUploadImage(state, action: PayloadAction<any>) {},
    getBrand: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchDeals.fulfilled,
        (state, action: PayloadAction<dealType[]>) => {
          if (action.payload.length > 0) {
            state.dealData = action.payload;
          }
        }
      )
      .addCase(fetchDeals.rejected, (state, action) => {
        state.dealData = action.payload;
      })
      .addCase(setDeals.fulfilled, (state, action) => {
        state.setDealData = action.payload;
      })
      .addCase(setDeals.rejected, (state, action) => {
        state.setDealData = action.payload;
      })
      .addCase(removeDeal.fulfilled, (state, action) => {
        state.deletedDeal = action.payload;
      })
      .addCase(removeDeal.rejected, (state, action) => {
        state.deletedDeal = action.payload;
      })
      .addCase(updateDeal.fulfilled, (state, action) => {
        state.updatedDeal = action.payload;
      })
      .addCase(updateDeal.rejected, (state, action) => {
        state.updatedDeal = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export default dealSlice.reducer;
