import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navigation from './Navigations/Navigation';

function App() {
  return (
    <Navigation />
  );
}

export default App;
