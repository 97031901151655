import React, { useState, useEffect, useContext, useDebugValue } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { signinWithEmail } from "../../utilities/Auth/EmailAndPassword";
import { errorMessage, printLogs } from "../../utilities/Helper/ErrorHandlers";
import { ROUTES } from "../../../constant";
import CustomAlert from "../../Components/CustomAlert";
import { isUserLogin } from "../../utilities/Auth/AuthStates";
import Loader from "../../Components/Loader";

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation()
  const [email, setemail] = useState<string>("");
  const [password, setpassword] = useState<string>("")
  const [responseError, setresponseError] = useState<String | null>(null)
  const [isLoading, setisLoading] = useState(false);
  const theme = createTheme();

  const isError = () => {
    if (email === "" || password === "") return true
    else return false
  }

  const onPressLogin = async () => {
    setisLoading(true)
    let response: any = await signinWithEmail(email, password)
    if (response.status) {
      navigate(ROUTES.HOME)
      setisLoading(false)
    } 
    else {
      setresponseError(response.errorMessage)
      setisLoading(false)
    }
  }

  useEffect(() => {
    isUserLogin()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth={"sm"} sx={{ mb: 5 }}>
        <Paper
          elevation={1}
          sx={{
            my: { xs: 3, md: 6 },
            p: { xs: 2, md: 3 },
          }}
        >
          <Grid container spacing={0} style={{ marginTop: 0, marginBottom: 0 }}>
            <Box style={{ margin: "0px auto" }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <PersonIcon sx={{ fontSize: 100 }} />
              </Box>
              <Typography style={{ fontSize: 25 }}>
                Login To Dashboard
              </Typography>
            </Box>

            <Grid item xs={12} sm={12}>
              <TextField
                margin={"normal"}
                fullWidth
                label="Email"
                name="email"
                placeholder="Your email address"
                type="email"
                value={email}
                onChange={(e: any) => {
                  responseError && setresponseError(null)
                  setemail(e.target.value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                margin={"normal"}
                fullWidth
                label="Password"
                name="password"
                placeholder="Your password"
                type="password"
                value={password}
                onChange={(e: any) => {
                  responseError && setresponseError(null)
                  setpassword(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        
          <Box sx={{ width: "100%", marginBottom: 3 }}>
            <Button
              variant="contained"
              sx={{ mt: 3, width: "100%", height: 50 }}
              disabled={isError()}
              color="primary"
              onClick={() => onPressLogin()}
            >
              Login
            </Button>
          </Box>
          {
            responseError && (
              <CustomAlert severity={"error"} message={responseError}/>
            )
          }
        </Paper>
        <Loader isLoading={isLoading}/>
      </Container>
    </ThemeProvider>
  );
}
