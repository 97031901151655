export const Flags = [
  {
    name: "Brazil",
    mini: "http://flags.fmcdn.net/data/flags/mini/br.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/br.png",
  },
  {
    name: "Canada",
    mini: "http://flags.fmcdn.net/data/flags/mini/ca.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ca.png",
  },
  {
    name: "Colombia",
    mini: "http://flags.fmcdn.net/data/flags/mini/co.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/co.png",
  },
  {
    name: "Costa Rica",
    mini: "http://flags.fmcdn.net/data/flags/mini/cr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cr.png",
  },
  {
    name: "Germany",
    mini: "http://flags.fmcdn.net/data/flags/mini/de.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/de.png",
  },
  {
    name: "India",
    mini: "http://flags.fmcdn.net/data/flags/mini/in.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/in.png",
  },
  {
    name: "Ireland",
    mini: "http://flags.fmcdn.net/data/flags/mini/ie.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ie.png",
  },
  {
    name: "Italy",
    mini: "http://flags.fmcdn.net/data/flags/mini/it.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/it.png",
  },
  {
    name: "Luxembourg",
    mini: "http://flags.fmcdn.net/data/flags/mini/lu.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lu.png",
  },
  {
    name: "Singapore",
    mini: "http://flags.fmcdn.net/data/flags/mini/sg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sg.png",
  },
  {
    name: "Mexico",
    mini: "http://flags.fmcdn.net/data/flags/mini/mx.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mx.png",
  },
  {
    name: "  Philippines",
    mini: "http://flags.fmcdn.net/data/flags/mini/ph.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ph.png",
  },
  {
    name: "Poland",
    mini: "http://flags.fmcdn.net/data/flags/mini/pl.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pl.png",
  },
  {
    name: "Spain",
    mini: "http://flags.fmcdn.net/data/flags/mini/es.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/es.png",
  },
  {
    name: "United Kingdom",
    mini: "http://flags.fmcdn.net/data/flags/mini/gb.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gb.png",
  },
  {
    name: "United States",
    mini: "http://flags.fmcdn.net/data/flags/mini/us.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/us.png",
  },
];
