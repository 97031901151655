import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { dealType } from "../../types/index";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { storage } from "../../services/firebase";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
export const setSlider = createAsyncThunk(
  "slider/set",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await setQuery(DB_COLLECTION.DP_SLIDER, data, "slider_id");
      return response;
    } catch (error: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSlider = createAsyncThunk(
  "slider/get",
  async (_, { rejectWithValue }) => {
    try {
      let response = await getAllQuery(DB_COLLECTION.DP_SLIDER);
      return response;
    } catch (err: any) {
      console.log("err in delete:", err);

      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeSlider = createAsyncThunk(
  "slider/delete",
  async (data: any, { rejectWithValue }) => {
  console.log('data :', data);
    try {
      // api call here for DP_DEALS
      let response = await deleteQuery(DB_COLLECTION.DP_SLIDER, data);
      return response;
    } catch (error: any) {
      console.log("err in delete:", error);

      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

// update deal data
export const updateSlider = createAsyncThunk(
  "slider/update",
  async (data: any, { rejectWithValue }) => {
    console.log("data :", data);
    try {
      let response = await updateQuery(DB_COLLECTION.DP_SLIDER, data.slider_id, data);
      return response;
    } catch (err: any) {
      console.log("err :", err);
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
interface brandState {
  sliderData: any;
  setSliderData: any;
  deletedSlider: any;
  setImageDownloadUrl: any;
  updatedSlider: any;
}

const initialState = {
  sliderData: [],
  setSliderData: [],
  setImageDownloadUrl: "",
  deletedSlider: false,
  updatedSlider: false,
} as brandState;

export const sliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {
    handleUploadImage(state, action: PayloadAction<any>) {},
    getSlider: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlider.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.sliderData = action.payload;
        }
      })
      .addCase(fetchSlider.rejected, (state, action) => {
        state.sliderData = action.payload;
      })
      .addCase(setSlider.fulfilled, (state, action) => {
        state.setSliderData = action.payload;
      })
      .addCase(setSlider.rejected, (state, action) => {
        state.setSliderData = action.payload;
      })
      .addCase(removeSlider.fulfilled, (state, action) => {
        state.deletedSlider = action.payload;
      })
      .addCase(removeSlider.rejected, (state, action) => {
        state.deletedSlider = action.payload;
      })
      .addCase(updateSlider.fulfilled, (state, action) => {
        state.updatedSlider = action.payload;
      })
      .addCase(updateSlider.rejected, (state, action) => {
        state.updatedSlider = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { getSlider } = sliderSlice.actions;
export default sliderSlice.reducer;
