import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { categoryType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";

export const getCategoryData = createAsyncThunk(
  "category/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_CATEGORY);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setCategoryData = createAsyncThunk(
  "category/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(DB_COLLECTION.DP_CATEGORY, data, "cat_id");
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCategoryData = createAsyncThunk(
  "category/update",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await updateQuery(DB_COLLECTION.DP_CATEGORY, data.cat_id, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCategoryData = createAsyncThunk(
  "category/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_CATEGORY, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface categoryState {
  categoryData: any;
  setCategoryData: any;
  updateCategoryData: any;
  deleteCategoryData: any;
}

const initialState: any = {
  categoryData: [],
  setCategoryData: [],
  updateCategoryData: [],
  deleteCategoryData: false,
} as categoryState;

export const CategorySlice = createSlice({
  name: "category",
  initialState,

  reducers: {
    setCategory: () => {},
    getCategory: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCategoryData.fulfilled,
        (state, action: PayloadAction<categoryType[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.categoryData = action.payload;
          }
        }
      )
      .addCase(getCategoryData.rejected, (state, action) => {
        state.categoryData = action.payload;
      })
      .addCase(setCategoryData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setCategoryData = action.payload;
      })
      .addCase(setCategoryData.fulfilled, (state, action) => {
        state.setCategoryData = action.payload;
      })
      .addCase(updateCategoryData.fulfilled, (state, action) => {
        state.updateCategoryData = action.payload;
      })
      .addCase(updateCategoryData.rejected, (state, action) => {
        state.updateCategoryData = action.payload;
      })
      .addCase(deleteCategoryData.fulfilled, (state, action) => {
        state.deleteCategoryData = action.payload;
      })
      .addCase(deleteCategoryData.rejected, (state, action) => {
        state.deleteCategoryData = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setCategory } = CategorySlice.actions;
export default CategorySlice.reducer;
