import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {  getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { subCategoryType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";


export const getSubCategoryData = createAsyncThunk(
  "subCategory/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_SUBCATEGORY);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setSubCategoryData = createAsyncThunk(
  "subCategory/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(DB_COLLECTION.DP_SUBCATEGORY, data, "sub_cat_id");
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSubCategoryData = createAsyncThunk(
  "subCategory/update",
  async (data: any, { rejectWithValue }) => {
  console.log('data IN SLICE:', data);
    try {
      let res = await updateQuery(DB_COLLECTION.DP_SUBCATEGORY, data.sub_cat_id ,data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubCategoryData = createAsyncThunk(
  "subCategory/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_SUBCATEGORY, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface categoryState {
  subCategoryData: any;
  setSubCategoryData: any;
  updateSubCategoryData: any;
  deleteSubCategoryData: any;
}

const initialState: any = {
  subCategoryData: [],
  setSubCategoryData: [],
  updateSubCategoryData: [],
  deleteSubCategoryData: false,
} as categoryState;

export const subCategorySlice = createSlice({
  name: "subcategory",
  initialState,

  reducers: {
    setSubCategory: () => {},
    getSubCategory: (state, { payload }) => {},
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSubCategoryData.fulfilled,
        (state, action: PayloadAction<subCategoryType[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.subCategoryData = action.payload;
          }
        }
      ) 
      .addCase(getSubCategoryData.rejected, (state, action) => {
        state.subCategoryData = action.payload;
      })
      .addCase(setSubCategoryData.fulfilled, (state, action) => {
        state.setSubCategoryData = action.payload;
      })
      .addCase(setSubCategoryData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setSubCategoryData = action.payload;
      })
      .addCase(updateSubCategoryData.fulfilled, (state, action) => {
        state.updateSubCategoryData = action.payload;
      })      
      .addCase(updateSubCategoryData.rejected, (state, action) => {
        state.updateSubCategoryData = action.payload;
      })
      .addCase(deleteSubCategoryData.fulfilled, (state, action) => {
        state.deleteSubCategoryData = action.payload;
      })      
      .addCase(deleteSubCategoryData.rejected, (state, action) => {
        state.deleteSubCategoryData = action.payload;
      })     
  },
});

// Action creators are generated for each case reducer function
export const { setSubCategory } = subCategorySlice.actions;
export default subCategorySlice.reducer;
