export const cardHeaderStyles = {
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "20px",
      paddingRight: "20px",
      height: "65px",
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginLeft: "3.4%",
      // width: "100%",
    },
    filter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "20px",
      paddingRight: "20px",
      height: "65px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginLeft: "3.4%",
    },
    dropDown: {
      width: "200px"
    },
    addUserButton: {
      fontSize: "1.05rem",
    },
  };
  export const modalStyles = {
    inputFields: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
      marginBottom: "15px",
      ".MuiFormControl-root": {
        marginBottom: "20px",
      },
    },
  };
 