import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";

export const getCouponData = createAsyncThunk(
  "coupons/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_COUPON);
      console.log("res--->", res);
      return res;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const setCouponData = createAsyncThunk(
  "coupons/set",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await setQuery(DB_COLLECTION.DP_COUPON, data, "id");
      return response;
    } catch (error: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCouponData = createAsyncThunk(
  "coupon/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      // api call here for DP_DEALS
      let response = await deleteQuery(DB_COLLECTION.DP_COUPON, data);
      return response;
    } catch (error: any) {

      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCouponData = createAsyncThunk(
  "coupon/update",
  async (data: any, { rejectWithValue }) => {
    try {
      let response = await updateQuery(
        DB_COLLECTION.DP_COUPON,
        data.id,
        data
      );
      return response;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
interface couponState {
  couponsData: any;
  setCouponData: any;
  deleteQrPaymentData: any;
  setImageDownloadUrl: any;
  updateQrPaymentData: any;
}

const initialState: any = {
  couponsData: [],
  setCouponData: [],
  setImageDownloadUrl: "",
  deleteQrPaymentData: false,
  updateQrPaymentData: false,
} as couponState;

export const CouponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    handleUploadImage(state, action) {},
    getQrPayment: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCouponData.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.couponsData = action.payload;
        }
      })
      .addCase(getCouponData.rejected, (state, action) => {
        state.couponsData = action.payload;
      })
    .addCase(setCouponData.fulfilled, (state, action) => {
      state.setCouponData = action.payload;
    })
    .addCase(setCouponData.rejected, (state, action) => {
      state.setCouponData = action.payload;
    })
    .addCase(deleteCouponData.fulfilled, (state, action) => {
      state.deleteQrPaymentData = action.payload;
    })
    .addCase(deleteCouponData.rejected, (state, action) => {
      state.deleteQrPaymentData = action.payload;
    })
    .addCase(updateCouponData.fulfilled, (state, action) => {
      state.updateQrPaymentData = action.payload;
    })
    .addCase(updateCouponData.rejected, (state, action) => {
      state.updateQrPaymentData = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { getQrPayment } = CouponSlice.actions;
export default CouponSlice.reducer;
