import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../services/firebase";
import {
  getMerchantInvoiceData,
  updateMerchantInvoiceData,
} from "../../store/reduxSlice/MerchantInvoiceSlice";
import { useDispatch } from "react-redux";
import { CONSTANT } from "../../constant";

const imageType = ["image/png", "image/jpeg", "image/jpg"];

const UploadModal = ({
  isOpen,
  handleChangeModal,
  invoiceData,
  uploadType,
}: any) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, seterrorMessage] = useState<string>("");
  const [isLoading, setisLoading] = useState(false);
  const dispatch: any = useDispatch();

  const handleFileSelect = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadImage = async () => {
    let data = invoiceData;
    seterrorMessage("");
    setisLoading(true);
    if (selectedFile !== null) {
      let imageName = Math.round(Math.random() * 1000000000);
      const storageRef = ref(
        storage,
        `/Merchant/Receipt-Qr/${imageName}`
      );
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);
      const getThumbnailUrl = await uploadImageTask(uploadTask);
      console.log("getThumbnailUrl", getThumbnailUrl);

      if (uploadType === CONSTANT.QR_UPLOAD)
        data = {
          ...data,
          paymentQRImage: getThumbnailUrl,
          isUploadReceipt: true,
        };

      if (uploadType === CONSTANT.RECEIPT_UPLOAD)
        data = {
          ...data,
          uploadReceiptDateTime: new Date().getTime(),
          paymentReceiptImage: getThumbnailUrl,
          isUploadReceipt: true,
        };

      dispatch(updateMerchantInvoiceData(data)).then(() => {
        setSelectedFile(null)
        setisLoading(false);
        handleChangeModal(null);
        dispatch(getMerchantInvoiceData());
      });
    } else {
      setisLoading(false);
      seterrorMessage("Please Upload Recipt.");
    }
  };

  const uploadImageTask = (uploadTask: any) => {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  return (
    <Dialog
      open={isOpen}
      // maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth={true}
    >
      <IconButton
        aria-label="close"
        onClick={handleChangeModal}
        style={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        id="alert-dialog-title"
        style={{
          fontSize: "1.25rem",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
          lineHeight: 1.6,
          letterSpacing: "0.0075em",
        }}
        variant="h5"
      >
        {uploadType}
      </DialogTitle>

      <DialogContent dividers>
        <Grid item xs={12} sm={12}>
          <TextField
            margin={"normal"}
            fullWidth
            name="recipt"
            placeholder="Your email address"
            type="file"
            // value={email}
            onChange={handleFileSelect}
            inputProps={{ accept: ".jpeg, .jpg, .png" }}
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={uploadImage}>Upload</Button>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadModal;
