import React, { Fragment, useState } from "react";
import {
  Modal,
  Box,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";

const ImagesModal = ({ images, isOpen, onClose }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Image Preview</DialogTitle>
      <DialogContent>
        {images?.length === 0 ? (
          <h4>No image found</h4>
        ) : (
          <img
            src={images[currentIndex]}
            alt={`Image ${currentIndex + 1}`}
            style={{ width: "100%", maxHeight: 400, marginBottom: 16 }}
          />
        )}
      </DialogContent>

      <DialogActions>
        {images.length > 1 && (
          <Fragment>
            <Button variant="contained" onClick={handlePrev}>
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              style={{ marginLeft: 8 }}
            >
              Next
            </Button>
          </Fragment>
        )}

        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImagesModal;
