import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HEADERS } from "../../services/config";
import axios from "axios";
import Loader from "./Loader";

const ImageModal = ({
  open,
  handleCloseModal,
  imageUrl,
  isActionButton,
  actionTitle,
  ActionFunction,
  isActionButton2,
  actionTitle2,
  ActionFunction2,
  isActionButtonAllow,
  isLoading
}: any) => {
  const [isOpen, setisOpen] = useState(false);
  const [rejectReason, setrejectReason] = useState("");

  const handleInputChange = (event: any) => {
    setrejectReason(event.target.value);
  };

  useEffect(() => {
    return () => {
      setisOpen(false);
    };
  }, []);

  return (
    <div>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Preview" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          {isActionButtonAllow && isActionButton && (
            <Button onClick={() => {
              handleCloseModal()
              ActionFunction()
            }} color="primary">
              {actionTitle}
            </Button>
          )}
          {isActionButtonAllow && isActionButton2 && (
            <Button onClick={() => setisOpen(true)} color="primary">
              {actionTitle2}
            </Button>
          )}
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>

        {isOpen && (
          <Dialog open={open} onClose={handleCloseModal}>
            <DialogTitle>Rejection Reason </DialogTitle>
            <TextField
                sx={{
                  margin: "20px"
                }}
                placeholder={"Enter rejection reason"}
                name={rejectReason}
                type={'text'}
                onChange={handleInputChange}
              />
            <Button onClick={() => {
              setisOpen(false)
              handleCloseModal()
              ActionFunction2(rejectReason)
            }} color="primary">
              Reject
            </Button>
            <Button onClick={() => setisOpen(false)} color="primary">
              Cancel
            </Button>
          </Dialog>
        )}
      </Dialog>
      <Loader isLoading={isLoading} />

    </div>
  );
};

export default ImageModal;
