import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import Popup from "../../Components/Popup";
import { getCategoryData } from "../../../store/reduxSlice/CategorySlice";
import {
  setSubCategoryData,
  getSubCategoryData,
  updateSubCategoryData,
  deleteSubCategoryData,
} from "../../../store/reduxSlice/SubCategorySlice";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { getSession } from "../../utilities/Auth/AuthStates";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchBar from "../../Components/SearchBar";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import DialogBox from "../../Components/Dialoag";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import { fetchBrand, setBrands } from "../../../store/reduxSlice/brandSlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../services/firebase";

type Props = {};
const SubCategory = (props: Props) => {
  const data: any = useSelector((state: any) => state);
  const rows =
    data?.subCategory && data?.subCategory?.subCategoryData.length > 0
      ? data?.subCategory?.subCategoryData
      : [];
  const { categoryData } = data.category;
  const [page, setPage] = React.useState(0);
  const [values, setValues] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [textField, setTextField] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [catData, setCatData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState("");
  const [subCatData, setSubCatData] = useState<any>([]);
  const [image, setImage] = React.useState<any>([]);
  const [prograssValue, setPrograssValue] = React.useState<any>([]);
  const [renderImageName, setRenderImageName] = React.useState<any>("");
  const { brandsData } = data?.brands;
  const { dealData } = data?.deals;
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [openModal, setOpenModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleClose = () => {
    setValues({
      ...values,
      cat_id: "",
      sub_cat_name: "",
      sub_cat_description: "",
    });
    setOpen(false);
    setIsEditMode(false);
    setDisableButton(false);
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    getSession();
    dispatch(getCategoryData());
    dispatch(getSubCategoryData());
    // dispatch(fetchDeals());
    // dispatch(fetchBrand());
  }, []);
  useEffect(() => {
      setSubCatData(rows);
      setCatData(categoryData);
  }, []);
  const columns = [
    {
      id: "sub_cat_icon",
      label: "Icon",
      minWidth: 100,
      type: "file",
    },
    {
      id: "sub_cat_name",
      label: "Name",
      minWidth: 100,
    },
    {
      id: "sub_cat_description",
      label: "Description",
      minWidth: 170,
    },
    {
      id: "cat_name",
      label: "Category Name",
      minWidth: 170,
    },
    {
      id: "sub_cat_location",
      label: "Location",
      placeholder: "",
      select: false,
    },
    {
      id: "created_date",
      label: "Date",
      minWidth: 170,
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "cat_id") {
      let filteredData = categoryData.filter(
        (item: any) => item?.cat_id === value
      );
      let locationArray = filteredData[0].location
        ? filteredData[0].location
        : [];
      setValues({
        ...values,
        location: locationArray,
        [name]: value,
      });
      setTextField(locationArray);
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }

    setLoading(false);
    setDisableButton(false);
  };

  const handleImage = (event: any) => {
    console.log(" event:", event.target.files);
    setImage(event.target.files);
    setDisableButton(false);
  };
  const inputFieldValues = [
    // {
    //   name: "location",
    //   // label: "location",
    //   id: "location",
    //   placeholder: "Location",
    //   type: "text",
    //   disabled: true,
    // },
    {
      name: "sub_cat_name",
      label: "Sub Category Name",
      id: "sub_cat_name",
      placeholder: "Category Name",
      type: "text",
    },
    {
      name: "sub_cat_description",
      label: "Sub Category Description",
      id: "sub_cat_description",
      placeholder: "Category Description",
      type: "text",
    },
    {
      id: "sub_cat_icon",
      name: "sub_cat_icon",
      label: "",
      type: "file",
    },
  ];

  const getContent = () => (
    <Box sx={modalStyles.inputFields}>
      <div>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Location"
          name={"location"}
          value={textField}
          disabled
          onChange={(event: any) => handleChange(event)}
        />
      </div>
      <div style={{ display: "flex" }}>
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Category Id *</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="cat_id"
            label="Category Id*"
            defaultValue={values["cat_id"]}
            variant="outlined"
            onBlur={handleChange}
            onChange={handleChange}
          >
            {categoryData && categoryData.length > 0 ? (
              categoryData.map((eachCategory: any) => {
                return (
                  <MenuItem
                    // key={eachCategory.cat_id}
                    // value={`${eachCategory.cat_id}`}
                    key={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                    value={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                  >
                    {eachCategory.cat_name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem> </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      {inputFieldValues.map((eachField: any, index: any) => {
        return (
          <TextField
            key={index}
            placeholder={eachField.placeholder}
            defaultValue={eachField.type === "file" ? "" : values[eachField.id]}
            name={eachField.id}
            label={eachField.label}
            // value={values[eachField.id]}
            type={eachField.type}
            required
            disabled={eachField.disabled}
            multiline={eachField.id === "sub_cat_description" ? true : false}
            onChange={(event: any) => {
              eachField.type === "file"
                ? handleImage(event)
                : handleChange(event);
            }}
          />
        );
      })}
    </Box>
  );

  const handleFormSubmit = async (file: any) => {
    console.log("clicked", values);
    if (
      values.cat_id !== undefined &&
      values.cat_id !== " " &&
      values.sub_cat_name !== " " &&
      values.sub_cat_name !== undefined &&
      values.sub_cat_description !== " " &&
      values.sub_cat_description !== undefined &&
      image.length > 0
    ) {
      setLoading(true);
      let imageName = Math.round(Math.random() * 1000000000);
      let brandName =
        values && values.sub_cat_name ? values.sub_cat_name : "custom";
      const storageRef = ref(
        storage,
        `/SubCategory/${brandName}/${imageName}-${brandName}-sub_cat_icon`
      );
      const uploadTask = uploadBytesResumable(storageRef, file[0]);

      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPrograssValue(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            values["sub_cat_icon"] = downloadURL;
            let setDataRequest = {
              ...values,
              // cat_id: values?.cat_id,
              // sub_cat_id: values?.sub_cat_id,
              // sub_cat_name: values?.sub_cat_name,
              // sub_cat_description: values?.sub_cat_description,
              // sub_cat_icon: values?.sub_cat_icon,
            };
            if (setDataRequest) {
              dispatch(setSubCategoryData(setDataRequest));
              handleClose();
              dispatch(getSubCategoryData());
              setLoading(false);
              setValues({
                ...values,
                cat_id: "",
                sub_cat_id: "",
                sub_cat_name: "",
                sub_cat_description: "",
                sub_cat_icon: "",
              });
            }
          });
        }
      );
      // let setDataRequest = {
      //   cat_id: values?.cat_id,
      //   sub_cat_id: values?.sub_cat_id,
      //   sub_cat_name: values?.sub_cat_name,
      //   sub_cat_description: values?.sub_cat_description,
      //   sub_cat_icon: values?.sub_cat_icon,
      // };
      // console.log("setDataRequest", setDataRequest);
      // if (setDataRequest) {
      //   dispatch(setSubCategoryData(setDataRequest));
      //   handleClose();
      //   dispatch(getSubCategoryData());
      //   setLoading(false);
      //   setValues({
      //     ...values,
      //     cat_id: "",
      //     sub_cat_id: "",
      //     sub_cat_name: "",
      //     sub_cat_description: "",
      //   });
      // }
    } else {
      setDisableButton(true);
    }
  };
  const handleFormSubmitAfterEdit = async (file: any) => {
    if (
      values.cat_id &&
      values.cat_id !== undefined &&
      values.cat_id !== "undefined" &&
      values.sub_cat_id &&
      values.sub_cat_id !== undefined &&
      values.sub_cat_id !== "undefined" &&
      values.sub_cat_description &&
      values.sub_cat_description !== undefined &&
      values.sub_cat_description !== "undefined"
    
    ) {
      setLoading(true);

      let setDataRequest = {
        cat_id: values?.cat_id,
        sub_cat_id: values?.sub_cat_id,
        sub_cat_name: values?.sub_cat_name,
        sub_cat_description: values?.sub_cat_description,
        sub_cat_icon: values?.sub_cat_icon,
        location: values?.location,
      };
      console.log("setDataRequest", setDataRequest);
      if (image.length > 0) {
        let allBrandsData: any = {};
        let imageName = Math.round(Math.random() * 1000000000);
        let brandName =
          values && values.sub_cat_name ? values.sub_cat_name : "custom";
        const storageRef = ref(
          storage,
          `/SubCategory/${brandName}/${imageName}-${brandName}-sub_cat_icon`
        );
        const uploadTask = uploadBytesResumable(storageRef, file[0]);

        uploadTask.on(
          "state_changed",
          (snapshot: any) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setPrograssValue(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setDataRequest["sub_cat_icon"] = downloadURL;

              allBrandsData = {
                ...setDataRequest,
              };
              if (allBrandsData) {
                dispatch(updateSubCategoryData(allBrandsData));
                handleClose();
                dispatch(getSubCategoryData());
                setLoading(false);
                setValues({
                  ...values,
                  cat_id: "",
                  sub_cat_id: "",
                  sub_cat_name: "",
                  sub_cat_description: "",
                  sub_cat_icon: "",
                });
              }
              setIsEditMode(false);
              setLoading(false);
            });
          }
        );
      } else {
        if (setDataRequest) {
          dispatch(updateSubCategoryData(setDataRequest));
          handleClose();
          dispatch(getSubCategoryData());
          setLoading(false);
          setValues({
            ...values,
            cat_id: "",
            sub_cat_id: "",
            sub_cat_name: "",
            sub_cat_description: "",
            sub_cat_icon: "",
          });
        }
        setIsEditMode(false);
        setLoading(false);
      }
    } else {
      setDisableButton(true);
    }
  };
  async function uploadImageTask(uploadTask: any) {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  }
  // const handleFormSubmitAfterEdit = async (file: any) => {
  //   console.log("inside update", values);
  //   if (image) {
  //     let setDataRequest = {
  //       ...values,
  //     };

  //     if (image) {
  //       setLoading(true);

  //       if (
  //         image.hasOwnProperty("sub_cat_icon") &&
  //         typeof image.sub_cat_icon === "object"
  //       ) {
  //         for (const key in image) {
  //           const element = image[key];
  //           if (key === "sub_cat_icon") {
  //             let imageName = Math.round(Math.random() * 1000000000);
  //             let brandName =
  //               values && values.sub_cat_icon ? values.sub_cat_icon : "custom";
  //             const storageRef = ref(
  //               storage,
  //               `/SubCategory/${brandName}/${imageName}-${brandName}-sub_cat_icon`
  //             );
  //             const uploadTask = uploadBytesResumable(storageRef, element[0]);
  //             const getThumbnailUrl = await uploadImageTask(uploadTask);
  //             values["sub_cat_icon"] = getThumbnailUrl;
  //             setDataRequest = {
  //               ...values,
  //             };
  //           }
  //         }
  //       }  else if (
  //         image.hasOwnProperty("sub_cat_icon") &&
  //         typeof image.sub_cat_icon === "object"
  //       ) {
  //         let imageName = Math.round(Math.random() * 1000000000);
  //         let brandName = values && values.sub_cat_icon ? values.sub_cat_icon : "custom";
  //         const storageRef = ref(
  //           storage,
  //           `/SubCategory/${brandName}/${imageName}-${brandName}-sub_cat_icon`
  //         );
  //         const uploadTask = uploadBytesResumable(
  //           storageRef,
  //           image.sub_cat_icon[0]
  //         );
  //         const getThumbnailUrl = await uploadImageTask(uploadTask);
  //         values["sub_cat_icon"] = getThumbnailUrl;
  //         setDataRequest = {
  //           ...values,
  //         };
  //       }

  //       if (setDataRequest.sub_cat_icon) {
  //         setDataRequest = {
  //           ...values,
  //         };
  //         await dispatch(updateSubCategoryData(setDataRequest));
  //         handleClose();
  //         await dispatch(getSubCategoryData());
  //         setLoading(false);
  //         setIsEditMode(false);
  //       }
  //     }
  //   } else {
  //     setDisableButton(true);
  //     setIsEditMode(false);
  //   }
  // };

  const editCategory = (
    cat_id: any,
    sub_cat_id: any,
    name: any,
    description: any,
    sub_cat_icon: any,
    location: any,
  ) => {
    setValues({
      ...values,
      cat_id: cat_id,
      sub_cat_id: sub_cat_id,
      sub_cat_name: name,
      sub_cat_description: description,
      sub_cat_icon: sub_cat_icon,
      location: location,
    });

    console.log("values=====:", sub_cat_icon, values);
    setTextField(location ? location : [])
    setIsEditMode(true);
    handleOpen();
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(deleteSubCategoryData(itemToDelete[0]));
    await dispatch(getSubCategoryData());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };

  const handleChangeSearch = (e: any) => {
    let filteredData: any = [];
    let filterCat: any = [];
    e.preventDefault();
    if (e.target.value?.length > 0) {
      filteredData = rows.filter((row: any) => {
        return (
          row.sub_cat_name
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row.sub_cat_description
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase())
        );
      });
    // filter by location
    rows.filter((row: any) => {
      if (
        row.location.some((x: any) =>
          x.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      ) {
        filteredData?.push(row);
      }
    });

      if (filteredData.length === 0) {
        filterCat = categoryData.filter((row: any) => {
          return row.cat_name
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase());
        });
        filterCat.map((item: any) => {
          let data = rows.filter((item2: any) => item2.cat_id === item?.cat_id);
          filteredData.push(data[0]);
        });
      }
      setSearchInput(e.target.value);
      setSubCatData(filteredData);
    } else {
      setSubCatData(rows);
    }
  };
  const handleDelete = (item: any) => {
    let checkDealExists = dealData.filter((eachDeal: any) => {
      if (eachDeal.sub_cat_id === item.sub_cat_id) {
        return eachDeal;
      }
    });
    let checkBrandExists = brandsData.filter((eachBrand: any) => {
      if (eachBrand.sub_cat_id === item.sub_cat_id) {
        return eachBrand;
      }
    });
    if (checkDealExists.length === 0 && checkBrandExists.length === 0) {
      setOpenAlert(true);
      setItemToDelete([item]);
    } else {
      setOpenModal(true);
    }
  };
  const dialogContent =
    "Sorry you are not able to delete this.Please delete brand and deal first";

  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Sub Category</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search by Category Name or Category Description"
          searchBarWidth="720px"
        />
        <Box>
          <div>
            <Button variant="contained" onClick={handleOpen}>
              <AddIcon />
              Add Sub Category
            </Button>
            <IconButton>
              <RefreshIcon />
            </IconButton>

            <div>
              <Popup
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Edit Sub Category" : "Add Sub Category"}
                buttonText={
                  isEditMode ? "Update Sub Category" : "Submit Sub Category"
                }
                content={getContent()}
                values={values}
                handleFormSubmit={() => handleFormSubmit(image)}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() =>
                  handleFormSubmitAfterEdit(image)
                }
              />
            </div>
          </div>
        </Box>
      </Box>
      {/*  Table Start */}
      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subCatData && subCatData.length > 0 ? (
                    subCatData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell align={"center"}>
                              <img
                                src={row.sub_cat_icon}
                                style={{ width: "70px", height: "65px" }}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.sub_cat_name}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.sub_cat_description}
                            </TableCell>
                            <TableCell align={"center"}>
                              {categoryData &&
                                getObjectById(
                                  categoryData,
                                  row.cat_id,
                                  "cat_name"
                                )}
                            </TableCell>
                            <TableCell
                              style={{ padding: "5px" }}
                              
                            >
                              <ul>
                                {row?.location && row?.location.map((item: any) => {
                                  if (item) {
                                    return <li>{item}</li>;
                                  } else {
                                    return "No Location Available";
                                  }
                                })}
                              </ul>
                            </TableCell>
                            <TableCell align={"center"}>
                              {setDateFormate(row?.created_Date)}
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() =>
                                  editCategory(
                                    row.cat_id,
                                    row.sub_cat_id,
                                    row.sub_cat_name,
                                    row.sub_cat_description,
                                    row.sub_cat_icon,
                                    row.location
                                  )
                                }
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Sub Category Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
        <Loader isLoading={isLoading} />

        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>
      </div>
    </>
  );
};

export default SubCategory;
