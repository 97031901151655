export const ROUTES: any = {
  HOME: "/",
  SLIDER: "/addSlider",
  LOGIN: "/login",
  CATEGORIES: "/categories",
  SUBCATEGORIES: "/subcategories",
  BRANDS: "/brands",
  DEALS: "/deals",
  HOME_CARDS: "/addHomeCards",
  VIDEOS: "/videos",
  COMPANIES: "/companies",
  MERCHANT: "/merchant",
  USER_REFFERAL: "/userrefferal",
  QR_PAYMENTS: "/qrpayments",
  COUPONS: "/Coupons",
  MERCHANT_INVOICES: "/invoices"
};
