import React from "react";
import { Popover, Typography } from "@mui/material";
interface IpopOver {
  PopoverContent: any;
  open: boolean;
  anchorEl?: any;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  onClose: () => void;
}
const PopoverMui = (props: IpopOver) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const styles = {
    typography: {
      p: 2,
      width: 400,
    //   fontFamily: "Poppins",
    },
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div style={{ width: 300 }}>
      <Popover
        elevation={1}
        id={id}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={styles.typography}>{props.PopoverContent}</Typography>
      </Popover>
    </div>
  );
};

export default PopoverMui;
