import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAllQuery } from "../../UI/utilities/queries/getQuery";
import { DB_COLLECTION } from "../../constant/dbcollections";
// import { CompaniesType } from "../../types";
import { setQuery } from "../../UI/utilities/queries/setQuery";
import { deleteQuery } from "../../UI/utilities/queries/deleteQuery";
import { updateQuery } from "../../UI/utilities/queries/updateQuery";
import { deleteObject, ref } from "@firebase/storage";
import { storage } from "../../services/firebase";
import { toast } from "react-toastify";

export const getrefferalData = createAsyncThunk(
  "refferalCode/get",
  async (_, { rejectWithValue }) => {
    try {
      let res = await getAllQuery(DB_COLLECTION.DP_REFERRAL_CODE);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const setRefferalData = createAsyncThunk(
  "refferalCode/set",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await setQuery(
        DB_COLLECTION.DP_REFERRAL_CODE,
        data,
        "refferal_id"
      );
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRefferalData = createAsyncThunk(
  "refferalCode/update",
  async (data: any, { rejectWithValue }) => {
    console.log("data IN SLICE:", data);
    try {
      let res = await updateQuery(
        DB_COLLECTION.DP_REFERRAL_CODE,
        data.company_id,
        data
      );
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRefferalData = createAsyncThunk(
  "refferalCode/delete",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await deleteQuery(DB_COLLECTION.DP_REFERRAL_CODE, data);
      return res;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

interface IRefferal {
  refferalData: any;
  setRefferalData: any;
  updateRefferalData: any;
  deleteRefferalData: any;
}

const initialState: any = {
  refferalData: [],
  setRefferalData: [],
  updateRefferalData: [],
  deleteRefferalData: false,
} as IRefferal;

export const refferalCodeSlice = createSlice({
  name: "refferalCode",
  initialState,

  reducers: {
    setrefferalCOde: () => {},
    getrefferalCOde: (state, { payload }) => {},
    // is update data need to be here??
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getrefferalData.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          // action is inferred correctly here if using TS
          if (action.payload.length > 0) {
            state.refferalData = action.payload;
          }
        }
      )
      .addCase(getrefferalData.rejected, (state, action) => {
        state.refferalData = action.payload;
      })
      .addCase(setRefferalData.fulfilled, (state, action) => {
        state.setRefferalData = action.payload;
      })
      .addCase(setRefferalData.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        state.setRefferalData = action.payload;
      })
      .addCase(updateRefferalData.fulfilled, (state, action) => {
        state.updateRefferalData = action.payload;
      })
      .addCase(updateRefferalData.rejected, (state, action) => {
        state.updateRefferalData = action.payload;
      })
      .addCase(deleteRefferalData.fulfilled, (state, action) => {
        state.deleteRefferalData = action.payload;
      })
      .addCase(deleteRefferalData.rejected, (state, action) => {
        state.deleteRefferalData = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setrefferalCOde } = refferalCodeSlice.actions;
export default refferalCodeSlice.reducer;
