import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";

export default function HomeCardsList(props: any) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          color: "gray",
        //   border: "1px solid black",
        }}
      >
        <CancelIcon onClick={()=> props.onSelect(props.id)} style={{cursor: "pointer"}} />
        <li
          style={{ marginLeft: 5, marginBottom: 5 , textDecoration: "none"}}
        //   key={index}
        //   id={index}
        //   onSelect={deleteItems}
        >
          {props.list}
        </li>
      </div>
    </>
  );
}
