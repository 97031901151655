import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ToastContainer, toast, Theme } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../Components/Popup";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../services/firebase";
import { Delete, Edit, ImagesearchRoller } from "@mui/icons-material";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { getSession } from "../../utilities/Auth/AuthStates";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import SearchBar from "../../Components/SearchBar";
import AddCommonButton from "../../Components/AddCommonButton";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import { AnyAction } from "@reduxjs/toolkit";
import DialogBox from "../../Components/Dialoag";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import { fetchBrand } from "../../../store/reduxSlice/brandSlice";
import { getSubCategoryData } from "../../../store/reduxSlice/SubCategorySlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import { InputLabel } from "@mui/material";
import {
  setSlider,
  fetchSlider,
  removeSlider,
  updateSlider,
} from "../../../store/reduxSlice/sliderSlice";
import { Flags } from "../../../data/Flags";
const defaultInputValues = {
  cat_name: "",
  cat_description: "",
};
const Slider = () => {
  const data: any = useSelector((state: any) => state);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [values, setValues] = useState<any>([]);
  const [retrieveData, setRetrieveData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sliderData, setSliderData] = useState([]);
  const [image, setImage] = React.useState<any>([]);
  const [location, setLocation] = React.useState<string[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const rows = data?.slider?.sliderData;
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [openModal, setOpenModal] = React.useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleClose = () => {
    setValues({
      slider_id: "",
      main_title: "",
      slider_description: "",
      slider_link: "",
      title: "",
      slider_thumbnail: "",
      slider_image: "",
      location: [],
    });
    setLocation([]);
    setImage({ slider_thumbnail: "", slider_image: "" });
    setOpen(false);
    setIsEditMode(false);
    setDisableButton(false);
  };
  const dispatch: any = useDispatch();
  const theme: any = useTheme();
  useEffect(() => {
    dispatch(fetchSlider());
    // setRetrieveData(data);
  }, []);

  useEffect(() => {
    getSession();
    sort_sliders(rows);
  }, [rows]);
  const columns = [
    {
      id: "main_title",
      label: "Main Title",
      minWidth: 100,
    },
    {
      id: "title",
      label: "Title",
      minWidth: 170,
    },
    {
      id: "slider_description",
      label: "Description",
      minWidth: 170,
    },
    {
      id: "slider_display_order",
      name: "slider_display_order",
      label: "Order",
      minWidth: 170,
    },
    {
      id: "slider_link",
      label: "Offer Link",
      minWidth: 170,
    },
    {
      id: "slider_location",
      label: "Location",
      placeholder: "",
      select: false,
    },
    {
      id: "slider_thumbnail",
      label: "Thumbnail",
      minWidth: 170,
    },
    {
      id: "slider_image",
      label: "Slider Image",
      minWidth: 170,
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setLoading(false);
    setDisableButton(false);
  };
  const inputFieldValues = [
    {
      id: "main_title",
      name: "main_name",
      label: "Main Title",
      placeholder: "Enter main title",
      type: "text",
    },
    {
      id: "title",
      name: "title_name",
      label: "Title",
      placeholder: "Enter title",
      type: "text",
    },
    {
      id: "slider_description",
      name: "slider_description",
      label: "Description",
      placeholder: "Enter Description",
      type: "text",
    },
    {
      id: "slider_link",
      label: "Offer Link *",
      minWidth: 170,
    },
    {
      id: "slider_display_order",
      name: "slider_display_order",
      label: "Enter Display order",
      placeholder: "",
      type: "number",
    },
    {
      id: "slider_thumbnail",
      name: "slider_thumbnail",
      label: "Thumbnail (100 * 100)-JPEG *",
      placeholder: "",
      type: "file",
    },
    {
      id: "slider_image",
      name: "slider_image",
      label: "Slider Image (1020 * 380)-JPEG *",
      placeholder: "",
      type: "file",
    },
  ];
  const dialogContent =
    "Sorry you are not able to delete this.Please delete  subcatefory brand and deal first";
  const handleImage = (event: any) => {
    const { name, value, files } = event.target;

    if (files[0].name.match(/\.(jpg|jpeg|png)$/i) != null) {
      setImage({
        ...image,
        [name]: files,
      });
      setDisableButton(false);
    } else {
      toast.error(`Select valid image extension!`, {
        className: "toast-message",
      });
    }
  };
  const handleMultiSelect = (event: SelectChangeEvent<typeof location>) => {
    const {
      target: { value },
    } = event;
    let array = typeof value === "string" ? value.split(",") : value;
    let editValues = {
      ...values,
      location: array,
    };
    setValues(editValues);
    setLocation(typeof value === "string" ? value.split(",") : value);
    // if (values?.slider_location) {
    //   editValues = {
    //     ...values,
    //     slider_location: [
    //       ...values.slider_location,
    //       {
    //         flag: value[value.length - 1].split(" ")[0],
    //         country:
    //           value[value.length - 1].split(" ")[1] +
    //           " " +
    //           (value[value.length - 1].split(" ")[2]
    //             ? value[value.length - 1].split(" ")[2]
    //             : ""),
    //       },
    //     ],
    //   };
    // } else {
    //   editValues = {
    //     ...values,
    //     slider_location: [
    //       {
    //         flag: value[value.length - 1].split(" ")[0],
    //         country:
    //           value[value.length - 1].split(" ")[1] +
    //           " " +
    //           (value[value.length - 1].split(" ")[2]
    //             ? value[value.length - 1].split(" ")[2]
    //             : ""),
    //       },
    //     ],
    //   };
    // }
  };
  function getStyles(name: string, location: readonly string[], theme: Theme) {
    return {
      fontWeight: location.indexOf(name) === -1 ? "normal" : "bold",
    };
  }
  const getContent = () => (
    <Box sx={modalStyles.inputFields}>
      {inputFieldValues.map((eachField: any) => (
        <>
          <InputLabel style={{ margin: "5px 5px" }}>
            {eachField.label}
          </InputLabel>
          <TextField
            defaultValue={eachField.type === "file" ? "" : values[eachField.id]}
            placeholder={eachField.placeholder}
            name={eachField.id}
            type={eachField.type}
            multiline={eachField.id === "slider_description" ? true : false}
            onChange={(event: any) => {
              eachField.type === "file"
                ? handleImage(event)
                : handleChange(event);
            }}
          />
        </>
      ))}
      <div>
        {/* multi select  */}
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Region</InputLabel>
          <Select
            fullWidth={true}
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={location} //
            onChange={handleMultiSelect}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {Flags.map((item: any) => (
              <MenuItem
                key={item.name}
                value={item.name}
                style={getStyles(item.name, location, theme)}
              >
                {<div style={{}}>{item.name}</div>}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );

  // async function UploadMultiplePhotos(docRef: any) {
  //   var photos = [];
  //   for (var i = 0; i < files.value.length; i++) {
  //     // files.values contains all the files objects
  //     const file = files.value[i];
  //     refStorageFunction(storage, "cities/" + docRef.id + "/" + file.name);
  //     const snapshot = await uploadBytes(storageRef, file);
  //     const downloadURL = await getDownloadURL(snapshot.ref);
  //     photos.push(downloadURL);
  //   }
  //   return photos;
  // }
  async function uploadImageTask(uploadTask: any) {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  }

  // async function uploadImg() {
  //   return new Promise((resolve, reject) => {
  //     console.log("Uploading image ...");
  //     const storageRef = firebase.storage().ref();
  //     const uploadTask = storageRef.child("user-uploads/images/" + this.file.name).put(this.name);

  //     uploadTask.on(
  //       firebase.storage.TaskEvent.STATE_CHANGED,
  //       (snapshot) => {
  //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         console.log("Upload is " + progress + "% done");
  //       },
  //       (error) => {
  //         console.log(error);
  //         reject(error);
  //       },
  //       async () => {
  //         const imgURL = await uploadTask.snapshot.ref.getDownloadURL();
  //         console.log("uploaded image: " + imgURL);
  //         this.newMarker.imgURL = imgURL;
  //         resolve();
  //       }
  //     );
  //   });
  // },
  const handleFormSubmit = async (file: any) => {
    if (
      image &&
      image.slider_thumbnail != undefined &&
      image.slider_thumbnail != "" &&
      image.slider_thumbnail != "undefined" &&
      image.slider_image != undefined &&
      image.slider_image != "" &&
      image.slider_image != "undefined" &&
      values.slider_link.length > 0 &&
      values.slider_display_order &&
      values.slider_display_order !== undefined
    ) {
      setLoading(true);
      let allBrandsData: any = {};
      if (image) {
        for (const key in image) {
          const element = image[key];
          if (key === "slider_thumbnail") {
            let imageName = Math.round(Math.random() * 1000000000);
            const storageRef = ref(
              storage,
              `/Slider/slider_thumbnail/${imageName}`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getThumbnailUrl = await uploadImageTask(uploadTask);
            values["slider_thumbnail"] = getThumbnailUrl;
            allBrandsData = {
              ...values,
            };
          } else if (key === "slider_image") {
            let imageName = Math.round(Math.random() * 1000000000);
            const storageRef = ref(
              storage,
              `/Slider/slider_image/${imageName}`
            );
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getSliderUrl = await uploadImageTask(uploadTask);
            values["slider_image"] = getSliderUrl;
            allBrandsData = {
              ...values,
            };
          }
        }

        if (allBrandsData.slider_image && allBrandsData.slider_thumbnail) {
          dispatch(setSlider(allBrandsData));
          handleClose();
          dispatch(fetchSlider());
          setLoading(false);
        }
      }
    } else {
      setDisableButton(true);
    }
  };

  const handleFormSubmitAfterEdit = async (file: any) => {
    if (image && values.slider_link.length > 0) {
      let allSliderData = {
        ...values,
      };
      if (image) {
        setLoading(true);

        if (
          image.hasOwnProperty("slider_thumbnail") &&
          image.hasOwnProperty("slider_image") &&
          typeof image.slider_image === "object" &&
          typeof image.slider_thumbnail === "object"
        ) {
          for (const key in image) {
            const element = image[key];
            if (key === "slider_thumbnail") {
              let imageName = Math.round(Math.random() * 1000000000);
              const storageRef = ref(
                storage,
                `/Slider/slider_thumbnail/${imageName}`
              );
              const uploadTask = uploadBytesResumable(storageRef, element[0]);
              const getThumbnailUrl = await uploadImageTask(uploadTask);
              values["slider_thumbnail"] = getThumbnailUrl;
              allSliderData = {
                ...values,
              };
            } else if (key === "slider_image") {
              let imageName = Math.round(Math.random() * 1000000000);
              const storageRef = ref(
                storage,
                `/Slider/slider_image/${imageName}`
              );
              const uploadTask = uploadBytesResumable(storageRef, element[0]);
              const getSliderUrl = await uploadImageTask(uploadTask);
              values["slider_image"] = getSliderUrl;
              allSliderData = {
                ...values,
              };
            }
          }
        } else if (
          image.hasOwnProperty("slider_image") &&
          typeof image.slider_image === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          const storageRef = ref(storage, `/Slider/slider_image/${imageName}`);
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.slider_image[0]
          );
          const getSliderUrl = await uploadImageTask(uploadTask);
          values["slider_image"] = getSliderUrl;
          allSliderData = {
            ...values,
          };
        } else if (
          image.hasOwnProperty("slider_thumbnail") &&
          typeof image.slider_thumbnail === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          const storageRef = ref(
            storage,
            `/Slider/slider_thumbnail/${imageName}`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.slider_thumbnail[0]
          );
          const getThumbnailUrl = await uploadImageTask(uploadTask);
          values["slider_thumbnail"] = getThumbnailUrl;
          allSliderData = {
            ...values,
          };
        }

        if (allSliderData.slider_image && allSliderData.slider_thumbnail) {
          await dispatch(updateSlider(allSliderData));
          handleClose();
          await dispatch(fetchSlider());
          setLoading(false);
          setIsEditMode(false);
        }
      }
    } else {
      setDisableButton(true);
      setIsEditMode(false);
    }
  };

  const handleEditSlider = (data: any) => {
    setIsEditMode(true);
    setValues({
      ...values,
      main_title: data.main_title || "",
      slider_description: data.slider_description || "",
      slider_id: data.slider_id || "",
      slider_image: data.slider_image || "",
      slider_link: data.slider_link || "",
      slider_thumbnail: data.slider_thumbnail || "",
      title: data.title || "",
      slider_location: data.slider_location || "",
      location: data.location,
      slider_display_order: data?.slider_display_order || "",
    });
    setLocation(data.location ? data.location : []);
    setImage({
      slider_image: data.slider_image,
      slider_thumbnail: data.slider_thumbnail,
    });
    handleOpen();
  };

  const handleDelete = (item: any) => {
    setOpenAlert(true);
    setItemToDelete([item]);
  };
  const handleChangeSearch = (e: AnyAction) => {
    let filteredData: any = [];
    e.preventDefault();
    if (e.target.value?.length > 0) {
      filteredData = rows.filter((row: any) => {
        return (
          row.main_title?.toLowerCase().match(e.target.value?.toLowerCase()) ||
          row.slider_description
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase()) ||
          row.slider_link?.toLowerCase()?.match(e.target.value.toLowerCase()) ||
          row.title?.toLowerCase()?.match(e.target.value.toLowerCase())
        );
      });
      setSearchInput(e.target.value);
      setSliderData(filteredData);
    } else {
      setSliderData(rows);
    }
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(removeSlider(itemToDelete[0]));
    await dispatch(fetchSlider());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };
  const sort_sliders = (data: any) => {
    console.log("sort_slidersdata :", data);

    try {
      let sortedData: any = [...data];
      // const sortedData = JSON.parse(JSON.stringify(data));
      sortedData = data.filter((item: any) => item.slider_display_order);
      let notSorted: any = data.filter(
        (item: any) => !item.slider_display_order
      );
      sortedData.sort(
        (a: any, b: any) =>
          Number(a.slider_display_order) - Number(b.slider_display_order)
      );
      console.log("sortedData:", sortedData);
      let array: any = [...sortedData, ...notSorted];
      setSliderData(array);
    } catch (e: any) {
      console.log("error in console for sorting:", e);
    }
  };

  return (
    <>
      {console.log("sliderData====:", sliderData)}
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Slider</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search by Category Name or Category Description"
          searchBarWidth="720px"
        />
        <Box>
          <div>
            {/* import add buton */}
            {/* <AddCommonButton
                onClick={handleOpen}
                size="large"
                sx={cardHeaderStyles.addUserButton}
                title={"Add Category"}
              /> */}

            <Button variant="contained" onClick={handleOpen}>
              <AddIcon />
              Add Slider
            </Button>
            <IconButton>
              <RefreshIcon />
            </IconButton>
            <div>
              {/* add/edit Modal */}
              <Popup
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Edit Slider" : "Add Slider"}
                buttonText={isEditMode ? "Update Slider" : "Submit Slider"}
                content={getContent()}
                values={values}
                handleFormSubmit={() => handleFormSubmit(image)}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() =>
                  handleFormSubmitAfterEdit(image)
                }
              />
            </div>
          </div>
        </Box>
      </Box>

      {/*Table Start  */}

      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sliderData && sliderData?.length > 0 ? (
                    sliderData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell key={"main_title"} align={"center"}>
                              {row.main_title}
                            </TableCell>
                            <TableCell key={"title"} align={"center"}>
                              {row.title}
                            </TableCell>
                            <TableCell
                              key={"slider_description"}
                              align={"center"}
                            >
                              {row.slider_description}
                            </TableCell>
                            <TableCell
                              key={"slider_display_order"}
                              align={"center"}
                            >
                              {row?.slider_display_order}
                            </TableCell>
                            <Tooltip title={row.slider_link}>
                              <TableCell key={"slider_link"} align={"center"}>
                                <a href={row.slider_link} target="_blank">
                                  {row.slider_link.length > 30
                                    ? row.slider_link.substring(0, 28 - 3) +
                                      "..."
                                    : row.slider_link}
                                </a>
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              style={{ padding: "9px", width: 180 }}
                              align={"left"}
                            >
                              {" "}
                              <ul>
                                {row?.location && row?.location.length > 0
                                  ? row?.location.map((item: any) => {
                                      return <li>{item.country || item}</li>;
                                    })
                                  : "No Location Available"}
                              </ul>
                            </TableCell>
                            <TableCell
                              align={"center"}
                              key={"slider_thumbnail"}
                              style={{ padding: "5px" }}
                            >
                              <img
                                src={row.slider_thumbnail}
                                style={{ width: "20", height: "30px" }}
                              ></img>
                            </TableCell>

                            <TableCell
                              align={"center"}
                              key={"slider_image"}
                              style={{ padding: "5px" }}
                            >
                              <img
                                src={row.slider_image}
                                style={{ width: "90px" }}
                              ></img>
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleEditSlider(row)}
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                        // border: "1px solid black"
                      }}
                    >
                      No Slider Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <ToastContainer theme="colored" />
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
        <Loader isLoading={isLoading} />
        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>
      </div>
    </>
  );
};

export default Slider;
