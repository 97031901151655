import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteUserRefferalData,
  getUserRefferalData,
  setUserRefferalData,
} from "../../../store/reduxSlice/UserRefferalSlice";
import Header from "../../Components/Header";
import { getSession } from "../../utilities/Auth/AuthStates";
import AlertDialog from "../../Components/ConfirmDelete";
import SearchBar from "../../Components/SearchBar";
import { cardHeaderStyles } from "../../Components/Style";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const RefferalUsers = () => {
  const data: any = useSelector((state: any) => state);
  const [searchInput, setSearchInput] = React.useState("");
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const dispatch: any = useDispatch();
  const [isLoading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterUserType, setfilterUserType] = useState<string>("1");
  const [refferalUserData, setRefferalUserData] = React.useState([]);

  const rows =
    data?.refferalUser && data?.refferalUser?.userRefferalData.length > 0
      ? data?.refferalUser?.userRefferalData
      : [];

  useEffect(() => {
    rows.length > 0 && handleFilteredData(rows)
    // sort_data(rows);
  }, [rows, filterUserType]);
  useEffect(() => {
    getSession();
    dispatch(getUserRefferalData());
  }, [dispatch]);

  const handleFilteredData = async (data: any) => {
    //! Active User Filter logic
    let activeFlag: boolean = filterUserType === "1" ? false : true;
    let filteredData = data.filter((item: any) => item.isArchive === activeFlag)
    //! end logic
    await sort_data(filteredData)
    // setRefferalUserData(sortByDate);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDelete = (item: any) => {
    setOpenAlert(true);
    setItemToDelete([item]);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(deleteUserRefferalData(itemToDelete[0]));
    await dispatch(getUserRefferalData());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };
  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 100,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 100,
    },
    // {
    //   id: "password",
    //   label: "Password",
    //   minWidth: 100,
    // },
    {
      id: "phoneNumber",
      label: "Phone Number",
      minWidth: 100,
    },
    {
      id: "companyName",
      label: "Company Name",
      minWidth: 150,
    },
    {
      id: "createdDate",
      label: "Register Date",
      minWidth: 150,
    },
    {
      id: "refferalCode",
      label: "Referral Code",
      minWidth: 170,
    },
    {
      id: "isArchive",
      label: "Active",
      minWidth: 70,
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    const inputValue = e.target.value?.toLowerCase();
    if (inputValue?.length > 0) {
      const filteredData = rows.filter((row: any) => {
        const match =
          row?.email?.toLowerCase().match(inputValue) ||
          row?.first_name?.toLowerCase().match(inputValue) ||
          row?.last_name?.toLowerCase()?.match(inputValue) ||
          row?.password?.toLowerCase().match(inputValue) ||
          row?.phone_number?.toLowerCase().match(inputValue) ||
          row?.company_name?.toLowerCase().match(inputValue) ||
          row?.referral_code?.toLowerCase().match(inputValue);

        return match;
      });
      setSearchInput(inputValue);
      // setRefferalUserData(filteredData);
      handleFilteredData(filteredData)
    } else {
      setSearchInput("");
      // setRefferalUserData(rows);
      handleFilteredData(rows)

    }
  };
  const sort_data = (data: any) => {
    let sortedData: any = [...data];
    try {
      sortedData.sort((a: any, b: any) => b.created_Date - a.created_Date);
      setRefferalUserData(sortedData);
      return sortedData;
    } catch (e: any) {
      console.log("error in console  for sorting:", e);
    }
  };

  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Users</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.filter}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">User Type</InputLabel>
          <Select
            sx={cardHeaderStyles.dropDown}
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="userType"
            label="User Type"
            variant="outlined"
            onChange={(e: any)=> setfilterUserType(e.target.value)}
            value={filterUserType}
          >
            <MenuItem key={"1"} value={"1"}>
              Active
            </MenuItem>
            <MenuItem key={"0"} value={"0"}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search..."
          searchBarWidth="720px"
        />
      </Box>

      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refferalUserData && refferalUserData.length > 0 ? (
                    refferalUserData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        const fullName = `${row.first_name} ${row.last_name}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell align={"center"}>{fullName}</TableCell>
                            <TableCell align={"center"}>{row.email}</TableCell>

                            {/* <TableCell align={"center"}>
                              {row.password}
                            </TableCell> */}
                            <TableCell align={"center"}>
                              {row.phone_number}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.companyName}
                            </TableCell>
                            <TableCell align={"center"}>
                              {new Date(row.created_Date).getDate() +
                                "/" +
                                (new Date(row.created_Date).getMonth() + 1) +
                                "/" +
                                new Date(row.created_Date).getFullYear() ||
                                "----"}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.referral_code}
                            </TableCell>
                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              {!row?.isArchive ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Users Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default RefferalUsers;
