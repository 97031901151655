import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@mui/material/Alert";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "./Loader";
const   AlertDialog = ({
  openAlert,
  handleCloseAlert,
  handleConfirmDelete,
  isLoading,
}: any) => {
  return (
    <Dialog
      open={openAlert}
      onClose={handleCloseAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ marginLeft: "10px" }}>
        {/* <Alert severity="success" color="warning" style={{ width: "400px" }}> */}
        {"Are you sure?"}
        {/* </Alert> */}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Alert severity="warning" color="error">
            Do you really want to delete this? This will be delete permenantly
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAlert}>Cancel</Button>
        <Button onClick={handleConfirmDelete} autoFocus>
          Delete
        </Button>
      </DialogActions>
      <Loader isLoading={isLoading} />
    </Dialog>
  );
};
export default AlertDialog;
